<template>
<div class="print">
    <div v-for="d,index in diary">
        <p style="text-align:center" v-if="d.recom">
            Совместный осмотр с профессором, д.м.н. Пальшиным Г.А.
        </p>
        <p style="text-align:center" v-else>
            Дневниковая запись лечащего врача
        </p>
        <p style="text-align:justify">
            <b>ФИО пациента:</b> {{ patient.full_name }}<br/>
            <b>Возраст:</b> {{ patientAge }} ({{ patient.birthdate.split('-').reverse().join('.')}} г.р.)<br/>
            <b>Дата:</b> {{ d.diaryDate.split(' ').join(' - ') }}
        </p>
        <p style="text-align:justify">
            <b>Жалобы:</b> {{ d.compliant }}
        </p>
        <p style="text-align:justify">
            <b>Объективный статус:</b> {{ d.status_objective }}
        </p>
        <p style="text-align:justify">
            <b>Локальный статус:</b> {{ d.status_local }}
        </p>
        <p style="text-align:justify" v-if="d.recom">
            <b>Рекомендации:</b> {{ d.recom }}
        </p>

        <p style="text-align:justify;margin-bottom:2rem">
        <b>Врач:</b> {{ d.doctor_full_name }}<br/>
        <span v-if="d.recom"><b>Д.м.н., профессор:</b> Пальшин Г.А.<br/></span>
        </p>
        <hr size=2 width="100%" align=center v-if="(index < (diary.length - 1)) && !printMedhistory">
        <div class="pagebreak" v-if="printMedhistory" />
    </div>
</div>
</template>
<script setup>
    import { formatDate } from '@/common/rcfx.js'
</script>
<script>
export default {
    props: ['patient','diary','printMedhistory'],
    computed: {
        patientAge() {
            var birthdate = new Date(this.patient.birthdate.split('.').reverse().join('-'))
            var today = new Date()
            return Math.floor((today-birthdate)/3600000/24/365)
        },
        medhistory() {
            return this.$store.state.app.medhistory
        },
    },
    mounted() {
        console.log()
    }
}
</script>
<style scoped>
    .rc-table {
        border-collapse: collapse;
        border: solid 1px #333
    }
</style>
