<template>
  <table class="va-table">
    <tbody>
      <tr v-if="diary.recom">
        <td class="va-list-label rc-text-m" colspan="2">Совместный осмотр с Профессором</td>
      </tr>
      <tr>
        <td class="va-list-label rc-text-m">Дата и время</td>
        <td class="rc-text-m">{{ diary.diaryDate }}</td>
      </tr>
      <tr>
        <td class="va-list-label rc-text-m">Врач</td>
        <td class="rc-text-m">{{ diary.doctor_full_name }}</td>
      </tr>
      <tr>
        <td class="va-list-label rc-text-m">Жалобы</td>
        <td class="rc-text-m">{{ diary.compliant }}</td>
      </tr>
      <tr>
        <td class="va-list-label rc-text-m">Объективный статус</td>
        <td class="rc-text-m">{{ diary.status_objective }}</td>
      </tr>
      <tr>
        <td class="va-list-label rc-text-m">Локальный статус</td>
        <td class="rc-text-m">{{ diary.status_local }}</td>
      </tr>
      <tr v-if="diary.recom">
        <td class="va-list-label rc-text-m">Рекомендации</td>
        <td class="rc-text-m">{{ diary.recom }}</td>
      </tr>
    </tbody>
  </table>
</template>
<script>
export default {
    props: ['diary'],
}
</script>