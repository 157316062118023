import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import store from './store'
import router from './router'
import setupInterceptors from './services/setupInterceptors';
import firebaseMessaging from './firebase'
import helpers from './helpers'

setupInterceptors(store);

import './assets/css/reactive.clinic.css'
import 'vuestic-ui/styles/essential.css'
import 'vuestic-ui/styles/grid.css'
import 'vuestic-ui/styles/reset.css'
import 'vuestic-ui/styles/typography.css'
import { createVuestic} from 'vuestic-ui'
import VueHtmlToPaper from 'vue-html-to-paper';

const url = process.env.NODE_ENV === 'production' ? "https://clinic.reactiv.su/" : "http://localhost:8080/"

const options = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [
    url + 'css/reactive.print.css?'+new Date().getTime()
  ],
  timeout: 10, // default timeout before the print window appears
  autoClose: true, // if false, the window will not close after printing
  windowTitle: window.document.title + ' - Печать документа', // override the window title
}

const reactivClinic = createApp(App)
    .use(helpers)
    .use(VueHtmlToPaper, options)
    .use(firebaseMessaging)
    .use(store)
    .use(router)
    .use(createVuestic({
        config: {
            components: {
                VaDateInput: {
                    weekdayNames:[ "ВС", "ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ" ],
                    monthNames:[ "Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь" ],
                    firstWeekday:"Monday",
                    highlightWeekend:true,
                    },
                VaDatePicker: {
                    weekdayNames:[ "ВС", "ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ" ],
                    monthNames:[ "Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь" ],
                    firstWeekday:"Monday",
                    highlightWeekend:true,
                    },
                VaIcon: {
                    sizesConfig: {
                        defaultSize: 24,
                        sizes: {small: 18, medium: 24, large: 30}
                    }
                }
                },
            },
        })
    )
    if (firebaseMessaging) {reactivClinic.config.globalProperties.$messaging = firebaseMessaging}
    reactivClinic.config.globalProperties.$isJSON = helpers.isJSON
    reactivClinic.mount('#app')
