<template>
    
</template>
<script setup>
import EventBus from "../common/EventBus";
</script>
<script>
export default {
mounted() {
    this.$store.dispatch('app/resetProfile')
    this.$store.dispatch('auth/logout')
    this.$router.replace('/about')
}
}
</script>