<template>
    <VaCardTitle class="rc-text-m">Внутренний порядковый номер № {{ anexam.id }}</VaCardTitle>

    <VaCardTitle class="rc-text-m">Дата и время осмотра</VaCardTitle>
    <VaCardContent>
    {{ anexam.examDate }}
    </VaCardContent>

    <VaCardTitle class="rc-text-m">Осматривал анестезиолог</VaCardTitle>
    <VaCardContent>
    {{ anexam.anesthesist_full_name }}
    </VaCardContent>

    <VaCardTitle class="rc-text-m">ОТДЕЛЕНИЕ</VaCardTitle>
    <VaCardContent>
    {{ anexam.depName }}
    </VaCardContent>

    <VaCardTitle class="rc-text-m">ВИД ОПЕРАЦИИ</VaCardTitle>
    <VaCardContent>
    {{ anexam.urgence }}
    </VaCardContent>

    <VaCardTitle class="rc-text-m">ПЛАНИРУЕМЫЙ ОБЪЕМ ОПЕРАТИВНОГО ВМЕШАТЕЛЬСТВА</VaCardTitle>
    <VaCardContent>
    {{ anexam.scope }}
    </VaCardContent>

    <VaCardTitle class="rc-text-m">ДИАГНОЗ ОСНОВНОЙ</VaCardTitle>
    <VaCardContent>
    {{ anexam.diagnosis }}
    </VaCardContent>

    <VaCardTitle class="rc-text-m">ЖАЛОБЫ</VaCardTitle>
    <VaCardContent>
    {{ anexam.compliant }}
    </VaCardContent>

    <VaCardTitle class="rc-text-m">ПЕРЕНЕСЕННЫЕ ЗАБОЛЕВАНИЯ</VaCardTitle>
    <VaCardContent>
    {{ anexam.past_illness }}
    </VaCardContent>

    <VaCardTitle class="rc-text-m">СОПУТСТВУЮЩИЕ ЗАБОЛЕВАНИЯ</VaCardTitle>
    <VaCardContent>
    {{ anexam.diagnosis_secondary }}
    </VaCardContent>

    <VaCardTitle class="rc-text-m">АЛЛЕРГИЧЕСКИЕ РЕАКЦИИ</VaCardTitle>
    <VaCardContent>
    {{ anexam.status_alergo }}
    </VaCardContent>

    <VaCardTitle class="rc-text-m">ГЕМОТРАНСФУЗИИ</VaCardTitle>
    <VaCardContent>
    {{ anexam.hemotransfusion }}
    </VaCardContent>

    <VaCardTitle class="rc-text-m">ПЕРЕНЕСЕННЫЕ ОПЕРАЦИИ, ВИД АНЕСТЕЗИОЛОГИЧЕСКОГО ПОСОБИЯ, ОСОБЕННОСТИ</VaCardTitle>
    <VaCardContent>
    {{ anexam.past_operations }}
    </VaCardContent>

    <VaCardTitle class="rc-text-m">ОБЪЕКТИВНОЕ СОСТОЯНИЕ БОЛЬНОГО</VaCardTitle>
    <VaCardContent>
    {{ anexam.status_objective }}
    </VaCardContent>

    <VaCardTitle class="rc-text-m">ПОЛОЖЕНИЕ</VaCardTitle>
    <VaCardContent>
    {{ anexam.position }}
    </VaCardContent>

    <VaCardTitle class="rc-text-m">ТЕЛОСЛОЖЕНИЕ: РОСТ, ВЕС, ИМТ</VaCardTitle>
    <VaCardContent>
    {{ anexam.body_type }}
    </VaCardContent>

    <VaCardTitle class="rc-text-m">СОЗНАНИЕ</VaCardTitle>
    <VaCardContent>
    {{ anexam.state }}
    </VaCardContent>

    <VaCardTitle class="rc-text-m">ЗРАЧКИ</VaCardTitle>
    <VaCardContent>
    {{ anexam.pupils }}
    </VaCardContent>

    <VaCardTitle class="rc-text-m">ФОТОРЕАКЦИЯ</VaCardTitle>
    <VaCardContent>
    {{ anexam.photo_reaction }}
    </VaCardContent>

    <VaCardTitle class="rc-text-m">КОЖНЫЕ ПОКРОВЫ</VaCardTitle>
    <VaCardContent>
    {{ anexam.skin }}
    </VaCardContent>

    <VaCardTitle class="rc-text-m">ОТЕКИ</VaCardTitle>
    <VaCardContent>
    {{ anexam.edema }}
    </VaCardContent>

    <VaCardTitle class="rc-text-m">ПОЛОСТЬ РТА И ШЕЯ</VaCardTitle>
    <VaCardContent>
    {{ anexam.mouth }}
    </VaCardContent>

    <VaCardTitle class="rc-text-m">НОСОВОЕ ДЫХАНИЕ</VaCardTitle>
    <VaCardContent>
    {{ anexam.nasal }}
    </VaCardContent>

    <VaCardTitle class="rc-text-m">ХАРАКТЕРИСТИКА ДЫХАНИЯ</VaCardTitle>
    <VaCardContent>
    {{ anexam.breath }}
    </VaCardContent>

    <VaCardTitle class="rc-text-m">ИВЛ</VaCardTitle>
    <VaCardContent>
    {{ anexam.av }}
    </VaCardContent>

    <VaCardTitle class="rc-text-m">ДЫХАНИЕ</VaCardTitle>
    <VaCardContent>
    {{ anexam.chest }}
    </VaCardContent>

    <VaCardTitle class="rc-text-m">ЧДД</VaCardTitle>
    <VaCardContent>
    {{ anexam.breath_freq }}
    </VaCardContent>

    <VaCardTitle class="rc-text-m">МОКРОТА</VaCardTitle>
    <VaCardContent>
    {{ anexam.sputum }}
    </VaCardContent>

    <VaCardTitle class="rc-text-m">ПЕРКУТОРНО</VaCardTitle>
    <VaCardContent>
    {{ anexam.percussion }}
    </VaCardContent>

    <VaCardTitle class="rc-text-m">СЕРДЕЧНЫЕ ТОНЫ</VaCardTitle>
    <VaCardContent>
    {{ anexam.heart_tones }}
    </VaCardContent>

    <VaCardTitle class="rc-text-m">СЕРДЕЧНЫЙ РИТМ</VaCardTitle>
    <VaCardContent>
    {{ anexam.heart_beat }}
    </VaCardContent>

    <VaCardTitle class="rc-text-m">АРТЕРИАЛЬНОЕ ДАВЛЕНИЕ</VaCardTitle>
    <VaCardContent>
    {{ anexam.artpressure }}
    </VaCardContent>

    <VaCardTitle class="rc-text-m">ЧСС И САТУРАЦИЯ</VaCardTitle>
    <VaCardContent>
    {{ anexam.heart_rpm }}
    </VaCardContent>

    <VaCardTitle class="rc-text-m">ОСОБЕННОСТИ</VaCardTitle>
    <VaCardContent>
    {{ anexam.aditional }}
    </VaCardContent>

    <VaCardTitle class="rc-text-m">ЯЗЫК</VaCardTitle>
    <VaCardContent>
    {{ anexam.tongue }}
    </VaCardContent>

    <VaCardTitle class="rc-text-m">ЗАПАХ ИЗО РТА</VaCardTitle>
    <VaCardContent>
    {{ anexam.smell }}
    </VaCardContent>

    <VaCardTitle class="rc-text-m">ЖИВОТ</VaCardTitle>
    <VaCardContent>
    {{ anexam.stomach }}
    </VaCardContent>

    <VaCardTitle class="rc-text-m">ПЕРИСТАЛЬТИКА КИШЕЧНИКА</VaCardTitle>
    <VaCardContent>
    {{ anexam.peristalsis }}
    </VaCardContent>

    <VaCardTitle class="rc-text-m">ПЕЧЕНЬ</VaCardTitle>
    <VaCardContent>
    {{ anexam.liver }}
    </VaCardContent>

    <VaCardTitle class="rc-text-m">СТУЛ</VaCardTitle>
    <VaCardContent>
    {{ jsonArrayParse(anexam.stool) }}
    </VaCardContent>

    <VaCardTitle class="rc-text-m">РВОТА</VaCardTitle>
    <VaCardContent>
    {{ anexam.vomit }}
    </VaCardContent>

    <VaCardTitle class="rc-text-m">ВРЕМЯ ПРИЕМА ПИЩИ И ЖИДКОСТИ</VaCardTitle>
    <VaCardContent>
    {{ anexam.foodwater_intake }}
    </VaCardContent>

    <VaCardTitle class="rc-text-m">МОЧЕИСПУСКАНИЕ</VaCardTitle>
    <VaCardContent>
    {{ jsonArrayParse(anexam.urination) }}
    </VaCardContent>

    <VaCardTitle class="rc-text-m">ДИУРЕЗ</VaCardTitle>
    <VaCardContent>
    {{ jsonArrayParse(anexam.diuresis) }}
    </VaCardContent>

    <VaCardTitle class="rc-text-m">СИМПТОМ ПОКОЛАЧИВАНИЯ</VaCardTitle>
    <VaCardContent>
    {{ jsonArrayParse(anexam.murphys_symptom) }}
    </VaCardContent>

    <VaCardTitle class="rc-text-m">ПЛАН АНЕСТЕЗИИ</VaCardTitle>
    <VaCardContent>
    {{ anexam.anesthesia_recom }}
    </VaCardContent>

    <VaCardTitle class="rc-text-m">РИСК АНЕСТЕЗИИ</VaCardTitle>
    <VaCardContent>
    {{ anexam.anesthesia_risc }}
    </VaCardContent>

    <VaCardTitle class="rc-text-m">РЕКОМЕНДАЦИИ</VaCardTitle>
    <VaCardContent>
    {{ anexam.recoms }}
    </VaCardContent>
</template>
<script>
export default {
    props: ['anexam'],
    methods: {
        jsonArrayParse(string) {
            console.log(string)
            let array = JSON.parse(string)
            console.log(array)
            array.shift()
            return array.join(', ')
        }
    },
}
</script>