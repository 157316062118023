<template>
      <div class="print profile">
        <p class="header">
        Наименование медицинской организации<br/>
        ООО "КЛИНИКА ПРОФЕССОРА"<br/>
        Адрес: РС(Я), г.Якутск, ул. Радужная 69/12<br/>
        </p>
        <p class="bold center header">
  МЕДИЦИНСКАЯ КАРТА<br/>
ПАЦИЕНТА, ПОЛУЧАЮЩЕГО МЕДИЦИНСКУЮ ПОМОЩЬ<br/>
В АМБУЛАТОРНЫХ УСЛОВИЯХ №___
        </p>
<b>1. Дата заполнения медицинской карты:</b> {{ date }}<br/>
<b>2. Фамилия, имя, отчество:</b> <span class="big">{{ patient.full_name ? patient.full_name : "не указаны" }}</span><br/>
<b>3. Пол:</b> {{ patient.sex === "m" ? "Мужской" : patient.sex === "f" ? "Женский" : "не указан"}}<br/>
<b>4. Дата рождения:</b> {{ patient.birthdate ? patient.birthdate : "не указана" }}	<br/>
<b>5. Место регистрации:</b> {{ patient.address ? patient.address : "не указан" }}<br/>
<b>6. Телефон:</b> {{ patient.phone ? patient.phone : "не указан" }}<br/>
<b>7. Местность:</b> {{ patient.villager ? "сельская" : "городская" }}<br/>
<b>8. Полис ОМС:</b> {{ patient.polis ? patient.polis : "не указан" }}<br/>
<b>9. СНИЛС:</b> {{ patient.snils ? patient.snils : "не указан" }}<br/>
<b>10. Код категории льготы: </b> ________________________<br/>
<b>11. Документ:</b> {{ passport ? passport : "не указаны" }}<br/>
<b>12. Семейное положение:</b> {{ patient.marriage ? patient.marriage : "не указано" }}<br/>
<b>13. Образование:</b> {{ patient.education ? patient.education : "не указано"}}<br/>
<b>14. Инвалидность:</b> {{ patient.disability }}  <br/>
<b>15. Место работы, должность:</b> {{ patient.works ? patient.works : "не указано" }}  <br/>

        </div>
</template>
<script setup>
  import { bloodTypes, rhfactor } from "@/common/locale"
</script>
<script>
export default {
    props: ['patient', 'date'],
    computed: {
      bmi() {return this.patient.weight && this.patient.height && (this.patient.weight/Math.pow(this.patient.height/100, 2)).toFixed(2)},
      passport() {
        if (this.$isJSON(this.patient.passport)) {
          let p = JSON.parse(this.patient.passport)
          return 'паспорт серия ' + p.series + ' номер ' + p.number + ' выдан ' + p.issued + ' ' + p.date
        }else{
          return this.patient.passport.length>0 ? this.patient.passport : 'не указаны'
        }
      }
    },
    methods: {
      bmiString(bmi) {
        if (bmi<=16) {
          return 'Выраженный дефицит массы тела'
        }
        else if (bmi<=18.5) {
          return 'Недостаточная (дефицит) масса тела'
        }
        else if (bmi<=25) {
          return 'Норма'
        }
        else if (bmi<=30) {
          return 'Избыточная масса тела (предожирение)'
        }
        else if (bmi<=35) {
          return 'Ожирение первой степени'
        }
        else if (bmi<=40) {
          return 'Ожирение второй степени'
        }
        else if (bmi>40) {
          return 'Ожирение третьей степени (морбидное)'
        }
      },
    }
}
</script>