<template>
    <div class="print">
        <p class="bold center">
            ООО «Клиника Профессора»<br/>
            ИНН 1435316805, КПП 143501001<br/>
            Адрес: 677010, г. Якутск, ул. Радужная, 69/12<br/>
            Телефон: +7 (914)2776990; электронный адрес: klinika.professora@mail.ru
        </p>
        
        <hr size=2 width="100%" align=center>
        <p class="bold center">
        ПРЕДОПЕРАЦИОННЫЙ ОСМОТР АНЕСТЕЗИОЛОГА
        </p>
        <p style="text-align:left;font-weight:bold;">
            {{ anexam.examDate.split(' ')[0] }} г. в {{ anexam.examDate.split(' ')[1] }}<br/>
        </p>
        <p style="text-align:justify">
            <b>ФИО пациента:</b> {{ anexam.patient_full_name }}<br/>
            <b>Возраст:</b> {{ patientAge }}<br/>
            <b>Рост:</b> {{ anexam.patient_height>0 ? anexam.patient_height + " см" : "не указан" }}, <b>вес:</b> {{ anexam.patient_weight>0 ? anexam.patient_weight + " кг" : "не указан" }}
        </p>
        <p style="text-align:justify">
            Больной осмотрен в отделении: {{ anexam.depName }}. 
            Предполагается {{ anexam.urgence }} операция в объеме: {{ anexam.scope }}.
            По поводу диагноза: {{ anexam.diagnosis }}.<br>

            <b>Жалобы пациента: </b> {{ anexam.compliant }}<br/>
            <b>Перенесенные заболевания:</b> {{ anexam.past_illness }}<br/>
            <b>Сопутствующие заболевания:</b> {{ anexam.diagnosis_secondary }}<br/>
            <b>Алергологический анамнез:</b> {{ anexam.status_alergo }}<br/>
            <b>Гемотрансфузии:</b> {{ anexam.hemotransfusion }}<br/>
            <b>Перенесенные операции:</b> {{ anexam.past_operations }}<br/>
            <b>Объективный статус:</b> {{ anexam.status_objective }}<br/>
            <b>Положение:</b> {{ anexam.position }}<br/>
            <b>Телосложение:</b> {{ anexam.body_type }}<br/>
            <b>Сознание:</b> {{ anexam.state }}<br/>
            <b>Зрачки:</b> {{ anexam.pupils }}<br/>
            <b>Фотореакция:</b> {{ anexam.photo_reaction }}<br/>
            <b>Кожные покровы:</b> {{ anexam.skin }}<br/>
            <b>Отеки:</b> {{ anexam.edema }}<br/>
            <b>Полость рта и шеи:</b> {{ anexam.mouth }}<br/>
            <b>Носовое дыхание:</b> {{ anexam.nasal }}<br/>
            <b>Характеристика дыхания:</b> {{ anexam.breath }}<br/>
            <b>ИВЛ:</b> {{ anexam.av }}<br/>
            <b>Дыхание:</b> {{ anexam.chest }}<br/>
            <b>Мокрота:</b> {{ anexam.sputum }}<br/>
            <b>Перкуторно:</b> {{ anexam.percussion }}<br/>
            <b>Сердечные тоны:</b> {{ anexam.heart_tones }}<br/>
            <b>Серечный ритм:</b> {{ anexam.heart_beat }}<br/>
            <b>Артериальное давление:</b> {{ anexam.artpressure }}<br/>
            <b>ЧСС и сатурация:</b> {{ anexam.heart_rpm }}<br/>
            <b>Особенности:</b> {{ anexam.aditional }}<br/>
            <b>Язык:</b> {{ anexam.tongue }}<br/>
            <b>Запах изо рта:</b> {{ anexam.smell }}<br/>
            <b>Живот:</b> {{ anexam.stomach }}<br/>
            <b>Перистальтика кишечника:</b> {{ anexam.peristalsis }}<br/>
            <b>Печень:</b> {{ anexam.liver }}<br/>
            <b>Стул:</b> {{ JSON.parse(anexam.stool).join(', ') }}<br/>
            <b>Рвота:</b> {{ anexam.vomit }}<br/>
            <b>Время приема пищи и жидкости:</b> {{ anexam.foodwater_intake }}<br/>
            <b>Мочеиспускание:</b> {{ JSON.parse(anexam.urination).join(', ') }}<br/>
            <b>Диурез:</b> {{ JSON.parse(anexam.diuresis).join(', ') }}<br/>
            <b>Симптом поколачивания:</b> {{ JSON.parse(anexam.murphys_symptom).join(', ') }}<br/>
            <b>План анестезии:</b> {{ anexam.anesthesia_recom }}<br/>
            <b>Риск анестезии:</b> {{ anexam.anesthesia_risc }}<br/>
            <b>Рекомендации:</b> {{ anexam.recoms }}<br/>
        </p>
        <br/>
        <p style="text-align:justify">
            Врач анестезиолог-реаниматолог: {{ anexam.anesthesist_full_name}}<br/>
        </p>
        <div class="pagebreak" />
    </div>
</template>
<script setup>
    import { formatDate } from '@/common/rcfx.js'
</script>
<script>
export default {
    // props: ['anexam'],
    computed: {
        patient() {
            return this.$store.state.app.patient
        },
        anexam() {
            return this.$store.state.app.anexam
        },
        patientAge() {
                if (!this.anexam.patient_birthdate) { 
                    return 'Не указан' 
                }else{
                    var birthdate = new Date(this.anexam.patient_birthdate.split('.').reverse().join('-'))
                    var today = new Date()
                    return Math.floor((today-birthdate)/3600000/24/365) + " (" + this.anexam.patient_birthdate.split('-').reverse().join('.') + " г.р.)"
                }
            }
    }
}
</script>