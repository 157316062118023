<template>
<VaCard stripe stripe-color="success">
    <VaCardTitle>
      <VaButton
      icon="arrow_back_ios_new"
      color="primary"
      icon-color="#333"
      class="mr-3"
      preset="secondary"
      hover-behavior="opacity"
      :hover-opacity="0.4"
      @click="$router.back()"
    />
    Запись на прием: {{ $route.name == 'registationEdit' ? 'Редактирование' : 'Создание' }} 
    </VaCardTitle>

    <VaCardContent style="text-align:left">

        <VaSkeletonGroup v-if="patientLoading">
          <VaSkeleton variant="text" class="va-h5" :lines=12 style="max-width:600px"/>
          <VaSkeleton variant="rounded" class="va-button mr-3 mb-2" height="2em" style="max-width:100px"/>
        </VaSkeletonGroup>

        <VaForm ref="resForm" v-show="!patientLoading" class="items-baseline gap-6" style="display: flex; flex-direction: column; max-width:600px">
          <div v-if="patientId=='new'" style="width:100%">
            <VaSelect
                style="width:100%"
                v-model="foundPatientId"
                class="col-span-1 mb-2"
                label="ФИО пациента"
                placeholder="Найти пациента..."
                noOptionsText="Нет совпадений"
                autocomplete
                clearable
                highlight-matched-text
                :options="patients"
                :textBy="(option) => option.full_name"
                :valueBy="(option) => option.id"
                :rules="[(v) => v || 'Field is required']"
              >
              <template #append>
                <VaIcon
                  :name="patient_isValid ? 'check_circle' : 'warning'"
                  class="ml-2"
                  :color="patient_isValid ? 'success' : 'warning'"
                />
              </template>
            </VaSelect>
            <div v-if="foundPatientId==''">
              <p class="ml-3 mb-3">или</p>
              <VaButton icon-right="person_add" class="mb-3" preset="primary" @click="$router.replace('/express/add')">
                Добавить нового
              </VaButton>
            </div>
          </div>
          <div v-else>
            <label class="va-input-label" style="color: var(--va-primary);">ФИО пациента</label>
            <p class="mb-4">{{ patient.lastname }} {{ patient.firstname }} {{ patient.patronymic }}{{ patient.birthdate!=null ? ', ' + patient.birthdate : ''}}</p>
          </div>
          <label class="va-input-label" style="color: var(--va-primary);">Признак оплаты</label>
          <div class="rc-flex row">
            <VaRadio 
              class="mb-3"
              v-model="form.paid"
              :rules="[(v) => v || 'Отметьте признак оплаты']"
              :options="[
                {
                  text: 'ОМС',
                  value: false,
                },
                {
                  text: 'Платный',
                  value: true,
                },
              ]"
              value-by="value"
            />
            <VaIcon
              :name="paid_isValid ? 'check_circle' : 'warning'"
              class="ml-3 mt-2"
              :color="paid_isValid ? 'success' : 'warning'"
            />
          </div>
          <VaInput class="mb-3"
            type="text"
            v-model="form.examReason"
            label="Причина обращения"
            placeholder="На что жалуется пациент"
          >
          <template #append>
            <VaIcon
              :name="er_isValid ? 'check_circle' : 'warning'"
              class="ml-2"
              :color="er_isValid ? 'success' : 'warning'"
            />
          </template>
          </VaInput>
          <label class="va-input-label" style="color: var(--va-primary);">Прием</label>
          <div class="rcflex rcflex-base">
          <VaRadio class="mb-3"
            v-model="form.examType"
            :options="[
              {
                text: 'Первичный',
                value: '0',
              },
              {
                text: 'Повторный',
                value: '1',
              },
            ]"
            value-by="value"
            :success="isValid"
          />
          <VaIcon
                :name="et_isValid ? 'check_circle' : 'warning'"
                class="ml-4"
                :color="et_isValid ? 'success' : 'warning'"
              />
            </div>
          <VaSelect
            label="Лечащий врач"
            v-model="doctor_id"
            :text-by="(option) => option.full_name"
            :value-by="(option) => option.id"
            :options="doctors"
            placeholder="Выберите врача"
          >          
            <template #append>
              <VaIcon
                :name="user_isValid ? 'check_circle' : 'warning'"
                class="ml-2 mr-4"
                :color="user_isValid ? 'success' : 'warning'"
              />
            </template>
          </VaSelect>

          <div v-if="doctor_id" class="rcflex ">
          <VaDateInput class="mb-3"
            
            :allowedDays="(date) => allowedDays(date)"
            :startYear="currentYear"
            :endYear="currentYear"
            label="Дата"
            v-model="tempdate"
            clearable
            style="width:200px"
          >

            <template #append>
              <VaIcon
                  :name="date_isValid ? 'check_circle' : 'warning'"
                  class="ml-2"
                  :color="date_isValid ? 'success' : 'warning'"
                />
              </template>
            </VaDateInput>
          </div>

          <p v-if="tempdate && doctor_id">
          <label class="va-input-label" style="color: var(--va-primary);">Время записи</label>
            </p>
          <p v-if="tempdate && doctor_id" class="mb-3 ml-3">
          {{ temptime ? temptime : 'не выбрано' }}
          <VaIcon
                  :name="temptime ? 'check_circle' : 'warning'"
                  class="ml-4"
                  :color="temptime ? 'success' : 'warning'"
                />
          </p>
          <span v-if="!scheduleLoading" class="mb-4">
            <VaChip
              v-if="tempdate"
              v-for="(disabled, time,  index) in availTimeIntervals"
              :key="index"
              :disabled="disabled"
              class="mt-2 mr-2"
              style="width:60px"
              outline shadow
              @click="temptime = time"
            >
              {{ time }}
            </VaChip>
          </span>
          
          <VaSkeleton v-show="scheduleLoading" height="6.45rem" class="mb-4" variant="rounded"/>

          <VaButton :loading="isLoading" icon="save" preset="primary" class="mt-3" :disabled="isValid==false" @click="addReg()">
            Сохранить
          </VaButton>          
        </VaForm>



        </VaCardContent>
  </VaCard>
</template>

<script setup>
import { allowedDays } from "@/common/rcfx"
</script>
<script>
import axiosInstance from "../services/api";
import debounce from 'lodash.debounce'
import { RouterLink } from 'vue-router'
import { formatDate } from '@/common/rcfx.js'
import { getPatient } from "../api/request";

export default {
    components: { RouterLink },
    props: { 
      patientId: String,
    },
    computed: {
      patient_isValid: function() {
        return (this.foundPatientId != '') ? true : false
      },
      paid_isValid: function() {
        return (this.form.paid != undefined) ? true : false
      },
      er_isValid: function() {
        return (this.form.examReason.length > 5) ? true : false
      },
      et_isValid: function() {
        return (this.form.examType != '') ? true : false
      },
      user_isValid: function() {
        return (this.form.user != '') ? true : false
      },
      date_isValid: function() {
        return (this.form.tempdate != '') ? true : false
      },
      time_isValid: function() {
        return (this.temptime != '') ? true : false
      },
      isValid: function() {
        return (this.er_isValid && this.et_isValid && this.user_isValid && this.date_isValid && this.time_isValid && this.paid_isValid) ? true : false
      }
    },
    data() {
        return {
            //isValid: false,
            foundPatientId: '',
            tooShortQuery: false,
            availTimeIntervals:{},
            mode: this.$route.name == 'regEdit' ? 'edit' : 'add',
            currentYear: new Date().getFullYear(),
            tempdate: new Date(Date.now()),
            temptime:'',
            form: {
              paid: undefined,
              regDate: '',
              examType: '',
              depType: '0',
              examReason: '',
              visited: '-1',
              patient: this.patientId === "new" ? null : this.patientId ,
              user: '',
            },
            patients: {},
            patient: Object,
            doctorSchedule: Object,
            doctor_id: "",
            doctors: Object,
            addedSuccess: false,
            patientLoading: true,
            scheduleLoading:false,
            scheduleUpdated:0,
            isLoading: false
          };
    },
    watch: {
      foundPatientId: function(newValue) {
        if (newValue != '') {
          console.log('found!')
          this.form.patient = newValue
          this.getPatient(newValue)
        }else{
          this.form.patient = ''
          this.patient = {}
        }
      },
      doctor_id: function(newValue) {
        if (newValue) {
          console.log('doctor changed')
          this.getSchedule(formatDate(this.tempdate,'us'))
          this.form.user = newValue
        }
      },
      tempdate: function(newValue) {
        if (newValue) {
          console.log('date updated')
          this.getSchedule(formatDate(newValue,'us'))
        }
      },
      scheduleUpdated: function(newValue) {
        if (newValue) {
          console.log('schedule updated')
          this.checkTime()
          this.temptime=''
        }
      },
    },
    methods: {
      allowedMonths(date) {
        var currentTS = Date.now()
        var delta = 60*60*24*7*1000*4
        var endTS = currentTS + delta
        var startMonth = new Date(currentTS).getMonth()
        var endMonth = new Date(endTS).getMonth()
        if (date.getMonth() == startMonth || date.getMonth() == endMonth) { return true }else{ return false }
      },
      // allowedDays(date) {
      //   var currentTS = Date.now()
      //   var oneDay = 60*60*24
      //   var today = (Math.floor( Math.floor(currentTS/1000) / oneDay ) * oneDay - oneDay) * 1000
      //   var delta = oneDay*14
      //   var endTS = currentTS + delta*1000

      //   if (date.getTime() >= today && date.getTime() <= endTS) {
      //     return true 
      //   }else{ 
      //     return false 
      //   }
      // },
      checkTime() {
        console.log('Checking intervals')

        // Составляем объект doctorSch для текущего доктора на текущую дату
        let doctorSch=[]
        for (var sch in this.doctorSchedule) {
                        if (this.doctorSchedule[sch].visited < 0) {
                          var oneRecord = this.doctorSchedule[sch].regDate
                          doctorSch.push(oneRecord)
                        }
                      }
        // Итератор интервалов от 9:00 до 19:30 с обедом с 13:00 до 14:00
        let intervals={}
        for (let i = 9; i < 20; i++) {
            // if (i!=13) {

              var int = String(i).padStart(2, '0')+':00'
              var dateInt = formatDate(this.tempdate,'ru') + ' ' + int + ':00'

              //console.log('Date & time: ' + dateInt)
              this.availTimeIntervals[int] = doctorSch.includes(dateInt) ? true : false

              int= String(i).padStart(2, '0')+':30'
              var dateInt = formatDate(this.tempdate,'ru') + ' ' + int + ':00'

              //console.log('Date & time: ' + dateInt)
              this.availTimeIntervals[int] = doctorSch.includes(dateInt) ? true : false
  
            // }
          }
        //console.log('Available intervals is: ' + JSON.stringify(this.availTimeIntervals))
      },
      addReg() {
        console.log('Trying to add a Registration')
        delete this.form.id
        delete this.form.registered
        delete this.form.updated
        this.isLoading = true
//        if (this.patientId === "new") this.form.patientId = this.foundPatientId
        this.form.regDate = formatDate(this.tempdate,'us') + ' ' + this.temptime
        axiosInstance
            // .patch('patients/' + (this.patientId ? this.patientId : '') + '/', "")
            .post('/registrations/add/', this.form)
            .then(response => {
              const message = 'Added ok'
              console.log(message);
              //this.newPatientData = response.data
              this.addedSuccess=true
              var pat = this.patient.lastname + ' ' + this.patient.firstname + ' ' + this.patient.patronymic
              var examType = this.form.examType==0 ? "Первичный прием: " : "Повторный прием: "
              var payload = {
                title: "У вас новая запись в амбулаторию",
                body: examType + pat + " на " + this.form.regDate,
              }
              // this.addEvent("record", payload, this.form.user)
              this.$vaToast.init({ message: 'Запись сохранена', color: 'success', position: 'bottom-right'})
              this.$router.replace({name:'registrations', props: { date: this.form.regDate }})
        })
            .catch(error => {
            console.log('Axios Request Failed: ' + JSON.stringify(error))
            this.gotError = true;
        })
            .finally(() => {
              this.isLoading = false
        });
      },
      addEvent(type,payload,user) {
        console.log('Trying to add a Event')
        var event = {
          sent:0,
          event: type,
          payload: JSON.stringify(payload),
          recipient: user,
        }
        console.log(JSON.stringify(event))

        axiosInstance
            .post('/events/new', event)
            .then(response => {
              const message = 'Evend sent ok: ' + JSON.stringify(response)
              console.log(message);
        })
            .catch(error => {
            console.log('Event sent failed: ' + JSON.stringify(error))
        })
      },
      getSchedule(dateString) {
        this.scheduleLoading = true
        axiosInstance
            .get(`registrations/doctor/` + this.doctor_id + '?date=' + dateString)
            .then(response => {
              if (response.data.count>0) {
                this.doctorSchedule = response.data.results;
              }else{
                this.doctorSchedule = {open:true}
              }
            })
            .catch(error => {
            console.log('Schedule Request Failed: ' + error);
            this.gotError = true;
        })
            .finally(() => {
              console.log('Schedule: Request successfully completed')
              this.scheduleLoading = false
              this.scheduleUpdated = Date.now()
        });
      },
      getUsers() {
        axiosInstance
            .get(`/staff/?group=doctors`)
            .then(response => {
              this.doctors = response.data.results;
              this.getAnesthesists()
        })
            .catch(error => {
            console.log('Doctors Request Failed: ' + error);
            this.gotError = true;
        })
            .finally(() => {
              console.log('Doctors: Request successfully completed')
              this.isLoading = false
        });
      },
      getAnesthesists() {
        axiosInstance
            .get(`/staff/?group=anesthesists`)
            .then(response => {
              let a = response.data.results
              this.doctors = this.doctors.concat(a)
              
        })
            .catch(error => {
            console.log('Doctors Request Failed: ' + error);
            this.gotError = true;
        })
            .finally(() => {
              console.log('Doctors: Request successfully completed')
              this.isLoading = false
        });
      },
      searchPatient(query) {
        console.log('*****************Search executed')
          console.log('THE SEARCH QUERY IS: ' + query)
          axiosInstance
            .get('patients/?search=' + query)
            .then(response => {
              console.log(JSON.stringify(response.data))
              this.patientsCount = response.data.count
              this.patients = response.data.results
            })
            .catch(error => {
              console.log('Search: Axios Request Failed: ' + error);
            })
            .finally(() => (console.log('Search: Request successfully completed')))
      },  
      getPatient(id) {
        this.patientLoading = true
        // console.log('id is ' + id)
        axiosInstance
            .get(`patients/`+id+`/`)
            .then(response => {
              this.patient = response.data;
        })
            .catch(error => {
            console.log('Axios Request Failed: ' + error);
            this.gotError = true;
        })
            .finally(() => {
              console.log('One patient: Request successfully completed')
              this.patientLoading = false
        });
      },
    },
    mounted() {
      this.searchPatient('')
      // console.log('aD is ' + allowedDays(new Date))
      // console.log('mode is: ' + this.mode)
      this.getUsers()
      this.getPatient(this.patientId)
      if (this.mode=="edit") { 
        this.getPatient(this.patientId)
      }
      else {
        this.isLoading=false
      }
    },
}

</script>
<style>
</style>
  