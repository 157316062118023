export function allowedDays(date) {
    var currentTS = Date.now()
    var oneDay = 60*60*24
    var today = (Math.floor( Math.floor(currentTS/1000) / oneDay ) * oneDay - oneDay) * 1000
    var delta = oneDay*14
    var endTS = currentTS + delta*1000

    if (date.getTime() >= today && date.getTime() <= endTS) {
      return true 
    }else{ 
      return false 
    }
}
export const asd = function(date) {
    return date
}
export function ruDateTime(dt) {
    var day, month, year, hours, minutes, seconds;
    var d = dt.split(' ')[0]
    var t = dt.split(' ')[1]
    var result = d.match("[0-9]{2}([\-/ \.])[0-9]{2}[\-/ \.][0-9]{4}");
    if(null != result) {
        var dateSplitted = result[0].split(result[1]);
        day = dateSplitted[0];
        month = dateSplitted[1];
        year = dateSplitted[2];
    }
    var result = d.match("[0-9]{4}([\-/ \.])[0-9]{2}[\-/ \.][0-9]{2}");
    if(null != result) {
        var dateSplitted = result[0].split(result[1]);
        day = dateSplitted[2];
        month = dateSplitted[1];
        year = dateSplitted[0];
    }
    var result = t.match("[0-9]{2}([\-/ \:])[0-9]{2}[\-/ \:][0-9]{2}");
    if(null != result) {
        var timeSplitted = result[0].split(result[1]);
        //console.log("res is " + result)
        hours = timeSplitted[0];
        minutes = timeSplitted[1];
        seconds = timeSplitted[2];
    }

    if(month>12) {
        aux = day;
        day = month;
        month = aux;
    }
    var obj = {}
    obj.date = day+"/"+month+"/"+year
    obj.time = hours+":"+minutes
    return obj
}
// formatDate: принимает первым параметром объект Date, вторым параметром формат вывода en|ru
// возвращает String в виде Даты
export function formatDate(dateObject, format) {
    var d = dateObject
    switch (format) {
        case 'us':
            //return date.toLocaleDateString('en-GB', options);
            return String(String(d.getFullYear() + '-'+String(d.getMonth()+1).padStart(2, '0') + '-'+String(d.getDate()).padStart(2, '0')))
        case 'ru':
            return String(d.getDate()).padStart(2, '0') + '.'+String(d.getMonth()+1).padStart(2, '0') + '.'+String(d.getFullYear())
        case 'short':
            return String(d.getDate()).padStart(2, '0') + '.'+String(d.getMonth()+1).padStart(2, '0')
    }
}
// formatDate: принимает первым параметром объект Date, вторым параметром формат вывода en|ru
// возвращает String в виде Времени 00:00:00
export function formatTime(dateObject) {
    var d = dateObject
        return String(d.getHours()).padStart(2, '0') + ':' + String(d.getMinutes()).padStart(2, '0') + ':' + String(d.getSeconds()).padStart(2, '0')
}
export function formatDateTime(dateObject, format) {
    return formatDate(dateObject, format) + ' ' + formatTime(dateObject)
}
export function dayString(digit) {
        if (digit == 1){
            return 'день'
        }
        if (digit > 1 && digit < 5) {
            return 'дня'
        }
        if (digit > 4 && digit < 21) {
            return 'дней'
        }
}
Date.prototype.ruString = function(format) {

    switch (format) {
      case 'us':
        return String(String(this.getFullYear() + '-'+String(this.getMonth()+1).padStart(2, '0') + '-'+String(this.getDate()).padStart(2, '0')))
      case 'ru':
        return String(d.getDate()).padStart(2, '0') + '.'+String(d.getMonth()+1).padStart(2, '0') + '.'+String(d.getFullYear())
      case 'orm':
        return String(
          String(this.getFullYear()) + '-'+ 
           String(this.getMonth()+1).padStart(2, '0') + '-' + 
           String(this.getDate()).padStart(2, '0') + ' ' + 
          String(this.getHours()).padStart(2, '0') + '-' + 
          String(this.getMinutes()+1).padStart(2, '0') + '-'+ 
          String(this.getSeconds()).padStart(2, '0')
          )
    }
}
export function detectOS() {
    let userAgent = window.navigator.userAgent,
      platform = window.navigator.platform,
      macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
      windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
      iosPlatforms = ['iPhone', 'iPad', 'iPod'],
      os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'Mac OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'Windows';
    } else if (/Android/.test(userAgent)) {
      os = 'Android';
    } else if (!os && /Linux/.test(platform)) {
      os = 'Linux';
    }

    return os;
  }
export async function getNotificationPermission() {
    const state = await Notification.requestPermission(); 
    if (state !== 'granted') {
        return false
        }
        return true
    }