<template>
<div class="print">
        <p class="bold center">
            ООО «Клиника Профессора»<br/>
            ИНН 1435316805, КПП 143501001<br/>
            Адрес: 677010, г. Якутск, ул. Радужная, 69/12<br/>
            Телефон: +7 (914)2776990; электронный адрес: klinika.professora@mail.ru
        </p>
        <table border="0" width="100%">
            <tr>
                <td style="width:50%">
                    {{hosvisit.clinimum}}
                </td>
                <td align="center">
                    <b>{{ medhistory.paid ? "ХРО" : "КСГ" }}</b><br/>
                    <b v-if="!medhistory.paid">{{ patient.polis_issuer }}</b><br/>
                    <b v-if="!medhistory.paid">Полис №{{ patient.polis }}</b><br/>
                    <b v-if="!medhistory.paid">СНИЛС №{{ patient.snils }}</b><br/>
                    <b>Рост:</b> {{ patient.height }} см., <b>вес:</b> {{ patient.weight }} кг.
                </td>
            </tr>
        </table>
        <hr size=2 width="100%" align=center>
        <p style="text-align:center">
            <b>Медицинская карта № {{ title.medcard ? title.medcard : "_____" }}</b><br/>
            <b>стационарного больного</b>
        </p>
        <p style="text-align:justify">
            <b>Дата и время поступления:</b> {{ medhistory.openDate }} в Ортопедическое отделение<br/>
            <b>Палата:</b> {{ medhistory.room }}<br/>
            <b>Дата и время выписки:</b> {{ medhistory.discharge_date }}<br/>
            <b>Проведено:</b> {{ discharge.clinic_days }}<br/>
        </p>
        <hr size=2 width="100%" align=center>
        <p style="text-align:justify">
            <b>Вид транспортировки:</b> {{ title.transporting }}<br/>
            <b>Группа крови и резус-принадлежность:</b> {{ bloodTypes[patient.bloodtype] ? bloodTypes[patient.bloodtype] : "не указана" }}, {{ rhfactor(patient.rhfactor) ? rhfactor(patient.rhfactor) : "не указан" }}, дата: _____________<br/>
            <b>Побочное действие лекарств (непереносимость):</b> {{ hosvisit.status_alergo }} от {{ hosvisit.visitDate.split(' ')[0] }}, врач: {{ hosvisit.doctor_full_name }}<br/>
        </p>
        <hr size=2 width="100%" align=center>
        <h2 style="text-align:center">
            {{ patient.full_name }}
        </h2>
        <hr size=2 width="100%" align=center>
        <p style="text-align:justify">
            <b>Пол:</b> {{ patient.sex === "m" ? "Мужской" : "Женский" }}, <b>национальность:</b> {{ patient.nation ? patient.nation : "не указана" }}, <b>образование:</b> {{ patient.education ? patient.education : "не указано"}} <br/>
            <b>Возраст:</b> {{ patientAge }} ({{ patient.birthdate.split('-').reverse().join('.')}} г.р.)<br/>
            <b>Постоянное место жительства:</b> {{ patient.address }}<br/>
            <b>ФИО родственника:</b> {{ title.relative_full_name ? title.relative_full_name : "не указан" }}, <b>Телефон:</b> {{ title.relative_phone ? title.relative_phone : "не указан" }} <br/>
        </p>
        <hr size=2 width="100%" align=center>
        <p style="text-align:justify">
            <b>Паспорт серия:</b> {{ passport.series }}, <b>номер:</b> {{ passport.number }}, <b>выдан:</b> {{ passport.issued }}, {{ passport.date }} г.<br/>
            <b>Место работы:</b> {{ patient.works ? patient.works : "не указано" }}<br/>
            <b>Группа инвалидности:</b> {{ patient.disability }}
        </p>
        <hr size=2 width="100%" align=center>
        <p style="text-align:justify">
            <b>Кем направлен пациент:</b> {{ title.referal }}<br/>
            <b>Доставлен в стационар:</b> Планово, <b>через 24 часа</b>, после начала заболевания<br/>
            <b>Диагноз направившего учреждения:</b> {{ patient.diagnosis_first ? patient.diagnosis_first : "Нет" }}<br/>
        </p>
        <hr size=2 width="100%" align=center>
        <p style="text-align:justify">
            <b>Диагноз при поступлении:</b> {{ hosvisit.diagnosis ? hosvisit.diagnosis : "Нет" }}<br/>
            <b>Диагноз клинический:</b> {{ hosvisit.diagnosis ? hosvisit.diagnosis : "Нет" }}. <b>Дата установления клинического диагноза:</b> {{ hosvisit.visitDate.split(' ')[0] }}<br/>
            <b>Диагноз заключительный клинический:</b><br/>
            <b>Основной:</b> {{ discharge.diagnosis ? discharge.diagnosis : "Нет" }}<br/>
            <b>Сопутствующий:</b> {{ discharge.diagnosis_secondary ? discharge.diagnosis_secondary : "Нет" }}<br/>
        </p>
        <hr size=2 width="100%" align=center>
            <div style="display:block;width:50%;float:left"><b>Код МЭС:</b></div>
            <div style="display:block;width:50%;margin-left:50%"><b>Код МКБ:</b> {{ operation.mkb10 }}</div>
        <hr size=2 width="100%" align=center>
            <div style="display:block;width:50%;float:left"><b>Врач:</b> {{ reduce(medhistory.doctor_full_name) }}</div>
            <div style="display:block;width:50%;margin-left:50%"><b>Профессор:</b> Пальшин Г.А.</div>
        <p style="text-align:justify">
            Госпитализация в данном году по поводу данного заболевания впервыйе, всего 1 раз.<br/>
            Хирургические операции, методы обезболивания и послеоперационные осложнения
        </p>
        <table>
            <tr>
                <th>
                    Операция
                </th>
                <th>
                    Дата
                </th>
                <th>
                    Наркоз
                </th>
                <th>
                    Осложнения
                </th>
                <th>
                    Хирург
                </th>
                <th>
                    Код операции
                </th>
                <th>
                    Код КСГ
                </th>
            </tr>
            <tr>
                <td>
                    {{ operation.operationName }}
                </td>
                <td>
                    {{ operation.operationEnd.split(' ')[0] }}
                </td>
                <td>
                    {{ operation.anesthesia }}
                </td>
                <td>
                    {{ operation.complications }}
                </td>
                <td>
                    <text>{{ reduce(operation.operator_full_name) }}</text>
                    <text v-if="operation.assistOne"><br/>{{ reduce(operation.assistOne_full_name) }}</text>
                    <text v-if="operation.assistTwo"><br/>{{ reduce(operation.assistTwo_full_name) }}</text>
                </td>
                <td>
                    {{ operation.operationCode }}
                </td>
                <td>
                    {{ operation.operationCodeKSG }}
                </td>
            </tr>
        </table>
        <p style="text-align:justify">
            Другие виды лечения: {{ JSON.parse(title.cure_types).join(', ') }}<br/>
            Отметка о выдаче листка нетрудоспособности: {{ discharge.work_incapacity_cert }}<br/>
            <br/>
            Исход заболевания: {{ title.cure_result }}<br/>
            Трудоспособность: временно утрачена<br/>
            Для поступивших на экспертизу - заключение:<br/>
            Особые отметки:<br/>
            Критерии качества лечения<br/>
            Код МКБ: <b>{{ operation.mkb10 }}</b><br/>
            Комментарий: {{ title.comment }}<br/>
        </p>
        <p style="text-align:center">
            Оценка уровня качества лечения (в баллах)
        </p>
        <table>
            <tr>
                <th>
                    Наименование критерия оценки
                </th>
                <th>
                    Оценка в норме
                </th>
                <th>
                    0 ступень (лечащий врач)
                </th>
                <th>
                    I ступень (заведующий отделением)
                </th>
                <th>
                    II ступень
                </th>
                <th>
                    III ступень
                </th>
            </tr>
            <tr>
                <td>
                    A. Сбор информации
                </td>
                <td>
                    4,0
                </td>
                <td>
                    4,0
                </td>
                <td>
                    4,0
                </td>
                <td>
                </td>
                <td>
                </td>
            </tr>
            <tr>
                <td>
                    B. Обоснование диагноза
                </td>
                <td>
                    1,0
                </td>
                <td>
                    1,0
                </td>
                <td>
                    1,0
                </td>
                <td>
                </td>
                <td>
                </td>
            </tr>
            <tr>
                <td>
                    C. Оценка лечебных мероприятий
                </td>
                <td>
                    2,0
                </td>
                <td>
                    2,0
                </td>
                <td>
                    2,0
                </td>
                <td>
                </td>
                <td>
                </td>
            </tr>
            <tr>
                <td>
                    D. Преемственность
                </td>
                <td>
                    1,0
                </td>
                <td>
                    1,0
                </td>
                <td>
                    1,0
                </td>
                <td>
                </td>
                <td>
                </td>
            </tr>
            <tr>
                <td>
                    E. Оформление документации
                </td>
                <td>
                    2,0
                </td>
                <td>
                    2,0
                </td>
                <td>
                    2,0
                </td>
                <td>
                </td>
                <td>
                </td>
            </tr>
            <tr>
                <td>
                    Сумма баллов
                </td>
                <td>
                    10,0
                </td>
                <td>
                    10,0
                </td>
                <td>
                    10,0
                </td>
                <td>
                </td>
                <td>
                </td>
            </tr>
            <tr>
                <td>
                    УКЛ
                </td>
                <td>
                    1,0
                </td>
                <td>
                    1,0
                </td>
                <td>
                    1,0
                </td>
                <td>
                </td>
                <td>
                </td>
            </tr>
        </table>
        <hr size=2 width="100%" align=center>
        <div style="display:block;width:50%;float:left"><b>Врач:</b> {{ reduce(medhistory.doctor_full_name) }}</div>
        <div style="display:block;width:50%;margin-left:50%"><b>Профессор:</b> Пальшин Г.А.</div>
        <div class="pagebreak" />
</div>
</template>
<script setup>
    import { bloodTypes, rhfactor } from "@/common/locale"
    import { formatDate } from '@/common/rcfx.js'
</script>
<script>
export default {
    props: ['patient'],
    methods: {
        reduce(string) {
            return string.split(' ')[0] + ' ' + string.split(' ')[1].substring(0,1) + '. ' + string.split(' ')[2].substring(0,1) + '.'
        },
    },
    computed: {
        patientAge() {
            var birthdate = new Date(this.patient.birthdate.split('.').reverse().join('-'))
            var today = new Date()
            return Math.floor((today-birthdate)/3600000/24/365)
        },
        medhistory() {
            return this.$store.state.app.medhistory
        },
        hosvisit() {
            return this.$store.state.app.hosvisit
        },
        discharge() {
            return this.$store.state.app.discharge
        },
        operation() {
            return this.$store.state.app.operation
        },
        title() {
            return this.$store.state.app.title
        },
        discharge() {
            return this.$store.state.app.de
        },
        passport() {
            return JSON.parse(this.patient.passport)
        }
    }
}
</script>
<style scoped>
    .rc-table {
        border-collapse: collapse;
        border: solid 1px #333
    }
</style>
