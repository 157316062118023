<template>
<VaCard stripe stripe-color="success">
    <VaCardTitle>
      <VaButton
      icon="arrow_back_ios_new"
      color="primary"
      icon-color="#333"
      class="mr-3"
      preset="secondary"
      hover-behavior="opacity"
      :hover-opacity="0.4"
      @click="$router.back()"
    />
    Экспресс создание карточки пациента
    </VaCardTitle>

    <VaCardContent style="text-align:left">

        <VaForm autofocus ref="expressForm" class="items-baseline gap-6" style="display: flex; flex-direction: column; max-width:600px">
          <VaInput class="mb-3"
            requiredMark
            v-model="form.lastname"
            :rules="[(v) => !!v || 'Обязательное поле']"
            label="Фамилия"
          />

          <VaInput class="mb-3"
            requiredMark
            v-model="form.firstname"
            :rules="[(v) => !!v || 'Обязательное поле']"
            label="Имя"
          />
        
          <VaInput class="mb-3"
            requiredMark
            v-model="form.patronymic"
            :rules="[(v) => !!v || 'Обязательное поле']"
            label="Отчество"
          />

          <label class="va-input-label" style="color: var(--va-primary);">Пол <span class="va-input-label__required-mark">*</span></label>
          <VaRadio class="mb-3"
            v-model="form.sex"
            :rules="[(v) => (v === 'm' || v === 'f') || 'Обязательное поле']"
            :options="[
              {
                text: 'Мужской',
                value: 'm',
              },
              {
                text: 'Женский',
                value: 'f',
              },
            ]"
            value-by="value"
          />

          <VaDateInput class="mb-3"
            v-model="birthdate"
            firstWeekday="Monday"
            label="Дата рождения"
            :endYear="endYear"
            :startYear="startYear"
            manual-input
            clearable
            v-model:view="yearView"
            style="max-width:275px"
            :weekdayNames="weekdayNames"
            :monthNames="monthNames"
            :mask="{blocks: [2,2,4], delimiters: ['.','.','.']}"
            placeholder="ДД.ММ.ГГГГ"
            />

          <VaInput class="mb-3"
            v-model="form.phone"
            label="Телефон"
            placeholder="#(###)###-##-##"
            :mask="{blocks: [1,3,3,2,2], delimiters: ['(',')','-','-']}"
          />
              
          <VaButton class="mt-3" @click="validate() && addPatient()">
            Добавить
          </VaButton>
        </VaForm>
        </VaCardContent>
  </VaCard>
  <VaModal
    :allowBodyScroll=true
    v-model="gotError"
    size="small"
    hide-default-actions
    ref="error"
  >
    <h4 class="va-h4">
      Ошибка
    </h4>
    <p>
      Проверьте правильность заполнения полей
    </p>
    <template #footer>
      <VaButton @click.before="$refs.error.hide()" @click="gotError = !gotError"> Исправить </VaButton>
    </template>
  </VaModal>
</template>

<script>
import axiosInstance from "../services/api";
import { useForm } from 'vuestic-ui'

export default {
    data() {
        return {
            validate: useForm('expressForm').validate, 
            isValid: useForm('expressForm').isValid,
            diagnosisExists: false,
            bloodTypes: [
              {value: "1", text: "0(I)"},
              {value: "2", text: "A(II)"},
              {value: "3", text: "B(III)"},
              {value: "4", text: "AB(IV)"},
            ],
            rhfactor: [
              {value: "+", text: "Rh(+)"},
              {value: "-", text: "Rh(-)"},
            ],
            endYear: new Date().getFullYear(),
            startYear: 1900,
            weekdayNames:[ "ВС", "ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ" ],
            monthNames:[ "Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь" ],
            birthdate: null,
            form: {
              lastname:'',
              firstname:'',
              patronymic:'',
              birthdate: null,
              phone:'',
              sex:'',
              disability:'нет',
            },
            yearView: { type: "year", year: 2000 },
            gotError: false,
        };
    },
    computed: {
    },
    methods: {
      addPatient() {
        if (this.birthdate != null) {
          var d = new Date(this.birthdate);
          const dateString = d.getFullYear()+'-'+String(d.getMonth()+1).padStart(2, '0')+'-'+String(d.getDate()).padStart(2, '0')
          this.form.birthdate = dateString
        }
        axiosInstance
            .post('patients/', this.form)
            .then(response => {
              this.$vaToast.init({ message: 'Пациент добавлен', color: 'success', position: 'bottom-right'})
              this.$router.replace({name: 'regAdd', params: {patientId: response.data.id}})
            })
            .catch(() => {
              this.$vaToast.init({ title: 'Ошибка', message: 'Проверьте корректность заполнения полей', color: 'warning', position: 'bottom-right'})
            })
      },
    },
    mounted() {
    },
}

</script>
<style>
</style>
  