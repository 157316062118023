<template>
 <VaCard stripe stripe-color="primary" class="rc-fh" style="height:calc(100vh - 70px);overflow-y:scroll">
  <VaCardTitle>Пост медсестры</VaCardTitle>
  <VaCardContent>
    <VaDataTable :items="united" :columns="cols" class="compact" noDataHtml="Нет действующих назначений">
      <template #cell($)="{ rowData }">
        <VaIcon :name="rowData.paid ? 'attach_money' : 'cruelty_free'" :color="rowData.paid ? 'success' : 'primary'"/>
      </template> 
      <template #cell(patient_full_name)="{ rowData, rowIndex }">
        <VaButton preset="plain" class="rc-text-light" @click.before="currentPatient=rowIndex" @click="showPatient=!showPatient">{{ rowData.patient_full_name }}{{ rowData.patient_birthdate ? ', ' + rowData.patient_birthdate.split('-').reverse().join('.') : '' }}</VaButton><br/>
        <span class="rc-text-s">ИБ №{{ rowData.id }}, Палата: {{ rowData.room }}</span><br/>
        <span class="rc-text-s">Поступление: {{ rowData.openDate }}</span><br/>
        <span class="rc-text-s mt-2"><VaChip size="small" :color="rowData.closed ? 'warning' : 'success'">{{ rowData.closed ? 'История болезни закрыта '+rowData.closeDate : 'История болезни открыта' }}</VaChip></span>
      </template>
      <template #cell(prescriptions)="{ rowData }">
        <VaList>
          <VaListItem v-for="(pre, index) in rowData.prescriptions">
            {{ pre.name }} до {{ pre.dateEnd }}
          </VaListItem>
        </VaList>
      </template>
    </VaDataTable>
  </VaCardContent>
 </VaCard>
 <VaModal :allowBodyScroll=true v-if="showPatient" v-model="showPatient" size="large" hideDefaultActions :closeButton="true" fixed-layout>
    <div class="rc-text-l mb-2">{{ united[currentPatient].patient_full_name }}</div>
    <span class="mb-2">ИБ №{{ united[currentPatient].id }}, Палата: {{ united[currentPatient].room }}</span><br/>
    <span class="mb-2">Поступление: {{ united[currentPatient].openDate }}</span><br/>
    <span class="rc-text-s mt-2"><VaChip size="small" :color="united[currentPatient].closed ? 'warning' : 'success'">{{ united[currentPatient].closed ? 'История болезни закрыта' : 'История болезни открыта' }}</VaChip></span>

    <VaAlert class="mb-2 noflex" border="left" color="success">
      <VaCardTitle class="rc-text-m">Исследования</VaCardTitle>
      <VaCardContent>
      <VaDataTable
        :columns="testsLabels" 
        :items="united[currentPatient].tests"
        striped
        clickable
        noDataHtml="Исследования не назначались"
        >
        <template #cell(type)="{ rowData }">
          {{ rowData.planDate }}<br/>
          {{ testTypes[rowData.type] }}
          <VaAlert v-if="rowData.comment" color="#FFFFFF" icon="feedback" class="mb-2">{{ rowData.comment }}</VaAlert>
        </template>
        <template #cell(taken)="{ rowData }">
          <div v-if="rowData.taken">
            <div v-if="new Date(rowData.factDate.split(' ')[0].split('.').reverse().join('-')) &gt; new Date(rowData.planDate.split('.').reverse().join('-'))">
              <VaChip flat icon="warning" color="secondary">Взят: {{rowData.factDate}}</VaChip>
              <p class="rc-text-s">С опозданием</p>
            </div>
            <div v-else>
              <VaChip flat icon="done" color="success">Взят: {{rowData.factDate}}</VaChip>
              <VaChip v-if="rowData.result!=''" flat icon="done" color="success">Внесен результат</VaChip>
              <VaChip v-else="rowData.result!=''" flat icon="schedule" color="secondary">Ожидается результат</VaChip>
            </div>
          </div>
          <div v-else>
            <div v-if="new Date(today) &gt; new Date(rowData.planDate.split('.').reverse().join('-'))">
              <VaChip flat icon="error" color="danger">Дата забора прошла</VaChip>
            </div>
            <div v-else>
              <VaChip flat icon="schedule" color="secondary">Запланирован</VaChip>
            </div>
          </div>
        </template>
        <template #cell(actions)="{ rowData, rowIndex }">
          <VaButton :disabled="united[currentPatient].closed" :loading="takingTest[rowIndex]" v-if="!rowData.taken" icon="labs" color="success" preset="secondary" @click="takeTest(rowData.id, rowIndex, rowData.type, true)"/>
          <VaButton :disabled="united[currentPatient].closed || rowData.result!=''" :loading="takingTest[rowIndex]" v-else icon="cancel" color="danger" preset="secondary" @click="takeTest(rowData.id, rowIndex, rowData.type, false)"/>
        </template>
      </VaDataTable>
    </VaCardContent>
    </VaAlert>

    <VaAlert class="mb-2 noflex" border="left">
      <VaCardTitle class="rc-text-m">Назначения</VaCardTitle>
      <VaCardContent>
      <VaDataTable
        :columns="prescriptionsLabels"
        :items="united[currentPatient].prescriptions"
        :loading="prescriptionsLoading"
        striped
        clickable
        noDataHtml="Назначений пока нет"
        >
        <template #cell(name)="{ rowData }">
          <div class="rcflex row">
            <div class="rcflex column" style="line-height:1.3rem">
              <p>{{ rowData.name }}, {{ rowData.dosage }}</p>
              <p>{{ rowData.method }} - {{ rowData.freq }} рвд</p>
              <p>{{ rowData.dateStart }} - {{ rowData.dateEnd }}</p>
              <VaAlert v-if="rowData.comment" color="#FFFFFF" icon="feedback" class="mb-2">{{ rowData.comment }}</VaAlert>
            </div>
          </div>
        </template>
        <template #cell(take)="{ rowData }">
          <div class="rcflex row">
          <div v-for="item,index in rowData.fact">
            <div class="mr-3 column">
            <div style="line-height:1.5rem" class="rcflex center">{{ formatDate(new Date(parseInt(index)), 'short') }}</div>
              <div v-for="f, logIndex in rowData.fact[index]" class="rcflex center">
                          <VaDropdown trigger="hover" placement="left-center" :offset="[10, 0]" class="rcflex">
                            
                            <template #anchor>
                              <VaButton 
                              :disabled="united[currentPatient].closed || ( Number(new Date().getTime()) - index < 0)"
                              @click.before="!f.datetime && !f.missed ? addLogRecord(rowData.id, rowData.medhistory, rowData.name, index, logIndex) : ''"
                              @click="f.datetime && removeLogRecord(f.id, f.prescription, rowData.medhistory, index, logIndex)" 
                              class="mr-2" 
                              :icon="f.datetime ? 'done' : f.missed ? 'error' : 'schedule'" 
                              :preset="!f.datetime && !f.missed ? 'plainOpacity' : 'plain'" 
                              :color="f.datetime ? 'success' : f.missed ? 'warning' : 'secondary'"/>
                            </template>

                            <VaDropdownContent style="background-color:#f8f8f8;border:1px dashed #dddddd">
                              {{ f.datetime ? 'Исполнено: ' + f.datetime + '\nМедсестра: ' + f.nurse_full_name : f.missed ? 'Просрочено' : 'Запланировано' }}
                            </VaDropdownContent>

                          </VaDropdown>
              </div>
            </div>
          </div>
          </div>
        </template>
      </VaDataTable>
    </VaCardContent>
    </VaAlert>
 </VaModal>
</template>
<script setup>
  import { testTypes } from "@/common/locale"
  import { formatDate, formatTime } from '@/common/rcfx.js'

</script>
<script>
import axiosInstance from "../services/api"
import PatientProfile from '@/components/PatientProfile'
import VueQRCodeComponent from 'vue-qrcode-component-v3'
import _ from 'lodash'
import { VaCard, VaCardContent } from "vuestic-ui/web-components"
Date.prototype.addDays = function(days) {
    this.setDate(this.getDate() + parseInt(days));
    return this;
}

export default {
    components: { PatientProfile, VueQRCodeComponent },
    data() {
        return {
          prescriptionsLabels: [
              { key: "name", label: "Препарат, частота, курс", width: "40%" },
              //{ key: "dateStart", label: "Начало", width: 25 },
              { key: "take", label: "Журнал приема", width: "60%" },
              //{ key: "dateEnd", label: "Конец", width: 25 },
            ],
          testsLabels: [
              // { key: "id", width: 1 },
              // { key: "planDate", label: "Дата забора", width: 25 },
              { key: "type", label: "Дата и тип исследования", width: 25 },
              { key: "taken", label: "Статус", width: 24 },
              { key: "actions", label: "Действия", width: 24 },
            ],
          logsLoading: true,
          testsLoading: true,
          takingTest: [],
          prescriptionsLoading: true,
          logsJoined: false,
          refreshLogs: false,
          currentPatient: Number,
          showPatient: false,
          mhList: [],
          mhIDs: [],
          tests: [],
          logs: [],
          prescriptions: [],
          united: [],
          cols: [
            { key: "$", label: '$' },
            { key: "patient_full_name", label: 'ФИО Пациента', width: 25},
            { key: "doctor_full_name", label: 'Врач' },
            { key: "prescriptions", label: 'Назначения' },
          ],
          today: formatDate(new Date, 'us'),
        }
    },
    computed: {
      userProfile: function() {return this.$store.state.app.account.profile;},
      prescriptionsReady: function() {
        return  !this.prescriptionsLoading && !this.logsLoading ? true : false
      },
      allDone: function() {
        return !this.testsLoading && this.logsJoined ? true : false
      }
    },
    watch: {
      prescriptionsReady: function(newValue) {
        if (newValue == true) {
         this.joinLogs(this.prescriptions, this.logs)
        }
      },
      allDone: function(newValue) {
        if (newValue == true) {
         this.joinTables(this.mhList, this.prescriptions, this.tests)
        }
      },
      refreshLogs: function(newValue) {
        if (newValue == true) {
          this.united
        }
      }
    },
    methods: {
      joinLogs(preObj, logsObj){
        var pretmp = []
        for (let i=0;i<preObj.length;i++) {
          this.prescriptions[i].logs=[]
          for (let e=0;e<logsObj.length;e++) {
            if (logsObj[e].prescription == preObj[i].id) {
                //console.log('match! ' + JSON.stringify(this.prescriptions[i].logs))
                this.prescriptions[i].logs.push(this.logs[e])
              }
          }
        }
        this.logsJoined = true
      },
      joinTables(mhObj, preObj, testsObj) {
        
        for (let i=0;i<mhObj.length;i++) {
          var prestmp = []
          var teststmp = []
          //console.log('---Чекаем назначения')
          for (let e=0;e<preObj.length;e++) {
            //console.log('Сравниваю ' + preObj[e].medhistory + ' и ' + mhObj[i].id)
              if (preObj[e].medhistory == mhObj[i].id) {
                //console.log('match!')
                prestmp.push(preObj[e])
              }
            }
          //console.log('---Чекаем анализы')
          for (let e=0;e<testsObj.length;e++) {
          //console.log('Сравниваю ' + testsObj[e].medhistory + ' и ' + mhObj[i].id)
            if (testsObj[e].medhistory == mhObj[i].id) {
              //console.log('match!')
              teststmp.push(testsObj[e])
            }
          }
              this.united.push({
                id: mhObj[i].id,
                patient_full_name: mhObj[i].patient_full_name,
                patient_birthdate: mhObj[i].patient_birthdate,
                doctor_full_name: mhObj[i].doctor_full_name,
                openDate: mhObj[i].openDate,
                closed: mhObj[i].closed,
                closeDate: mhObj[i].closeDate,
                paid: mhObj[i].paid,
                room: mhObj[i].room,
                user: mhObj[i].user,
                patient: mhObj[i].patient,
                prescriptions: prestmp,
                tests: teststmp,
              })
        }
        this.convertLogs(this.united)
      },
      convertLogs(list) {
        var today = new Date(formatDate(new Date, 'ru').split('.').reverse().join('-')).getTime()
        //console.log('today is: ' + today)
        // перебор основного объединенного массива
          for (var i = 0; i < list.length; i++) {
              // i = номер в объединенном массиве
              // e = номер в назначения
              // d = номер даты в графике
              // f = номер записи в журнале исполнений назначений
              if (list[i].prescriptions.length > 0) {
                  // перебор массива назначений для одной записи в объединенном массиве
                  for (var e = 0; e < list[i].prescriptions.length; e++) {
                      var dates = this.makeGraph(list[i].prescriptions[e].dateStart, list[i].prescriptions[e].duration)
                          list[i].prescriptions[e].fact = {}
                          // перебор массива из дат графика приема
                          for (var d = 0; d < dates.length; d++) {
                              var currentDate = new Date(dates[d].split('.').reverse().join('-')).getTime()
                              // перебор массива журнала для одной записи в массиве назначений
                              list[i].prescriptions[e].fact[currentDate] = []
                              var count = 0
                              for (var f = 0; f < list[i].prescriptions[e].logs.length; f++) {
                                  var foundDate = new Date(list[i].prescriptions[e].logs[f].datetime.split(' ')[0].split('.').reverse().join('-')).getTime()
                                  if (currentDate === foundDate) {
                                    list[i].prescriptions[e].fact[currentDate].push(list[i].prescriptions[e].logs[f])
                                    count++
                                  }

                              }
                              // дописываем неисполненные назначения пустыми объектами
                              if (count<list[i].prescriptions[e].freq) {
                                  var delta = parseInt(list[i].prescriptions[e].freq) - count
                                  for (var k=0;k<delta;k++){
                                    var missed = currentDate < today ? true : false
                                    list[i].prescriptions[e].fact[currentDate].push({missed: missed})
                                  }
                                }
                          }
                  }
              }
          }
      },
      makeGraph(start, duration) {
        var result = []
        for (let i=0; i<duration; i++) {
          var a = start.split('.').reverse().join('-')
          var b = new Date(a).addDays(i)
          result.push(formatDate(b,'ru'))
        }
        return result
      },
      takeTest(testId, testIndex,  testType, status) {
        this.takingTest[testIndex] = true
        var message = status ? 'Исследование ' + testTypes[testType] + ' исполнено' : 'Исследование ' + testTypes[testType] + ' отменено'
        var color = status ? 'success' : "warning"
        axiosInstance
            .patch('/medhistory/test/' + testId, {taken: status, result: ''})
            .then(response => {
              console.log('test Index = '+testIndex)
                this.united[this.currentPatient].tests[testIndex].taken = status
                this.united[this.currentPatient].tests[testIndex].factDate = response.data.factDate
                this.$vaToast.init({ message: message, color: color, position: 'bottom-right'})
                var recipient = this.united[this.currentPatient].user
                var payload = {
                  title: "Исследование",
                  body: 'Заказанный вами "' + testTypes[testType] + '" для пациента ' + this.united[this.currentPatient].patient_full_name + ' взят ' + response.data.factDate +'. Исполнитель: ' + this.$store.state.app.account.profile.full_name,
                }
                this.addEvent("comment", payload, recipient)
                this.takingTest[testIndex] = false
            })
            .catch(error => {
              this.takingTest[testIndex] = false
              console.log('Test get error: ' + error);
            })
      },
      getLogs() {
        axiosInstance
            .get('/medhistory/prescriptionlogs?medhistory=' + this.mhIDs.toString())
            .then(response => {
              this.logs = response.data.results
            })
            .catch(error => {
              console.log('Logs get error: ' + error);
            })
            .finally(() => {
              this.logsLoading = false
            })
      },
      updateFact(medhistory, prescriptionId, logrecord=false, dateIndex, logIndex) {
        console.log(logrecord)
        var uIndex = this.united.findIndex((item) => item.id == medhistory)
        var pIndex = this.united[uIndex].prescriptions.findIndex((item) => item.id == prescriptionId)
        delete this.united[uIndex].prescriptions[pIndex].fact[dateIndex][logIndex]
        this.united[uIndex].prescriptions[pIndex].fact[dateIndex][logIndex] = logrecord
      },
      removeLogRecord(logId, prescriptionId, medhistoryId, dateIndex, logIndex){
        axiosInstance
            .delete(`/medhistory/prescriptionlogs/remove/`+logId)
            .then(() => {
              this.updateFact(medhistoryId, prescriptionId, {missed: false}, dateIndex, logIndex)
              this.$vaToast.init({ message: 'Исполнение отменено', color: 'warning', position: 'bottom-right'})
            })
            .catch(error => {
              console.log('Prescription LOG post error: ' + error);
            })
      },
      addLogRecord(prescriptionId, medhistoryId, prescriptionName, dateIndex, logIndex) {
        var now = new Date
        
        var record = {
            datetime: formatDate(now, 'us') + ' ' + formatTime(now),
            prescription: prescriptionId,
            executedBy: this.userProfile.id,
            medhistory: medhistoryId,
        }
        axiosInstance
            .post(`/medhistory/prescriptionlogs/new`, record)
            .then(response => {
              this.updateFact(medhistoryId, prescriptionId, response.data, dateIndex, logIndex)
              this.$vaToast.init({ message: 'Назначение ' + prescriptionName + ' исполнено ' + record.datetime, color: 'success', position: 'bottom-right'})
            })
            .catch(error => {
              console.log('Prescription LOG post error: ' + error);
            })
      },
      getTests() {
        this.testsLoading = true
        axiosInstance
            .get('/medhistory/tests?medhistory=' + this.mhIDs.toString())
            .then(response => {
              this.tests = response.data.results
              //this.joinTables(this.mhList, this.tests)
            })
            .catch(error => {
              console.log('Test get error: ' + error);
            })
            .finally(() => {
              this.testsLoading = false
            })
      },
      getPrescriptions() {
      this.prescriptionsLoading = true
      axiosInstance
          .get('/medhistory/prescriptions?medhistory=' + this.mhIDs.toString())
          .then(response => {
            this.prescriptions = response.data.results
            //this.joinTables(this.mhList, this.prescriptions)
          })
          .catch(error => {
            console.log('Test get error: ' + error);
          })
          .finally(() => {
            this.prescriptionsLoading = false
          })
      },
      getMedhistoryList() {
        axiosInstance
            .get(`/medhistory/list`)
            .then(response => {
                var results = response.data.results
                // this.mhList = _.sortBy(results,['room']).filter((item)=>item.discharged === false).filter((item)=>item.closed === false).filter((item)=>item.accepted === false)
                this.mhList = _.sortBy(results,['room'])
                for (let i=0; i<this.mhList.length; i++) {
                  this.mhIDs.push(this.mhList[i].id)
                }
                if (results.length>0) {
                  this.getTests()
                  this.getLogs()
                  this.getPrescriptions()
                }
              })
            .catch(error => {
              console.log('Templates: error ' + error);
        })
            .finally(() => {
              console.log('Templates: Request completed')
              //this.getPatient(this.medhistory.patient)
        });
      },
      addEvent(type,payload,user) {
        console.log('Trying to add a Event')
        var event = {
          sent:0,
          event: type,
          payload: JSON.stringify(payload),
          recipient: user,
        }
        console.log(JSON.stringify(event))

        axiosInstance
            .post('/events/new', event)
            .then(response => {
              const message = 'Evend sent ok: ' + JSON.stringify(response)
              console.log(message);
        })
            .catch(error => {
            console.log('Event sent failed: ' + JSON.stringify(error))
        })
      },
    },
    created() {
      !this.$store.state.auth.status.loggedIn && this.$router.push('/login')
    },
    mounted() {
      this.getMedhistoryList()
    }
}
</script>