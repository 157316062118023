<template>
  <VaCard stripe stripe-color="primary">
    <VaCardTitle>
      Записи пациентов
    </VaCardTitle>
    <VaCardContent>
      <div class="va-text-right">
      <VaButton icon-right="add" class="mb-3" preset="primary" @click="$router.push('/regs/add/new')">
        Добавить
      </VaButton>
    </div>
    <div class="rcflex rcflex-btw row">
      <span class="rcflex">
        <VaButton
          icon="arrow_back_ios"
          color="primary"
          icon-color="#333"
          preset="secondary"
          hover-behavior="opacity"
          :hover-opacity="0.4"
          @click="day('prev')"
        />
      </span>
      <span class="rcflex center">
        <span :key="dayIndex">Расписание приема на {{ formatDate(dateString, 'ru') }}</span>
      </span>
      <span class="rcflex">
        <VaButton
          icon="arrow_forward_ios"
          color="primary"
          icon-color="#333"
          preset="secondary"
          hover-behavior="opacity"
          :hover-opacity="0.4"
          @click="day('next')"
        />
      </span>
    </div>
       
        <VaDataTable v-if="scheduleReady"
        :items="schedule"
        :columns="columns"
        sticky-header
        :key="regs"
        hoverable
        :loading="!scheduleReady"
        >
    <template #cell(patient)="{ row, isExpanded, rowData }">
      <VaIcon v-if="rowData.patient"
        :name="rowData.paid ? 'attach_money' : 'cruelty_free'"
        :color="rowData.paid ? 'success' : 'primary'"
        class="w-full mr-2 mb-2"
        text="asd"
      />
      <router-link :to="{ path: '/patients/' + rowData.patient_id }">{{ rowData.patient }}</router-link>
      <VaIcon v-if="rowData.visited && rowData.visited===-1"
        name="schedule" 
        color="secondary"
      />
      <VaIcon v-if="rowData.visited >= 0"
        :name="rowData.visited==1 ? 'done' : 'delete_history'" 
        :color="rowData.visited==1 ? 'success' : 'danger'"
        class="ml-2 mb-2"
      />
    </template>

    <template #cell(actions)="{ row, isExpanded, rowData }">
      <VaButton v-if="rowData.patient"
        :icon="isExpanded ? 'va-arrow-up': 'va-arrow-down'"
        preset="primary"
        class="w-full"
        @click="row.toggleRowDetails()"
      />
    </template>

    <template #expandableRow="{ rowData }">
      <div class="flex gap-2 area-more-info">
        <div class="pl-2">
          <div class="flex items-center mb-2">
            <b>Время записи: {{ rowData.regDate }}</b>
          </div>
          <div class="flex items-center mb-2">
            <span>Дата создания записи: {{ rowData.updated }}</span>
          </div>
          <div class="flex items-center mb-2">
            <span>Прием: {{ rowData.examType==0 ? "Первичный" : "Повторный" }}</span>
          </div>
          <div class="flex items-center mb-2">
            <span>Отделение: {{ rowData.depType==0 ? "Амбулатория" : "Стационар" }}</span>
          </div>
          <div class="flex items-center mb-4">
            <span>Причина обращения: {{ rowData.examReason }}</span>
          </div>
          <VaDivider />
          <div class="flex items-center" v-if="rowData.visited<0">
            <VaButton color="danger" preset="secondary" icon="clear" class="mr-2" @click="setDNC(rowData.id)">Пометить неявку</VaButton>
            <VaButton color="info" preset="primary" icon="sync_alt" class="mr-2" @click="toggleMoveRegModal(rowData)">Перенести запись</VaButton>
          </div>
          <div class="flex items-center" v-if="rowData.visited==0">
            <VaAlert color="warning">Пациент на прием не явился</VaAlert>
          </div>
          <div class="flex items-center" v-if="rowData.visited==1">
            <VaAlert color="success">Прием состоялся</VaAlert>
          </div>
        </div>
      </div>
    </template>
  </VaDataTable>
  
      <p class="mt-2" v-show="!isTableLoading">Всего записей в базе: {{ regsCount }}</p>
      <VaSkeleton v-show="isTableLoading" tag="p" variant="text" class="mt-2" lastLineWidth="25%" :lines="1"/>
      
    </VaCardContent>
  </VaCard>

  <VaModal
    :allowBodyScroll=true
    v-model="moveRegModal"
    size="small"
    hideDefaultActions
    ref="moveRegModal"
  >
    
    <template #default>
      <h6 class="va-h6">
        Перенос записи
      </h6>
      <p class="mb-1">
        Пациент: {{ movingReg.patient}}<br/>
      </p>
      <p class="mb-1">
        Врач: {{ movingReg.doctor_full_name}}<br/>
      </p>
      <p class="mb-1">
        Текущая запись на: {{ movingReg.regDate }}
      </p>
      <VaSelect
            label="Лечащий врач"
            v-model="doctor_id"
            :text-by="(option) => option.full_name"
            :value-by="(option) => option.id"
            :options="doctors"
            placeholder="Выберите врача"
          >          
          </VaSelect>
      <div class="rcflex ">
          <VaDateInput class="mb-3"
            
            :allowedDays="(date) => allowedDays(date)"
            :startYear="currentYear"
            :endYear="currentYear"
            label="Дата"
            v-model="tempdate"
            clearable
            style="width:200px"
          >

            <template #append>
              <VaIcon
                  :name="date_isValid ? 'check_circle' : 'warning'"
                  class="ml-2"
                  :color="date_isValid ? 'success' : 'warning'"
                />
              </template>
            </VaDateInput>
          </div>

          <p v-if="tempdate && doctor_id">
          <label class="va-input-label" style="color: var(--va-primary);">Время записи</label>
            </p>
          <p v-if="tempdate && doctor_id" class="mb-3 ml-3">
          {{ temptime ? temptime : 'не выбрано' }}
          <VaIcon
                  :name="temptime ? 'check_circle' : 'warning'"
                  class="ml-4"
                  :color="temptime ? 'success' : 'warning'"
                />
          </p>
          <span v-if="!scheduleLoading" class="mb-4">
            <VaChip
              v-if="tempdate"
              v-for="(disabled, time,  index) in availTimeIntervals"
              :key="index"
              :disabled="disabled"
              class="mt-2 mr-2"
              style="width:60px"
              outline shadow
              @click="temptime = time"
            >
              {{ time }}
            </VaChip>
          </span>
    </template>
    <template #footer>
      <VaButton @click="$refs.moveRegModal.hide();" preset="primary" color="secondary" class="mr-2" icon="close">Отменить</VaButton>
      <VaButton @click="$refs.moveRegModal.hide();patchReg()" preset="primary" icon="sync_alt">Перенести</VaButton>
    </template>
  </VaModal>

</template>
<script setup>
import { allowedDays, formatDate } from "@/common/rcfx"
</script>
<script>
import axiosInstance from "../services/api";
import UserService from "../services/user.service";
import EventBus from "../common/EventBus";
import debounce from 'lodash.debounce'
//import * as rcfx from "@/common/rcfx"

export default {
  props: {
      date: String,
    },
  data() {
        return {
            columns: [
              //{ key: "id", width: 1 },
              { key: "time", label: "Время", width: 1 },
              { key: "patient", label: "Пациент"},
              { key: "doctor_full_name", label: "Врач", width: "30%" },
              // { key: "bill", label: "Оплата", width: "20%" },
              { key: "actions", label: "Действия", width: "20%" },
            ],
            //showSidebar:this.$store.state.app.sidebarStatus,  
            regs: [],
            schedule: [],
            scheduleTimes: [],
            prev: String,
            next: String,
            isTableLoading: true,
            query: '',
            tooShortQuery: false,
            dateString: new Date,
            dayIndex: 0,
            regsCount: 0,
            scheduleReady: false,
            doctors: [],
            moveRegModal: false,
            movingReg: {},
            // Форма переноса
            scheduleLoading: false,
            doctor_id: Number,
            doctorSchedule: {},
            scheduleUpdated: 0,
            availTimeIntervals:{},
            currentYear: new Date().getFullYear(),
            tempdate: new Date(Date.now()),
            temptime:'',
            form: {
              regDate: '',
              user: '',
            },
        }
  },
  computed: {
    date_isValid: function() {
        return (this.tempdate != '') ? true : false
      },
  },
  watch: {
    doctor_id: function(newValue) {
      if (newValue) {
        console.log('doctor changed')
        this.getSchedule(formatDate(this.tempdate,'us'))
        this.form.user = newValue
      }
    },
    scheduleUpdated: function(newValue) {
      if (newValue) {
        console.log('schedule updated')
        this.checkTime()
        this.temptime=''
      }
    },
    tempdate: function(newValue) {
        if (newValue) {
          console.log('date updated')
          this.getSchedule(formatDate(newValue,'us'))
        }
      },
    dateString: function(newValue) {
      if (newValue) {
        console.log('dateString watcher triggered')
        this.getRegs()
        }
      //this.regObject = this.regs
    },
    showSidebar: function() {
      this.$store.dispatch('app/toggleSidebar',true)
    },
    // regs: function(newValue){
    //   // this.addCol(this.regs)
    //   // this.makeSchedule(this.regs)
    // },
  },
  computed: {
  },
  methods: {
    toggleMoveRegModal(reg) {
      this.movingReg = reg
      this.form.regDate = reg.regDate
      this.form.user = reg.user
      this.doctor_id = Number(reg.doctor_id)
      this.temptime = reg.time
      this.moveRegModal = true
    },
    setDNC(reg_id) {
      axiosInstance
        .patch('/registrations/edit/'+ reg_id, {visited: 0})
        .then((response) => {
          console.log('DNC status setted')
          this.getRegs()
        },
        (error) => {
          //console.log(JSON.stringify(error))
          console.log('DNC setting failed')
        })
    },
    addCol(array) {
    var i = 0
      while (i < array.length) {
        console.log(this.ruDateTime(array[i].regDate).time)
        array[i].time = this.ruDateTime(array[i].regDate).time
        i++
      }
      this.makeSchedule(array)
      // console.log(array)
    },
    sortRegs( a, b ) {
      if ( a.time < b.time ) return -1;
      if ( a.time > b.time ) return 1;
      return 0;
    },
    makeSchedule(array) {
      // console.log('starting makeing schedule')
      //array.sort(this.sortRegs)
      this.scheduleTimes = []
      for (let i = 9; i < 20; i++) {
        // if (i!=13) {
            // Часы XX:00
            var int = String(i).padStart(2, '0')+':00'
            var interval = {}
            interval.time = int
            this.scheduleTimes.push(interval)
            // Часы XX:30
            int= String(i).padStart(2, '0')+':30'
            var interval = {}
            interval.time = int
            this.scheduleTimes.push(interval)
          // }
        }
      this.schedule = []
      var i = 0
      while (i < this.scheduleTimes.length) {
        let foundRegs = array.filter((reg) => {
          return reg.time === this.scheduleTimes[i].time
        })
        if (foundRegs.length>0) {
          for (let j=0; j<foundRegs.length; j++) {
            if (j>0) foundRegs[j].time = ""
            this.schedule = [...this.schedule, foundRegs[j]]
          }
        }else{
          this.schedule.push(this.scheduleTimes[i])
        }
        i++
      }
      this.scheduleReady = true
    },
    day(direction) {
      this.dayIndex = Date.now()
      switch (direction) {
        case 'next':
          this.dateString.setDate(this.dateString.getDate() + 1)
          return this.getRegs()
        case 'prev':
          this.dateString.setDate(this.dateString.getDate() - 1)
          return this.getRegs()
      }
    },
    ruDateTime(dt) {
        var day, month, year, hours, minutes, seconds;
        var d = dt.split(' ')[0]
        var t = dt.split(' ')[1]
        var result = d.match("[0-9]{2}([\-/ \.])[0-9]{2}[\-/ \.][0-9]{4}");
        if(null != result) {
            var dateSplitted = result[0].split(result[1]);
            day = dateSplitted[0];
            month = dateSplitted[1];
            year = dateSplitted[2];
        }
        var result = d.match("[0-9]{4}([\-/ \.])[0-9]{2}[\-/ \.][0-9]{2}");
        if(null != result) {
            var dateSplitted = result[0].split(result[1]);
            day = dateSplitted[2];
            month = dateSplitted[1];
            year = dateSplitted[0];
        }
        var result = t.match("[0-9]{2}([\-/ \:])[0-9]{2}[\-/ \:][0-9]{2}");
        if(null != result) {
            var timeSplitted = result[0].split(result[1]);
            //console.log("res is " + result)
            hours = timeSplitted[0];
            minutes = timeSplitted[1];
            seconds = timeSplitted[2];
        }

        if(month>12) {
            aux = day;
            day = month;
            month = aux;
        }
        var obj = {}
        obj.date = day+"/"+month+"/"+year
        obj.time = hours+":"+minutes
        return obj
    },
    // formatDate: принимает первым параметром объект Date, вторым параметром формат вывода en|ru
    // возвращает String в виде Даты
    formatDate(dateObject, format) {
        var d = dateObject
        switch (format) {
          case 'us':
            return String(String(d.getFullYear() + '-'+String(d.getMonth()+1) + '-'+d.getDate()))
          case 'ru':
            return String(d.getDate()).padStart(2, '0') + '.'+String(d.getMonth()+1).padStart(2, '0') + '.'+String(d.getFullYear())
        }
    },
    showPatient(event) {
      this.$router.push('patients/' + event.item.id)
    },
    patchReg() {
      this.form.regDate = formatDate(this.tempdate,'us') + ' ' + this.temptime
      this.form.user = this.doctor_id
      console.log('Patching a registration entry')
      axiosInstance
        .patch('/registrations/edit/' + this.movingReg.id, this.form)
        .then((response) => {
          this.$vaToast.init({ message: 'Запись перенесена на ' + response.data.regDate, color: 'success', position: 'bottom-right'})
          this.getRegs()
        })
    },
    getRegs() {
      console.log('updating Regs')
      axiosInstance
        .get('/registrations/?date=' + this.formatDate(this.dateString, 'us'))
        .then((response) => {
            this.regsCount = response.data.count
            this.regs = response.data.results
            this.addCol(this.regs)
            // this.makeSchedule(this.regs)
        },
        (error) => {
          //console.log(JSON.stringify(error))
          console.log('Regs request failed')

        })
        .finally(() => {
          this.isTableLoading = false
        })
    },
    getDoctors() {
      axiosInstance
      .get(`/staff/?group=doctors`)
      .then(response => {
        this.doctors = response.data.results;
      })
      .catch(error => {
        console.log('Doctors Request Failed: ' + error);
      })
      .finally(() => {
        console.log('Doctors: Request successfully completed')
      });
    },
    getSchedule(dateString) {
      console.log('getting doctors schedule')
      this.scheduleLoading = true
      axiosInstance
          .get(`registrations/doctor/` + this.doctor_id + '?date=' + dateString)
          .then(response => {
            if (response.data.count>0) {
              this.doctorSchedule = response.data.results;
            }else{
              this.doctorSchedule = {open:true}
            }
          })
          .catch(error => {
          console.log('Schedule Request Failed: ' + error);
      })
          .finally(() => {
            console.log('Schedule: Request successfully completed')
            this.scheduleLoading = false
            this.scheduleUpdated = Date.now()
      });
    },
    checkTime() {
      console.log('Checking intervals')
      let doctorSch=[]
      for (var sch in this.doctorSchedule) {
        if (this.doctorSchedule[sch].visited < 0) {
          var oneRecord = this.doctorSchedule[sch].regDate
          doctorSch.push(oneRecord)
        }
      }
      // Итератор интервалов от 9:00 до 19:30 с обедом с 13:00 до 14:00
      let intervals={}
      for (let i = 9; i < 20; i++) {
        // if (i!=13) {
          var int = String(i).padStart(2, '0')+':00'
          var dateInt = formatDate(this.tempdate,'ru') + ' ' + int + ':00'
          this.availTimeIntervals[int] = doctorSch.includes(dateInt) ? true : false
          int= String(i).padStart(2, '0')+':30'
          var dateInt = formatDate(this.tempdate,'ru') + ' ' + int + ':00'
          this.availTimeIntervals[int] = doctorSch.includes(dateInt) ? true : false
        // }
      }
    },
    debouncedSearch: debounce(function (query) {
              this.searchPatient(query);
            }, 600)
  },
  mounted() {
    this.getDoctors()
    this.getRegs()
    console.log('date is ' + this.date)
  },
}

</script>

<style>
</style>
