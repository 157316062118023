<template>
  <VaCard stripe stripe-color="danger" class="ma-4">
    <VaCardTitle>Ошибка</VaCardTitle>
    <VaCardContent>
      <VaAlert
        icon="warning"
        color="warning"
        class="rcflex pa-4"
      >
      <template #title>
        <span class="rc-text-l rc-text-bold mb-4">Доступ запрещен</span>
      </template>
      <template #default>
        Причина: {{error[code]}}
      </template>
      </VaAlert>
    </VaCardContent>
    <VaCardContent>
      <VaButton
            class="mr-3"
            icon="cancel"
            preset="primary"
            color="secondary"
            @click="$router.back()"
            >
            Назад
          </VaButton>
    </VaCardContent>
  </VaCard>
</template>
<script>
export default {
  props: ['code'],
  data() {
    return {
      error: { e600: 'У вас нет разрешений на просмотр этого объекта',
                e601: 'Нельзя редактировать осмотр, созданный другими пользователями',
                e602: 'Нельзя редактировать предоперационный эпикриз, созданный другими пользователями',
            },
      }
    }
}
</script>
