<template>
  <VaCard stripe stripe-color="success" v-if="!preopExists">
    <VaCardTitle>
      <VaButton
      icon="arrow_back_ios_new"
      color="primary"
      icon-color="#333"
      class="mr-3"
      preset="secondary"
      hover-behavior="opacity"
      :hover-opacity="0.4"
      @click="$router.back()"
    />
    Дневник
    </VaCardTitle>
  
    <VaCardContent>
      <VaAlert
        color="primary"
        border="left"
        class="mb-6"
        >
        Этот дневник будет прикреплен к истории болезни №{{ recordId ? form.medhistory : medhistoryId }}
      </VaAlert>
      
        <VaForm ref="formRef" class="items-baseline gap-6" style="display: flex; flex-direction: column; max-width:100%">
          <label class="va-input-label" style="color: var(--va-primary);">Пациент</label>
          <VaCollapse v-if="patientReady"
            icon="person"
            v-model="toggleCard"
            class="min-w-96 mb-3 rc-text-m"
            :header="patient.full_name + (patient.birthdate ? ', '+patient.birthdate : '')"
          >
            <div class="va-table-responsive">
              <PatientProfile :patient="patient" />
            </div>

          </VaCollapse>
          <VaAlert color="#ffffff" v-if="diary.length>0 && showFillButton">
            <div class="rcflex row vcenter" ref="asd">
              <span class="rcflex">Можно заполнить форму данными из предыдущего дневника</span>
              <VaButton class="ml-4 rcflex" preset="primary" @click="fillDiary" @click.after="showFillButton = !showFillButton">
                Заполнить
              </VaButton>
            </div>
          </VaAlert>
        <VaSwitch
          v-model="joint"
          label="Совместный осмотр с Профессором"
          size="small"
          class="mb-3"
        />
        <VaTextarea class="mb-3"
          v-model="form.compliant"
          label="Жалобы"
          placeholder=""
          autosize
          :minRows=3
          :max-rows=6
        />

        <VaTextarea class="mb-3"
          v-model="form.status_objective"
          label="Объективный статус"
          placeholder=""
          autosize
          :minRows=10
          :max-rows=100
        />

        <VaTextarea class="mb-3"
          v-model="form.status_local"
          label="Локальный статус"
          placeholder=""
          autosize
          :minRows=6
          :max-rows=10
        />
        
        <VaTextarea v-if="joint"
          class="mb-3"
          v-model="form.recom"
          label="Рекомендации"
          placeholder=""
          autosize
          :minRows=6
          :max-rows=10
        />
        <div class="rcflex mt-4">
          <VaButton
            preset="primary"
            color="secondary"
            icon="close"
            class="mr-2"
            @click="$router.back()"
          >
            Отмена
          </VaButton>
          <VaButton v-if="recordId"
            style="max-width:230px"
            class="" 
            @click="patchDiary"
            preset="primary"
            icon-right="save"
          >
            Сохранить изменения
          </VaButton>
          <VaButton v-else
            style="max-width:200px"
            class="" 
            @click="addDiary"
            preset="primary"
            icon-right="system_update_alt"
          >
            Сохранить 
          </VaButton>
        </div>          
      </VaForm>
    </VaCardContent>
  </VaCard>

  <VaCard stripe stripe-color="danger" v-else>
    <VaCardTitle>Ошибка</VaCardTitle>
    <VaCardContent>
      <VaAlert
        icon="warning"
        color="warning"
        class="rcflex"
      >
      <template #title>
        <span class="rc-text-l mb-4 rc-text-bold">Предоперационный эпикриз уже создан, его можно посмотреть в истории болезни.</span>
      </template>
          Дата записи: {{ preop.preopDate }} <br/>
          Врач: {{ preop.doctor_full_name }}
      </VaAlert>
    </VaCardContent>
    <VaCardContent>
      <VaButton
            class="mr-3"
            icon="cancel"
            preset="primary"
            color="secondary"
            @click="$router.back()"
            >
            Назад
          </VaButton>
    </VaCardContent>
  </VaCard>

  <VaModal
    :allowBodyScroll=true
    v-model="addError"
    size="small"
    hideDefaultActions
    ref="error"
  >
    
    <template #default>
      <h4 class="va-h4">
        <VaIcon size="2rem" color="danger" name="close"/>Ошибка!
      </h4>
      <p class="mb-4">
        Проверьте правильность заполнения всех полей
      </p>
    </template>
    <template #footer>
      <VaButton @click.before="$refs.error.hide()" @click="addError = !addError" preset="primary"> Исправить </VaButton>
    </template>
  </VaModal>

  <VaModal
    :allowBodyScroll=true
    v-model="addSuccess"
    size="small"
    cancelText="Назад к списку"
    :beforeCancel="back"
    :mobile-fullscreen=false
    :hideDefaultActions=true
    ref="success"
  >
    
    <template #default>
      <h4 class="va-h4">
        Готово!
      </h4>
      <p class="mb-4">
      Запись успешно создана
      </p>
      <VaButton @click.before="$refs.success.hide()" @click="$router.back()">
          Ок
      </VaButton>
    </template>
  </VaModal>

</template>
<script>
import axiosInstance from "../services/api";
import EventBus from "../common/EventBus";
import { RouterLink } from 'vue-router'
import PatientProfile from '@/components/PatientProfile';

export default {
    components: { RouterLink, PatientProfile },
    props: ['recordId','medhistoryId'],
    data() {
        return {
            showFillButton: true,
            joint: false,
            form: {
              compliant: '',
              status_objective: '',
              status_local: '',
              recom: '',
              medhistory: this.medhistoryId,
              user: this.$store.state.app.account.profile.id,
            },
            medhistory: {},
            patient: {},
            patientReady: false,
          };
    },
    computed: {
      diary() {
        return this.$store.state.app.diary ? this.$store.state.app.diary : null
      }
    },
    methods: {
      fillDiary() {
        this.joint = this.diary[0].recom === "" ? false : true
        this.form.compliant = this.diary[0].compliant
        this.form.status_objective = this.diary[0].status_objective
        this.form.status_local = this.diary[0].status_local
        this.form.recom = this.diary[0].recom
      },
      addDiary() {
        axiosInstance
            .post(`/diary/`, this.form)
            .then(response => {
                this.diary = response.data
                this.$vaToast.init({ message: 'Дневник добавлен', color: 'success', position: 'bottom-right' })
                this.$router.replace('/medhistory/' + this.medhistoryId)
            })
            .catch(error => {
              console.log('Diary: error ' + error);
        })
            .finally(() => {
              console.log('Diary: Request completed')
        });
      },
      patchDiary() {
        delete this.form.id
        delete this.form.updated
        delete this.form.diaryDate
        delete this.form.doctor_full_name
        
        axiosInstance
            .patch(`/medhistory/diary/edit/`+this.recordId, this.form)
            .then(response => {
                this.$vaToast.init({ message: 'Запись в дневнике изменена', color: 'success', position: 'bottom-right' })
                this.$router.replace('/medhistory/' + response.data.medhistory)
            })
            .catch(error => {
              console.log('Diary: error ' + error);
        })
            .finally(() => {
              console.log('Diary: Request completed')
        });
      },
      getDiary() {
            axiosInstance
                .get(`/medhistory/diary/edit/`+this.recordId)
                .then(response => {
                  this.form = {...response.data}
                  if (this.form.recom) {this.joint = true}
                  this.getMedhistory(this.form.medhistory)
            })
                .catch(error => {
                  console.log('Get diary error')
            })
          },
      getMedhistory(medhistory) {
        axiosInstance
            .get(`/medhistory/` + medhistory)
            .then(response => {
                this.medhistory = response.data.results[0]
            })
            .catch(error => {
              console.log('Medhistory get error: ' + error);
        })
            .finally(() => {
              console.log('Medhistory get completed')
              this.getPatient(this.medhistory.patient)
        });
      },
      getPatient(id) {
            axiosInstance
                .get(`patients/`+id+`/`)
                .then(response => {
                  this.patient = response.data;
                  this.patientReady = true
            })
                .catch(error => {
                this.getPatientError = true;
            })
          },
    },
    mounted() {
      if (this.recordId) {
        this.getDiary()
      }else{
        this.getMedhistory(this.medhistoryId)
      }
    },
}

</script>
<style>
</style>