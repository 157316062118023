export default {
  isJSON (str) {
    if (typeof str !== "string") {
      return false;
    }
    if (str.substring(0, 1) !== "{") {
      return false;
    }
    try {
      JSON.parse(str);
    } 
    catch (e) {
      return false;
    }
    return true;
  }
}