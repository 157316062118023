<template>
  <VaCard stripe stripe-color="primary">
    <VaCardTitle>
      Мое расписание
    </VaCardTitle>
    <VaCardContent>
    <div class="rcflex rcflex-btw row">
      <span class="rcflex">
        <VaButton
          icon="arrow_back_ios"
          color="primary"
          icon-color="#333"
          preset="secondary"
          hover-behavior="opacity"
          :hover-opacity="0.4"
          @click="day('prev')"
        />
      </span>
      <span class="rcflex center">
        <span :key="dayIndex">Расписание приема на {{ formatDate(dateString, 'ru') }}</span>
      </span>
      <span class="rcflex">
        <VaButton
          icon="arrow_forward_ios"
          color="primary"
          icon-color="#333"
          preset="secondary"
          hover-behavior="opacity"
          :hover-opacity="0.4"
          @click="day('next')"
        />
      </span>
    </div>
       
        <!-- <VaDataTable
        :columns="columns" 
        :items="regs"
        :key="dayIndex"
        :loading="isTableLoading"
        striped 
        clickable
        @row:click="showPatient"
        /> -->
    <VaDataTable
    :loading="isTableLoading"
    :items="schedule"
    :key="dayIndex"
    :columns="columns"
    sticky-header
    hoverable
    :virtualScroller="true"
    class="rc-shedule"
    
    >
    <template #cell(patient)="{ row, rowData }">
        <div v-if="rowData.patient" @click="row.toggleRowDetails()">
          <VaIcon
            :name="rowData.paid ? 'attach_money' : 'cruelty_free'"
            :color="rowData.paid ? 'success' : 'primary'"
            class="w-full mr-2 mb-2"
            text="asd"
          />
          {{ rowData.patient }}<span v-if="rowData.patient_birthdate">, {{ rowData.patient_birthdate.split('-')[0] }} г.р.</span>
          <VaIcon v-if="rowData.visited<0"
            name="schedule" 
            color="secondary"
            class="ml-2 mb-2"
          />
          <VaIcon v-else
            :name="rowData.visited==1 ? 'done' : 'delete_history'" 
            :color="rowData.visited==1 ? 'success' : 'danger'"
            class="ml-2 mb-2"
          />
        </div>
    </template>  


    <template #expandableRow="{ rowData }">
      <div class="flex gap-2 area-more-info">
        <div class="pl-2">
          <div class="flex items-center mb-2">
            <span>Дата создания записи: {{ rowData.updated }}</span>
          </div>
          <div class="flex items-center mb-2">
            <span>Прием: {{ rowData.examType==0 ? "Первичный" : "Повторный" }}</span>
          </div>
          <div class="flex items-center mb-2">
            <span>Отделение: {{ rowData.depType==0 ? "Амбулатория" : "Стационар" }}</span>
          </div>
          <div class="flex items-center mb-4">
            <span>Причина обращения: {{ rowData.examReason }}</span>
          </div>
          <VaDivider />
          <div class="flex items-center" v-if="rowData.visited<0">
            <VaButton color="danger" preset="secondary" icon="clear" class="mr-2" @click="setDNC(rowData.id)">Пометить неявку</VaButton>
            <VaButton v-if="iamAnesthesist" color="info" preset="secondary" icon-right="arrow_forward" 
            @click="$router.push({name:'addAnExam', params:{patientId: rowData.patient_id, medhistoryId: 0, visitId: 0, regId: rowData.id}})">
              Начать прием анестезиолога
            </VaButton>
            <VaButton v-if="iamDoctor" color="info" preset="secondary" icon-right="arrow_forward" 
            @click="$router.push({name: 'visitNew', params: {patientId: rowData.patient_id, examType: rowData.examType, depType: rowData.depType, regId: rowData.id}})">
              Начать прием
            </VaButton>
          </div>
          <div class="flex items-center" v-if="rowData.visited==0">
            <VaAlert color="warning">Пациент на прием не явился</VaAlert>
          </div>
          <div class="flex items-center" v-if="rowData.visited==1">
            <VaAlert color="success">Прием состоялся</VaAlert>
          </div>
        </div>
      </div>
    </template>
  </VaDataTable>
  
      <p class="mt-2" v-show="!isTableLoading">Всего записей в базе: {{ regsCount }}</p>
      <VaSkeleton v-show="isTableLoading" tag="p" variant="text" class="mt-2" lastLineWidth="25%" :lines="1"/>
      
    </VaCardContent>
  </VaCard>

</template>
<script>
import axiosInstance from "../services/api";
import UserService from "../services/user.service";
import EventBus from "../common/EventBus";
import debounce from 'lodash.debounce'

export default {
  props: {
      page: Number,
    },
  data() {
        return {
            columns: [
              { key: "time", label: "Время", width: 1 },
              { key: "patient", label: "Пациент"},
              //{ key: "actions", label: "Действия", width: "20%" },
            ],
            //showSidebar:this.$store.state.app.sidebarStatus,  
            regs: [],
            schedule: [],
            prev: String,
            next: String,
            isTableLoading: true,
            query: '',
            tooShortQuery: false,
            dateString: new Date,
            dayIndex: 0,
            regsCount: 0,
        }
  },
  computed: {
    userProfile() {
      return this.$store.state.app.account.profile
    },
    iamAnesthesist() {
      return this.$store.state.app.account.profile.iamAnesthesist
    },
    iamDoctor() {
      return this.$store.state.app.account.profile.iamDoctor
    }
  },
  watch: {
    dateString: function(newValue) {
      if (newValue) {
        //console.log('dateString watcher triggered')
        this.getRegs(this.userProfile.id)
        }
      //this.regObject = this.regs
    },
    showSidebar: function() {
      this.$store.dispatch('app/toggleSidebar',true)
    },
    regs: function(newValue){
      this.addCol(this.regs)
      this.makeSchedule(this.regs)
    },
  },
  methods: {
    setDNC(reg_id) {
      axiosInstance
        .patch('/registrations/edit/'+ reg_id, {visited: 0})
        .then((response) => {
          //console.log('DNC status setted')
          this.getRegs(this.userProfile.id)
        },
        (error) => {
          //console.log(JSON.stringify(error))
          //console.log('DNC setting failed')
        })
    },
    addCol(array) {
    var i = 0
      while (i < array.length) {
        //console.log(array[i].regDate)
        array[i].time = this.ruDateTime(array[i].regDate).time
        i++
      }
    },
    getAllIndexes(arr, val) {
      var indexes = [], i;
      for(i = 0; i < arr.length; i++)
        if (arr[i].time === val)
          indexes.push(i);
      return indexes;
    },
    makeSchedule(array) {
      this.schedule = []
      for (let i = 9; i < 20; i++) {
            // if (i!=13) {
                var int = String(i).padStart(2, '0')+':00'
                  var interval = {}
                  interval.time = int
                  var indexes = this.getAllIndexes(array, int)
                  var index = array.findIndex(rec => {
                    return rec.time === int
                  })
                  if (indexes.length <= 0) {
                    this.schedule.push(interval)
                  }else {
                    for (var k=0; k<indexes.length; k++)
                    {
                      if (k>0) array[indexes[k]].time = ''
                      this.schedule.push(array[indexes[k]])  
                    }
                  }

                int= String(i).padStart(2, '0')+':30'
                  var interval = {}
                  interval.time = int
                  var index = array.findIndex(rec => {
                    return rec.time === int
                  })
                  if (index < 0) {
                    this.schedule.push(interval)
                  }else{
                    this.schedule.push(array[index])
                  }
          // }
        }
    },
    day(direction) {
      this.dayIndex = Date.now()
      switch (direction) {
        case 'next':
          this.dateString.setDate(this.dateString.getDate() + 1)
          return this.getRegs(this.userProfile.id)
        case 'prev':
          this.dateString.setDate(this.dateString.getDate() - 1)
          return this.getRegs(this.userProfile.id)
      }
    },
    ruDateTime(dt) {
        var day, month, year, hours, minutes, seconds;
        var d = dt.split(' ')[0]
        var t = dt.split(' ')[1]
        var result = d.match("[0-9]{2}([\-/ \.])[0-9]{2}[\-/ \.][0-9]{4}");
        if(null != result) {
            var dateSplitted = result[0].split(result[1]);
            day = dateSplitted[0];
            month = dateSplitted[1];
            year = dateSplitted[2];
        }
        var result = d.match("[0-9]{4}([\-/ \.])[0-9]{2}[\-/ \.][0-9]{2}");
        if(null != result) {
            var dateSplitted = result[0].split(result[1]);
            day = dateSplitted[2];
            month = dateSplitted[1];
            year = dateSplitted[0];
        }
        var result = t.match("[0-9]{2}([\-/ \:])[0-9]{2}[\-/ \:][0-9]{2}");
        if(null != result) {
            var timeSplitted = result[0].split(result[1]);
            //console.log("res is " + result)
            hours = timeSplitted[0];
            minutes = timeSplitted[1];
            seconds = timeSplitted[2];
        }

        if(month>12) {
            aux = day;
            day = month;
            month = aux;
        }
        var obj = {}
        obj.date = day+"/"+month+"/"+year
        obj.time = hours+":"+minutes
        return obj
    },
    // formatDate: принимает первым параметром объект Date, вторым параметром формат вывода en|ru
    // возвращает String в виде Даты
    formatDate(dateObject, format) {
        var d = dateObject
        switch (format) {
          case 'us':
            return String(String(d.getFullYear() + '-'+String(d.getMonth()+1) + '-'+d.getDate()))
          case 'ru':
            return String(d.getDate()).padStart(2, '0') + '.'+String(d.getMonth()+1).padStart(2, '0') + '.'+String(d.getFullYear())
        }
    },
    showPatient(event) {
      this.$router.push('patients/' + event.item.id)
    },
    getRegs(userid) {
      this.isTableLoading=true
      //console.log('updating Regs')
      axiosInstance
        .get('/registrations/doctor/'+ userid +'?date=' + this.formatDate(this.dateString, 'us'))
        .then((response) => {
            this.regsCount = response.data.count
            this.regs = response.data.results
        },
        (error) => {
          //console.log(JSON.stringify(error))
          //console.log('Regs request failed')
        })
        .finally(() => {
          this.isTableLoading = false
        })
    },
    debouncedSearch: debounce(function (query) {
              this.searchPatient(query);
            }, 600),
  },
  created() {
      !this.$store.state.auth.status.loggedIn && this.$router.push('/login')
    },
  mounted() {
    //this.dateString = Date.now()
    this.getRegs(this.userProfile.id)
  },
}

</script>

<style>
</style>
