import { register } from 'register-service-worker'
const APP_VERSION = process.env.VUE_APP_VERSION

if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV !== 'production') {
  const channel = new BroadcastChannel('sw-messages');
     register(`${process.env.BASE_URL}service-worker.js`, {
      ready (reg) {
        if (reg.sync) {
          async function requestBackgroundSync() {
            await self.reg.sync.register('notifications');
          }
        } else {
          console.log('Sync NOT supported')
        }
      // console.log(
      //   'App is being served from cache by a service worker.\n' +
      //   'For more details, visit https://goo.gl/AFskqB'
      // )
      // window.postMessage({type: "appMessage", code: 'READY', message: "App is being served from cache by a service worker.", version: APP_VERSION })
      channel.postMessage({type: "appMessage", code: 'READY', message: "App is being served from cache by a service worker.", version: APP_VERSION })
    },
    registered () {
      // console.log('Service worker has been registered.')
      // window.postMessage({type: "appMessage", code: 'REGISTERED', message: "Service worker has been registered", version: APP_VERSION })
      channel.postMessage({type: "appMessage", code: 'REGISTERED', message: "Service worker has been registered", version: APP_VERSION })
    },
    cached () {
      // console.log('Content has been cached for offline use.')
      // window.postMessage({type: "appMessage", code: 'CACHED', message: "Content has been cached for offline use.", version: APP_VERSION })
      channel.postMessage({type: "appMessage", code: 'CACHED', message: "Content has been cached for offline use.", version: APP_VERSION })
    },
    updatefound () {
      // console.log('New content is downloading.')
      // window.postMessage({type: "appMessage", code: 'UPDATEFOUND', message: "New content is downloading.", version: APP_VERSION })
      channel.postMessage({type: "appMessage", code: 'UPDATEFOUND', message: "New content is downloading.", version: APP_VERSION })
    },
    updated (reg) {
      // console.log('New content is available; please refresh.')
      // window.postMessage({type: "appMessage", code: 'UPDATED', message: "New content is available; please refresh.", version: APP_VERSION, registration: reg})
      channel.postMessage({ type: "appMessage", code: 'UPDATED', message: "New content is available; please refresh.", version: APP_VERSION})
      document.dispatchEvent(
        new CustomEvent('swUpdated', { detail: reg, version: APP_VERSION })
      )
    },
    offline () {
      // console.log('No internet connection found. App is running in offline mode.')
      // window.postMessage({type: "appMessage", code: 'OFFLINE', message: "No internet connection found. App is running in offline mode.", version: APP_VERSION })
      channel.postMessage({type: "appMessage", code: 'OFFLINE', message: "No internet connection found. App is running in offline mode.", version: APP_VERSION })
    },
    error (error) {
      // console.error('Error during service worker registration:', error)
      // window.postMessage({type: "appMessage", code: 'ERROR', message: "Error during service worker registration", version: APP_VERSION })
      channel.postMessage({type: "appMessage", code: 'ERROR', message: "Error during service worker registration", version: APP_VERSION })
    }
  })
}
