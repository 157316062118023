export const buttons = {
    save: "Сохранить",
    edit: "Изменить",
}
export const menu = {
    login: "Войти",
    logout: "Выйти",
    history: "Истории болезни",
    schedule: "Записи на прием",
    patients: "Пациенты",
    profile: "Профиль",
    myschedule: "Мое расписание",
    mypatients: "Мои пациенты",
    myhistory: "Мои истории болезни",
    mytemplates: "Мои шаблоны",
}
export const keys = {
    //Patient object
    lastname: "Войти",
    firstname: "Выйти",
    patronymic: "Истории болезни",
    sex: "Записи на прием",
    nation: "Пациенты",
    education: "Образование",
    birthdate: "Дата рождения",
    passport: "Паспортные данные",
    address: "Адрес места жительства",
    phone: "Телефон",
    snils: "СНИЛС",
    polis: "ПОЛИС ОМС",
    disability: "Группа инвалидности",
    works: "Место работы",
    height: "Рост",
    weight: "Вес",
    artpressure: "Артериальное давление",
    pulse: "Пульс",
    bloodtype: "Группа крови",
    rhfactor: "Резус-фактор",
    diagnosis_first: "Диагноз при поступлении",
}
export const bloodTypes= [
    "",
    "0(I)",
    "A(II)",
    "B(III)",
    "AB(IV)",
]
export const testTypes=[
    '',
    'Общий анализ крови',
    'Биохимический анализ крови',
    'Общий анализ мочи',
    'Коагулограмма',
    'Рентген'
]
export const testTemplates=[
    '',
    'Эр= ; Гмг= ; Гкт= ; Лейк= ; Тромб= ; Эоз= ; П/я= ; С/я= ; Лимф= ; Моноц= ; СОЭ= ;',
    'О.белок= ; Альб= ; Мочевин= ; Креат= ; Глюк= ; О.билир= ; прямой= ; непрямой= ; АЛТ= ; АСТ= ; ',
    'Кол-во= ; Цвет= ; Уд.вес= ; рН= ; Белок= ; Эпит.= ; Лейкоц= ; Эритроц= ;',
    'ПВ= ; ПТИ= ; ПО= ; МНО= ; АЧТВ= ; свер-ть= ; длит-ть= ;',
    ''
]
export function rhfactor(value) {
    switch(value) {
      case "+":
        return "Rh(+)"
      case "-":
        return "Rh(-)"
    }
  }