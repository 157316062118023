<template>
<div class="print">
    <p class="bold center">
        ООО «Клиника Профессора»<br/>
        ИНН 1435316805, КПП 143501001<br/>
        Адрес: 677010, г. Якутск, ул. Радужная, 69/12<br/>
        Телефон: +7 (914)2776990; электронный адрес: klinika.professora@mail.ru
    </p>
    
    <hr size=2 width="100%" align=center>
    <p class="bold center">
        ХОД ОПЕРАЦИИ
    </p>
    <p style="text-align:left;font-weight:bold;">
        {{ operation.operationStart.split(' ')[0] }} г.<br/>
    </p>
    <p style="text-align:justify">
        <b>ФИО пациента:</b> {{ patient.full_name }}<br/>
        <b>Возраст:</b> {{ patientAge }} ({{ patient.birthdate.split('-').reverse().join('.')}} г.р.)<br/>
        <b>Рост:</b> {{ patient.height }} см, вес: {{ patient.weight }} кг
    </p>
    <p style="text-align:justify">
        <b>Начало операции:</b> {{ operation.operationStart.split(' ')[0] }} г. в {{ operation.operationStart.split(' ')[1] }}<br/>
        <b>Конец операции:</b> {{ operation.operationEnd.split(' ')[0] }} г. в {{ operation.operationEnd.split(' ')[1] }}<br/>
        <b>Предоперационный диагноз:</b> {{ operation.diagnosis }}<br/>
        <b>Номер операции:</b> {{ operation.operationNo }}<br/>
        <b>Название операции:</b> {{ operation.operationName }}<br/>
        <b>Код операции:</b> {{ operation.operationCode }}<br/>
        <b>Код КСГ:</b> {{ operation.operationCodeKSG }}<br/>
        <b>Анестезиологическое пособие:</b> {{ operation.anesthesia }}<br/>
        <b>Ход операции:</b> {{ operation.operationLog }}<br/>
        <b>Осложнения:</b> {{ operation.complications }}<br/>
        <b>Расходный материал:</b> {{ operation.inserted }}<br/>
        <b>Удаленный макропрепарат:</b> {{ operation.removed }}<br/>
        <b>Диагноз постоперационный:</b> {{ operation.diagnosis_postop }}<br/>
        <b>Код диагноза:</b> {{ operation.mkb10 }}<br/>
        <b>Рекомендации :</b> {{ operation.recom }}<br/>
    </p>
    <p style="text-align:justify">
        <b>Оператор :</b> {{ operation.operator_full_name }}<br/>
        <span v-if="operation.assistOne"><b>Ассистент оператора №1:</b> {{ operation.assistOne_full_name }}<br/></span>
        <span v-if="operation.assistTwo"><b>Ассистент оператора №2:</b> {{ operation.assistTwo_full_name }}<br/></span>
        <span v-if="operation.opSister"><b>Операционная медсестра:</b> {{ operation.opSister_full_name }}<br/></span>
        <span v-if="operation.anesthetist"><b>Анестезиолог-реаниматолог:</b> {{ operation.anesthetist_full_name }}<br/></span>
        <span v-if="operation.anSister"><b>Анестезиологическая медсестра:</b> {{ operation.anSister_full_name }}<br/></span>
        <span v-if="operation.xraytech"><b>Рентген-лаборант:</b> {{ operation.xraytech_full_name }}<br/></span>
    </p>
    <p style="text-align:justify">
        Врач: {{ operation.operator_full_name}}<br/>
    </p>
    <div class="pagebreak" />
</div>
    </template>
    <script setup>
        import { formatDate } from '@/common/rcfx.js'
    </script>
    <script>
    export default {
        props: ['visit'],
        computed: {
            patient() {
                return this.$store.state.app.patient
            },
            operation() {
                return this.$store.state.app.operation
            },
            patientAge() {
                var birthdate = new Date(this.patient.birthdate.split('.').reverse().join('-'))
                var today = new Date()
                return Math.floor((today-birthdate)/3600000/24/365)
            }
        }
    }
    </script>