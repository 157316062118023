<template>
    <VaCardTitle class="rc-text-m">Внутренний порядковый номер № {{ visit.id }}</VaCardTitle>

    <VaCardTitle class="rc-text-m">Жалобы</VaCardTitle>
    <VaCardContent>
    {{ visit.compliant }}
    </VaCardContent>

    <VaCardTitle class="rc-text-m">Анамнез болезни</VaCardTitle>
    <VaCardContent>
    {{ visit.anamnes_med }}
    </VaCardContent>

    <VaCardTitle class="rc-text-m">Анамнез жизни</VaCardTitle>
    <VaCardContent>
    {{ visit.anamnes_life }}
    </VaCardContent>

    <VaCardTitle class="rc-text-m">Аллергические реакции</VaCardTitle>
    <VaCardContent>
    {{ visit.status_alergo }}
    </VaCardContent>

    <VaCardTitle class="rc-text-m">Объективный статус</VaCardTitle>
    <VaCardContent>
    {{ visit.status_objective }}
    </VaCardContent>

    <VaCardTitle class="rc-text-m">Локальный статус</VaCardTitle>
    <VaCardContent>
    {{ visit.status_local }}
    </VaCardContent>

    <div v-if="amb">
        <VaCardTitle class="rc-text-m">Манипуляции</VaCardTitle>
        <VaCardContent>
        {{ visit.manipulation.length>0 ? visit.manipulation : 'Нет' }}
        </VaCardContent>
    </div>

    <VaCardTitle class="rc-text-m">Клинический минимум</VaCardTitle>
    <VaCardContent>
    {{ visit.clinimum }}
    </VaCardContent>

    <VaCardTitle class="rc-text-m">Данные обследований</VaCardTitle>
    <VaCardContent>
    {{ visit.exam_data }}
    </VaCardContent>

    <div v-if="hos">
        <VaCardTitle class="rc-text-m">Обоснование диагноза</VaCardTitle>
        <VaCardContent>
        {{ visit.proof }}
        </VaCardContent>
    </div>

    <VaCardTitle class="rc-text-m">Диагноз</VaCardTitle>
    <VaCardContent>
    {{ visit.diagnosis }}
    </VaCardContent>

    <div v-if="hos">
        <VaCardTitle class="rc-text-m">Диагноз сопутствующий</VaCardTitle>
        <VaCardContent>
        {{ visit.diagnosis_secondary }}
        </VaCardContent>
    </div>

    <VaCardTitle class="rc-text-m">Код диагноза</VaCardTitle>
    <VaCardContent>
    {{ visit.mkb10 }}
    </VaCardContent>

    <div v-if="amb">
        <VaCardTitle class="rc-text-m">Рекомендации</VaCardTitle>
        <VaCardContent>
        {{ visit.recom }}
        </VaCardContent>
    </div>

    <div v-if="hos">
        <VaCardTitle class="rc-text-m">План ведения и лечения</VaCardTitle>
        <VaCardContent>
        {{ visit.cure_plan }}
        </VaCardContent>
    </div>
</template>
<script>
export default {
    props: ['visit'],
    computed: {
        amb() {
            return this.visit.depType === "0"
        },
        hos() {
            return this.visit.depType === "1"
        },
    },
}
</script>