<template>
    <VaCardTitle class="rc-text-m">Внутренний порядковый номер № {{ discharge.id }}</VaCardTitle>
    <VaCardTitle class="rc-text-m">Дней нетрудоспособности в году</VaCardTitle>
    <VaCardContent>
    {{ discharge.clinic_days }}
    </VaCardContent>
    <VaCardTitle class="rc-text-m">Отметка о выдаче листка нетрудоспособности</VaCardTitle>
    <VaCardContent>
    {{ discharge.work_incapacity_cert }}
    </VaCardContent>
    <VaCardTitle class="rc-text-m">Диагноз основной</VaCardTitle>
    <VaCardContent>
    {{ discharge.diagnosis }}
    </VaCardContent>
    <VaCardTitle class="rc-text-m">Диагноз сопутствующий</VaCardTitle>
    <VaCardContent>
    {{ discharge.diagnosis_secondary }}
    </VaCardContent>
    <VaCardTitle class="rc-text-m">Анамнез болезни</VaCardTitle>
    <VaCardContent>
    {{ discharge.anamnes_med }}
    </VaCardContent>
    <VaCardTitle class="rc-text-m">Диета</VaCardTitle>
    <VaCardContent>
    {{ discharge.diet }}
    </VaCardContent>
    <VaCardTitle class="rc-text-m">Лечение</VaCardTitle>
    <VaCardContent>
    {{ discharge.treatment_type }}
    </VaCardContent>
    <VaCardTitle class="rc-text-m">Операция</VaCardTitle>
    <VaCardContent>
    {{ discharge.operation }}
    </VaCardContent>
    <VaCardTitle class="rc-text-m">Послеоперационный период</VaCardTitle>
    <VaCardContent>
    {{ discharge.postoperation_note }}
    </VaCardContent>
    <VaCardTitle class="rc-text-m">Состояние при выписке</VaCardTitle>
    <VaCardContent>
    {{ discharge.current_status }}
    </VaCardContent>
    <VaCardTitle class="rc-text-m">Жалобы</VaCardTitle>
    <VaCardContent>
    {{ discharge.compliant }}
    </VaCardContent>
    <VaCardTitle class="rc-text-m">Объективный статус</VaCardTitle>
    <VaCardContent>
    {{ discharge.status_objective }}
    </VaCardContent>
    <VaCardTitle class="rc-text-m">Локальный статус</VaCardTitle>
    <VaCardContent>
    {{ discharge.status_local }}
    </VaCardContent>
    <VaCardTitle class="rc-text-m">На перевязке</VaCardTitle>
    <VaCardContent>
    {{ discharge.status_dressing }}
    </VaCardContent>
    <VaCardTitle class="rc-text-m">Рекомендации</VaCardTitle>
    <VaCardContent>
    {{ discharge.recom }}
    </VaCardContent>
    <VaCardTitle class="rc-text-m">Данные исследований при поступлении</VaCardTitle>
    <VaCardContent>
    {{ discharge.exam_data }}
    </VaCardContent>
    <VaCardTitle class="rc-text-m">Контрольные исследования</VaCardTitle>
    <VaCardContent>
    {{ discharge.control_data }}
    </VaCardContent>
</template>
<script>
export default {
    props: ['discharge'],
}
</script>