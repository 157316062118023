import axios from "axios";
const apiUrl = process.env.NODE_ENV === 'production' ? "https://tech.reactiv.su/api/v1/" : "http://192.168.70.131:8000/api/v1/"

const instance = axios.create({
  baseURL: apiUrl,
  // baseURL: "http://192.168.70.131:8000/api/v1/",
  // baseURL: "https://tech.reactiv.su/api/v1/",
  headers: {
    "Content-Type": "application/json",
  },
});

export default instance;
