<template>
<VaCard stripe stripe-color="success">
    <VaCardTitle>
      <VaButton
      icon="arrow_back_ios_new"
      color="primary"
      icon-color="#333"
      class="mr-3"
      preset="secondary"
      hover-behavior="opacity"
      :hover-opacity="0.4"
      @click="$router.back()"
    />
    {{ templateId ? "Редактирование шаблона" : "Новый шаблон" }}
    </VaCardTitle>

    <VaCardContent style="text-align:left">

        <VaForm ref="formRef" class="items-baseline gap-6" style="display: flex; flex-direction: column; max-width:100%">

          <VaSwitch
            v-model="form.shared"
            label="Сделать шаблон общедоступным"
            size="small"
            class="mb-3"
          />

          <label class="va-input-label" style="color: var(--va-primary);">Этот шаблон для отделения</label>
          <VaRadio class="mb-3"
            v-model="type"
            :options="[
              {
                text: 'Амбулатория',
                value: 0,
              },
              {
                text: 'Стационар',
                value: 1,
              },
            ]"
            value-by="value"
          />
          
          <VaInput class="mb-3"
            v-model="form.name"
            :rules="[(value) => (value && value.length > 0) || 'Нужно ввести название шаблона']"
            label="Название шаблона"
            placeholder="Например - Гонартроз"
          />

          <VaTextarea class="mb-3"
            v-model="form.status_local"
            :rules="[(value) => (value && value.length > 0) || 'Нужно описать локальный статус']"
            label="Локальный статус"
            placeholder="Опишите стандартный локальный статус, который будет содержать большинство необходимых данных для этого шаблона"
            autosize
            :minRows=10
            :max-rows=100
          />

          <VaTextarea v-show="type==0"
            class="mb-4"
            v-model="form.recom"
            label="Рекомендации"
            placeholder="Опишите стандартные рекомендации, которые содержат большинство необходимой информации для этого шаблона"
            autosize
            :minRows=10
            :max-rows=100
          />
          
          <VaTextarea v-show="type==1"
            class="mb-4"
            v-model="form.cure_plan"
            label="План ведения и лечения"
            placeholder="Опишите стандартный план ведения и лечения, которые будут содержать большинство необходимой информации для этого шаблона"
            autosize
            :minRows=10
            :max-rows=100
          />
        
          <VaButton preset="primary" v-if="templateId" style="max-width:190px" @click="patchTemplate">
            Сохранить изменения
          </VaButton>
          <VaButton preset="primary" v-else style="max-width:190px" @click="addTemplate">
            Добавить
          </VaButton>
        
        </VaForm>



        </VaCardContent>
  </VaCard>

  <VaModal
    :allowBodyScroll=true
    v-model="addError"
    size="small"
    blur
    hideDefaultActions
    ref="error"
  >
    
    <template #default>
      <h4 class="va-h4">
        <VaIcon size="2rem" color="danger" name="close"/>Ошибка!
      </h4>
      <p class="mb-4">
        Проверьте правильность заполнения всех полей
      </p>
    </template>
    <template #footer>
      <VaButton @click.before="$refs.error.hide()" @click="addError = !addError" preset="primary"> Исправить </VaButton>
    </template>
  </VaModal>

</template>
<script>
import axiosInstance from "../services/api";
import EventBus from "../common/EventBus";
import { RouterLink } from 'vue-router'

export default {
    components: { RouterLink },
    props: { 
      templateId: String,
    },
    data() {
        return {
          type: '',
          addError: false,
          addSuccess: false,
            form: {
              type: '',
              name: '',
              status_local: '',
              recom: '',
              owner: this.$store.state.auth.status.loggedIn ? this.$store.state.app.account.profile.id : '',
              shared: false,
            },
            templates: [],
          };
    },
    watch: {
      type: function(newValue) {
          console.log('type changed')
          this.form.type = this.type
    },
    },
    methods: {
      getTemplates() {
        axiosInstance
            .get(`templates/` + this.templateId)
            .then(response => {
                this.form = response.data;
                this.type = this.form.type
            })
            .catch(error => {
              console.log('Templates: error ' + error);
        })
            .finally(() => {
              console.log('Templates: Request completed')
        });
      },
      addTemplate() {
        axiosInstance
            .post(`templates/`, this.form)
            .then(response => {
              this.form = response.data;
              this.$vaToast.init({ message: 'Шаблон добавлен', color: 'success', position: 'bottom-right', dangerouslyUseHtmlString: true, })
              this.$router.push({name: 'templates'})
        })
            .catch(error => {
              console.log('Templates add : error ' + error);
              this.addError = true
        })
            .finally(() => {
              console.log('Templates add: Request completed')
        });
      },
      patchTemplate() {
            
            if (this.form.type == 0) {
                this.form.cure_plan = " "
              }else{
                this.form.recom = " "
              }

            axiosInstance
            .patch(`templates/` + this.templateId, this.form)
            .then(response => {
              this.addedSuccess = true
              this.form = response.data;
              this.$vaToast.init({ message: 'Шаблон обновлен', color: 'success', position: 'bottom-right', dangerouslyUseHtmlString: true, })
            })
            .catch(error => {
              console.log('Templates add : error ' + error);
              this.addedError = true
        })
            .finally(() => {
              console.log('Templates add: Request completed')
        });
      },
    },
    created() {
      !this.$store.state.auth.status.loggedIn && this.$router.push('/login')
    },
    mounted() {
      if (this.templateId)  this.getTemplates()
    },
}

</script>
<style>
</style>
  