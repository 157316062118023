<template>
  <VaCard stripe stripe-color="primary">
    <VaCardContent>
      <span>Версия: {{ appVersion }}</span>
      <VaImage
      class="w-full md:w-1/2 lg:w-1/3"
      style="margin:0 auto"
      :maxWidth=256
      src="./img/icons/icon-512x512.png"
      />
      <h6 class="va-h6 va-text-left">
        О приложении
      </h6>
      <p class="va-text-justify rc-text-l" style="line-height: normal !important;">
        Веб-приложение <b>"Реактив-клиник"</b> разработано для решения рутинных задач клиник с имеющимся стационаром. При проектировании приложения за основу был взят принцип <i>потока</i>, который заключается в передаче повторяющихся данных. Это можно заметить при работе с пациентами, начиная от первого касания с регистратором и заканчивая выпиской пациента. Также реализован механизм доставки Push-уведомлений, к которым мы все так привыкли, потому что это удобно, а в случае с рабочими процессами, просто необходимо.
      </p>
      <p class="va-text-justify rc-text-l" style="line-height: normal !important;">
        В основе приложения лежит микросервиская архитектура: под капотом трудится один из самых быстрых высокоуровневых языков - Python, а за отрисовку UI (пользовательского интерфейса) отвечает VueJS. 
      </p>
      <p class="va-text-justify rc-text-l" style="line-height: normal !important;">
        Если вы впервые открыли приложение, начать следует с авторизации, заполнения профиля и включения уведомлений.
      </p>
      <VaButton v-if="!loggedIn" preset="primary" color="success" iconRight="login" class="mt-4" @click="login">Войти</VaButton>
      <VaButton v-else preset="primary" color="success" iconRight="start" class="mt-4" @click="profile">Перейти в профиль</VaButton>
      </VaCardContent>
    </VaCard>
</template>

<script setup>
import { VaCardContent } from "vuestic-ui/web-components"
</script>
<script>
export default {  
  computed: {
    appVersion() {
      return this.$store.state.app.version
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn
    },
  },
  methods: {
    login() {
      this.$router.push('/login')
    },
    profile() {
      this.$router.push('/profile')
    }
  }
}
</script>
