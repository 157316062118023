<template>
<div class="print">
    <p class="bold center">
        ООО «Клиника Профессора»<br/>
        ИНН 1435316805, КПП 143501001<br/>
        Адрес: 677010, г. Якутск, ул. Радужная, 69/12<br/>
        Телефон: +7 (914)2776990; электронный адрес: klinika.professora@mail.ru
    </p>
    
    <hr size=2 width="100%" align=center>
    <p class="bold center">
    ЭПИКРИЗ ВЫПИСНОЙ
    </p>
    <p style="text-align:justify">
    <b>ФИО пациента:</b> {{ patient.full_name}}<br/>
    <b>Возраст:</b> {{ patientAge }} ({{ patient.birthdate }} г.р.)
    </p>
    <hr size=2 width="100%" align=center>
    <p style="text-align:justify">
    <b>Пациент находился на лечении в ООО «Клиника профессора»:</b> с {{ medhistory.openDate.split(' ')[0] }} по {{ medhistory.discharge_date.split(' ')[0] }}<br/>
    <b>Дней нетрудоспособности в году:</b> {{ discharge.clinic_days }}<br/>
    <b>Отметка о выдаче листка нетрудоспособности: </b> {{ discharge.work_incapacity_cert }}<br/>
    </p>
    <p style="text-align:center;font-weight:bold;">
    Клинический диагноз
    </p>
    <p style="text-align:justify">
        <b>Основной:</b> {{ discharge.diagnosis }}<br/>
        <b>Сопутствующий:</b> {{ discharge.diagnosis_secondary }}<br/>
    </p>
    <p style="text-align:justify">
        <b>Характеристика и особенности течения болезни:</b> {{ discharge.anamnes_med }}
    </p>
    <p style="text-align:justify">
        <b>Диета:</b> {{ discharge.diet }}<br/>
        <b>Лечение:</b> {{ discharge.treatment_type }}<br/>
        <b>Операция:</b> {{ discharge.operation }}<br/>
        <b>Послеоперационный период:</b> {{ discharge.postoperation_note }}
    </p>
    <p style="text-align:justify">
        <b>Состояние при выписке:</b> {{ discharge.current_status }}<br/>
        <b>Жалобы:</b> {{ discharge.compliant }}<br/>
        <b>Объективный статус:</b> {{ discharge.status_objective }}<br/>
        <b>Локальный статус: </b> {{ discharge.status_local }}<br/>
        <b>На перевязке:</b> {{ discharge.status_dressing }}
    </p>
    <p style="text-align:justify">
        <b>Рекомендации:</b><br/> {{ discharge.recom }}
    </p>
    <p style="text-align:justify">
        <b>Данные исследований при поступлении:</b> {{ discharge.exam_data }}
    </p>
    <p style="text-align:justify">
        <b>Контрольные исследования:</b> {{ discharge.control_data }}
    </p>
    <hr size=2 width="100%" align=center>
    Дата: {{ medhistory.discharge_date.split(' ')[0] }} г.<br/>
    Врач: ___________________ {{ medhistory.doctor_full_name}}<br/>
    Профессор: ___________________ Пальшин Геннадий Анатольевич
    <div class="pagebreak" />
</div>
</template>
<script>
export default {
    props: ['date','patient','medhistory','discharge'],
    computed: {
        patientAge() {
            var birthdate = new Date(this.patient.birthdate.split('.').reverse().join('-'))
            var today = new Date(this.date.split('.').reverse().join('-'))
            return Math.floor((today-birthdate)/3600000/24/365)
        }
    }
}
</script>