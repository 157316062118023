<template>
  <table class="va-table">
    <tbody>
      <tr>
        <td class="va-list-label rc-text-m">номер медицинской карты</td>
        <td class="rc-text-m">{{ title.medcard ? title.medcard : "не указан" }}</td>
      </tr>
      <tr>
        <td class="va-list-label rc-text-m">Дата и время</td>
        <td class="rc-text-m">{{ title.created }}</td>
      </tr>
      <tr>
        <td class="va-list-label rc-text-m">ФИО родственника</td>
        <td class="rc-text-m">{{ title.relative_full_name }}</td>
      </tr>
      <tr>
        <td class="va-list-label rc-text-m">Телефон родственника</td>
        <td class="rc-text-m">{{ title.relative_phone }}</td>
      </tr>
      <tr>
        <td class="va-list-label rc-text-m">Кем направлен больной</td>
        <td class="rc-text-m">{{ title.referal }}</td>
      </tr>
      <tr>
        <td class="va-list-label rc-text-m">Исход заболевания</td>
        <td class="rc-text-m">{{ title.cure_result }}</td>
      </tr>
      <tr>
        <td class="va-list-label rc-text-m">Другие виды лечения</td>
        <td class="rc-text-m">{{ JSON.parse(title.cure_types).join(', ') }}</td>
      </tr>
      <tr>
        <td class="va-list-label rc-text-m">Комментарий</td>
        <td class="rc-text-m">{{ title.comment }}</td>
      </tr>
    </tbody>
  </table>
</template>
<script>
export default {
  computed: {
      title() {
        return this.$store.state.app.title ? this.$store.state.app.title : null
      }
    },
}
</script>