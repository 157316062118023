<template>
  <VaCard stripe stripe-color="success">
    <VaCardTitle>
      Осмотры анестезиолога
    </VaCardTitle>
    
    <VaCardContent>
      <div class="rcflex row vcenter">
      <VaButtonToggle
        v-model="myown"
        preset="secondary"
        :options="ownfilter"
        class="mb-2"
      />
      <VaChip size="small" v-if="query.length>2 && !loading" closeable v-model="asd">Фильтр: {{ query }}</VaChip>
      </div>
      <div class="rcflex row">
      <VaInput clearableIcon="true" background="#ffffff" v-model.trim="query" @keyup.esc="resetSearch" class="mb-2 md12" style="width:100%;" aria-autocomplete="off" placeholder="Поиск по пациенту или анестезиологу-реаниматологу">
        <template #prependInner>
          <VaIcon name="person_search" color="secondary"/>
        </template>
      </VaInput>
      </div>
      <VaAlert v-if="tooShortQuery"
            color="info"
            border="left"
            class="mb-4">
            Для поиска небходимо минимум 3 символа
      </VaAlert>
      <VaDataTable
        :loading="loading"
        :key="anexams"
        clickable
        :items="anexams" 
        :columns="columns" 
        sticky-header
        noDataHtml="Здесь пока ничего нет"
      >
      <template #cell(examDate)="{ rowData }">
        <span v-if="rowData">{{ rowData.examDate.split(' ')[0] }}</span>
      </template>

      <template #cell(patient_full_name)="{ rowData }">
        <VaIcon
            :name="rowData.paid ? 'attach_money' : 'cruelty_free'"
            :color="rowData.paid ? 'success' : 'primary'"
            class="w-full ml-2"
            text="asd"
          />
        {{ rowData.patient_full_name }}<br>Дата рождения {{ rowData.patient_birthdate ? rowData.patient_birthdate.split('-').reverse().join('.') : ''}}<br/>
        <VaChip v-if="rowData.medhistory" size="small" :color="rowData.medhistory ? 'warning' : 'success'">Осмотр прикперлен к ИБ №{{ rowData.medhistory }}</VaChip>
      </template>

      <template #cell(diagnosis)="{ rowData }">
        {{ rowData.diagnosis.length > 10 ? rowData.diagnosis.slice(0,10)+"..." : rowData.diagnosis }}
      </template>

      <template #cell(actions)="{ row, rowData, isExpanded, rowIndex }">
          <VaButton
            :icon="isExpanded ? 'va-arrow-up': 'va-arrow-down'"
            preset="primary"
            class="w-full"
            @click="row.toggleRowDetails()"
          />
          <VaButton
            icon="quick_reference_all"
            preset="primary"
            class="w-full ml-2"
            @click.before="selectedExam=rowIndex;$store.dispatch('app/setAnexam', anexams[rowIndex])"
            @click="showAnExamModal=!showAnExamModal"
          />
          <VaButton
            :disabled="!!rowData.medhistory"
            icon="edit"
            preset="primary"
            class="w-full ml-2"
            @click="$router.push('/anexam/edit/'+rowData.id+'?from=anexamsmanage')"
          />
          <VaButton
            icon="print"
            preset="primary"
            class="w-full ml-2"
            @click.before="selectedExam=rowIndex;$store.dispatch('app/setAnexam', anexams[rowIndex]);showAnExamModal=!showAnExamModal;"
            @click="print('anexam', true)"
          />
        </template>
        <template #expandableRow="{ rowData }" class="asdasdasd">
          <VaAlert
            color="primary"
            border="left"
          >
            <template #icon>
              <VaIcon name="info" />
            </template>
            <b>Дата и время осмотра:</b> {{ rowData.examDate }}<br>
                <b>Диагноз:</b> {{ rowData.diagnosis }}<br/>
                  <b>Анестезиолог-реаниматолог:</b> {{ rowData.anesthesist_full_name }}
              <VaCard class="rc-comment-card" v-if="rowData.medhistory">
                <VaCardContent class="rcflex column">
                  <span class="mb-4">История болезни №{{ rowData.medhistory }}</span>
                  <span class="ml-4">Статус: пациент {{ rowData.medhistory_discharged==false ? 'выписан' : 'находится в стационаре' }}</span>
                  <span class="ml-4">Палата: {{ rowData.medhistory_room ? rowData.medhistory_room : 'не указана' }}</span>
                  <span class="ml-4">Поступление: {{ formatDateTime(new Date(rowData.medhistory_openDate), "ru") }}</span>
                  <span class="ml-4" v-if="rowData.medhistory_discharged===true">Выписка:{{ formatDateTime(new Date(rowData.medhistory_discharge_date), "ru") }}</span>
                  <span class="ml-4" v-if="rowData.medhistory_closed===true">История болезни закрыта: {{ formatDateTime(new Date(rowData.medhistory_closeDate), "ru") }}</span>
                  <span class="ml-4" v-if="rowData.medhistory_accepted===true">История болезни в архиве с: {{ formatDateTime(new Date(rowData.medhistory_accepted_date), "ru") }}</span>
                </VaCardContent>
              </VaCard>
            </VaAlert>
        </template>
      </VaDataTable>
      <VaPagination
        v-if="pages>1"
        :key="page"
        v-model="page"
        :pages="pages"
        :visible-pages="3"
        buttons-preset="primary"
        gapped
        class="mb-6 justify-center sm:justify-start"
      />
    </VaCardContent>
  </VaCard>

  <VaModal
    :allowBodyScroll=true
    v-if="showAnExamModal"
    :closeButton="true"
    v-model="showAnExamModal"
    max-height="85vh"
    hideDefaultActions
    fixed-layout
    :title="'Осмотр анестезиолога-реаниматолога'"
    ref="showAnexamModal"
  >

    <AnExamPrintForm id="anexam"/>

    <template #footer>
      <VaButton
        preset="primary"
        color="secondary"
        icon="close"
        class="mr-2"
        @click="$refs.showAnexamModal.hide()"
      >
        Закрыть
      </VaButton>
      <VaButton
        preset="primary"
        icon="print"
        class="mr-2"
        @click="print('anexam')"
      >
        Печать
      </VaButton>
      <VaButton
        :disabled="!!anexams[selectedExam].medhistory"
        preset="primary"
        color="primary"
        icon="edit"
        class="mr-2"
        @click.before="$refs.showAnexamModal.hide()"
        @click="$router.push('/anexam/edit/'+anexams[selectedExam].id+'?from=manage')"
      >
        Изменить
      </VaButton>
    </template>
  </VaModal>
</template>
<script setup>
  import { formatDateTime } from '@/common/rcfx.js'
</script>
<script>
import axiosInstance from "../services/api";
import EventBus from "../common/EventBus";
import { RouterLink } from 'vue-router'
import PatientProfile from '@/components/PatientProfile';
import { VaCardContent, VaCardTitle, VaSwitch } from "vuestic-ui/web-components";
import AnExamPrintForm from "@/components/printforms/AnExamPrintForm"
import debounce from 'lodash.debounce'

export default {
    components: { RouterLink, PatientProfile, AnExamPrintForm },
    data() {
        return {
            loading: false,
            ownfilter: [
              { label: "Все", value: false },
              { label: "Только мои", value: true },
            ],
            formOptions: [
              { label: "Табличная форма", value: false },
              { label: "Печатная форма", value: true },
            ],
            columns: [
              { key: "id", label: "Номер", width: 1 },
              { key: "examDate", label: "Дата приема", width: 10 },
              { key: "patient_full_name", label: "ФИО пациента", width: 10 },
              { key: "anesthesist_full_name", label: "Анестезиолог-реаниматолог", width: 100 },
              { key: "actions", label: "Действия", width: 100 },
            ],
            itemsPerPage: 20,
            pages:1,
            page:1,
            visits: [],
            anexams: [],
            selectedExam: 0,
            patient: {},
            filter: 'open',
            myown: false,
            showAnExamModal: false,
            printWindow: false,
            prev: String,
            next: String,
            query: '',
          };
    },
    computed: {
      ownfiltered() {
        if (this.filter === 'all') {
          return this.filtered
        }
        if (this.filter === 'myown') {
          return this.filtered.filter(item => item.user === this.$store.state.app.account.profile.id)
        }
      },
      tooShortQuery() {
        return this.query.length !== 0 && this.query.length<3 ? true : false 
      }
    },
    watch: {
      selectedExam(value) {
        console.log('selectedExam: ' + value)
        this.$store.dispatch('app/setAnexam', this.anexams[value])
        console.log('---------' + JSON.parse(this.anexams[value].stool))
      },
      query: function (newValue) {
        if (!this.tooShortQuery) {
          this.debouncedSearch()
        }
      },
      myown() {
        this.getAnExams(true,this.query)
      },
      page(value) {
        this.getAnExams(false,this.query)
      }
    },
    methods: {
      async print(elid, wait=false) {
        if (wait) {
          await this.showAnExamModal && this.$htmlToPaper(elid, null, () => {this.$refs.showAnexamModal.hide()});
        }else{
          await this.$htmlToPaper(elid);
        }
      },
      printDocument() {
        this.printWindow = !this.printWindow
      },
      resetSearch() {
        this.query=""
        this.getAnExams(true,false)
      },
      debouncedSearch: debounce(function () {
              this.getAnExams(true, this.query);
            }, 600),
      getAnExams(resetPage, query=false) {
        this.loading = true
        console.log('firing request')
        var filter = ""
        if (query!=false && query.length > 2) filter += "&search=" + this.query
        if (this.myown===true) filter += "&user=" + this.$store.state.app.account.profile.id
        if (resetPage) this.page = 1
        filter += "&page=" + this.page
        axiosInstance
        .get('/anexams/manage?' + filter)
        .then(response => {
          var data = response.data
          this.pages = Math.ceil(data.count / this.itemsPerPage)
          this.anexams = data.results
          this.loading = false
        })
        .catch(error => {
          console.log('get anexams error')
          this.loading = false
        })
      },
    },
    created() {
      !this.$store.state.auth.status.loggedIn && this.$router.push('/login')
    },
    mounted() {
      this.getAnExams(false,false)
    },
}

</script>
<style>
</style>