<template>
  <VaCard
    stripe
    stripe-color="info"
    v-if="userProfile"
  >
    <VaCardTitle class="rcflex rcflex-btw row">
      Профиль
      <div>
        <VaButton preset="primary" icon="refresh" class="mr-2" @click="updateProfile"/>
        <VaButton v-if="!editProfile" preset="primary" icon="edit" class="mr-2" @click="editProfile = !editProfile"/>
      </div>
    </VaCardTitle>
    <VaCardContent>
        <VaForm>
          <VaFileUpload
          class="rcflex profile-avatar-container"
          :class="{ 'profile-noavatar' : !avatarPreview }"
          style="justify-content:center;align-items:center"
          color="primary"
          v-model="avatar"
          file-types="jpeg,JPEG,jpg,JPG,png,PNG"
          capture="user"
          type="single"
          ref="avatarFile"
          @change="setFile"
          @click.before="this.$store.dispatch('auth/refreshAccessToken');"
          :hideFileList="true"
          >
            <img v-if="!avatarIsUploading"
              :alt="userProfile.full_name"
              :src="userProfile.photo ? userProfile.photo : '/img/defaultavatar.jpg'"
              class="profile-avatar va-file-upload__field"
            />
            <VaIcon name="east" color="primary" v-if="!avatarIsUploading && avatarPreview" class="ml-4 mr-4"/>
            <img v-if="!avatarIsUploading && avatarPreview"
              :alt="userProfile.full_name"
              :src="avatarPreview"
              class="profile-avatar"
            />
            <VaProgressCircle v-if="avatarIsUploading"
              indeterminate 
              size="128px"
            />
          </VaFileUpload>
          <VaButton v-if="avatar" icon="upload" preset="primary" class="rcflex center mt-4" @click="uploadAvatar">Заменить</VaButton>
        </VaForm>

        <VaForm ref="profileForm" v-show="editProfile" :key="form" class="items-baseline gap-6" style="display: flex; flex-direction: column; max-width:600px">
        <VaInput class="mb-3"
          v-model="form.full_name"
          :rules="[(value) => (value && value.length > 0) || 'First name is required']"
          label="ФИО"
        />

        <label class="va-input-label" style="color: var(--va-primary);">Пол</label>
        <VaRadio class="mb-3"
          v-model="form.sex"
          :options="[
            {
              text: 'Мужской',
              value: 'm',
            },
            {
              text: 'Женский',
              value: 'f',
            },
          ]"
          value-by="value"
        />
      
        <VaInput class="mb-3"
          v-model="form.phone"
          :rules="[(value) => (value && value.length > 0) || 'Last name is required']"
          label="Телефон"
        />

        <VaInput class="mb-3"
          v-model="form.email"
          :rules="[(value) => (value && value.length > 0) || 'Last name is required']"
          label="email"
        />
        
        <div class="rcflex row">
          <VaButton
            class="mr-3"
            icon="cancel"
            preset="primary"
            color="secondary"
            @click="editProfile = !editProfile"
            >
            Отменить
          </VaButton>
          <VaButton
            class=""
            icon="save"
            preset="primary"
            color="success"
            @click="patchProfile(this.form)"
            >
            Сохранить
          </VaButton>
      </div>
                    
      </VaForm>
      <VaList v-show="!editProfile">
        <VaListItem>
          <VaListItemSection>
            <VaListLabel class="rc-profile-title">Уведомления</VaListLabel>
            <VaListItemLabel class="ml-2">Ваша ОС: {{platform}}</VaListItemLabel>
            
            <VaSwitch
              :disabled="!this.$messaging"
              color="success"
              label="Push-сервис: "
              leftLabel
              style="align-self: flex-start"
              v-model="subscribeStatus"
              @click="pushServiceStatus=!pushServiceStatus"
              :loading="waitingFCM==true"
              size="small"
              class="ml-2"
              />
              <VaAlert v-if="this.$messaging"
                style="margin: unset;max-width: max-content;"
                :color="pushServiceStatus && !waitingFCM ? 'success' : 'warning'"
                border="left"
                class="mt-2 mb-2"
              >
                {{ pushServiceStatus && !waitingFCM 
                  ? 'Push-уведомления включены в настройках профиля. Вы будете получать уведомления о важных событиях' 
                  : 'Push-уведомления отключены. Вы не сможете получать уведомления о важных событиях'
                  }}
              </VaAlert>
              <VaAlert v-else
                style="margin: unset;max-width: max-content;"
                color="danger"
                border="left"
                class="mt-2 mb-2"
              >
              
              <p>Функционал Push-уведомлений ограничен на этом устройстве: 
              {{ platform === "iOS" || platform === "Android" || platform === "Mac OS" ? 'на платформе ' + platform + ' сначала нужно установить приложение' : 'Ваш браузер не поддерживает функционал Push-уведомлений'}}
              </p>
              <VaButton preset="primary" color="danger" icon="help" class="mt-4" @click="showInstallTip(platform)">Как?</VaButton>
              </VaAlert>
              <div v-if="this.$messaging">
                <VaButton v-if="pushServiceStatus && !waitingFCM" class="mt-3 mb-3" @click="showPushToken=!showPushToken" preset="primary">{{ showPushToken ? 'Скрыть идентификатор' : 'Показать идентификатор' }}</VaButton>
                <VaAlert v-if="showPushToken && pushServiceStatus && !waitingFCM" outline color="#cccccc" style="inline-size: 100%;overflow-wrap: anywhere;">
                  {{ pushToken }}
                </VaAlert>
                <VaButton v-if="!subscribeStatus" preset="primary" color="info" icon="restart_alt" :loading="cleaningFirebase" @click="cleanFirebaseDB">
                  Сбросить Push-сервис
                </VaButton>
              </div>
            <VaListLabel class="rc-profile-title">Личные данные</VaListLabel>
            <VaListItemLabel>
              ФИО: {{ userProfile.full_name }}
            </VaListItemLabel>
            <VaListItemLabel>
              Имя пользователя: {{userProfile.username}}        
            </VaListItemLabel>
            <VaListItemLabel>
              Пол: <span v-if="userProfile.sex == 'm'">Мужской</span><span v-if="userProfile.sex == 'f'">Женский</span>
            </VaListItemLabel>
            <VaListItemLabel>
              Уровень доступа: {{ userProfile.is_superuser ? "Администратор" : "Пользователь"}}        
            </VaListItemLabel>
            <VaListItemLabel>
              Группа:
              <span v-if="userProfile.groups && userProfile.groups.length==0">В группах не состоит</span>
              <span
                v-for="(group, index) in userProfile.groups"
                :key="index"
                class="list__item"
              >{{ group.name + ((userProfile.groups.length > 1) && (userProfile.groups.length-1 > index) ? ", " : "") }}</span>
            </VaListItemLabel>
            <VaListItemLabel>
              Дата регистрации: {{userProfile.date_joined}}
            </VaListItemLabel>
            <VaListLabel class="rc-profile-title">Контактные данные</VaListLabel>
            <VaListItemLabel>
              Телефон: {{userProfile.phone}}
            </VaListItemLabel>
            <VaListItemLabel>
              e-mail: {{userProfile.email}}
            </VaListItemLabel>
           
          </VaListItemSection>
        </VaListItem>
      </VaList>
    </VaCardContent>
  </VaCard>
  <VaModal
    :allowBodyScroll=true
    v-model="editError"
    size="small"
    blur
    hideDefaultActions
    ref="error"
  >
    
    <template #default>
      <h4 class="va-h4">
        <VaIcon size="2rem" color="danger" name="close"/>Ошибка!
      </h4>
      <p class="mb-4">
        Проверьте правильность заполнения всех полей
      </p>
    </template>
    <template #footer>
      <VaButton @click.before="$refs.error.hide()" @click="editError = !editError" preset="primary"> Исправить </VaButton>
    </template>
  </VaModal>

</template>

<script>
import axiosInstance from "../services/api";
import * as rcfx from "@/common/rcfx"
import EventBus from "@/common/EventBus";
import resizeImage from '@/common/image-resize';
export default {
  computed: {
    userData: function(){return this.$store.getters.userData},
    subscribeStatus: function() {
      this.pushServiceStatus = this.$store.state.app.account.profile.pushservice ? this.$store.state.app.account.profile.pushservice : false
      return this.$store.state.app.account.profile.pushservice
    },
    userProfile:  function() { return this.$store.state.app.account.profile },
    pushToken: function() { return localStorage.getItem('pushToken') },
  },
  data() {
    return {
      form: {
        full_name: "",
        sex: "",
        phone: "",
        email: "",
      },
      avatarPreview: null,
      avatar: undefined,
      avatarHover: false,
      avatarIsUploading: false,
      editProfile: false,
      editError: false,
      editSuccess: false,
      waitingFCM: false,
      cleaningFirebase: false,
      showPushToken: false,
      pushServiceStatus: null,
      platform: rcfx.detectOS(),
      howToInstall: false,
    };
  },
  watch: {
    pushServiceStatus:
        function(newValue) {
          if (newValue != this.subscribeStatus && this.$store.state.auth.status.loggedIn) {
            // console.log('Блокируем переключатель')
            this.waitingFCM = true
            if (newValue === true) {
              this.subscribe()
            }
            if (newValue === false) {
              this.unsubscribe()
            }
        }
    },
    editProfile: function(value) {
      if (value = true && this.userProfile) {
        Object.assign(this.form,this.userProfile)
      }
    },
  },
  methods: {
    showInstallTip(platform) {
        var ios_icon = '<i class="va-icon material-icons" aria-hidden="true" notranslate="" style="font-size: 24px; height: 24px; line-height: 24px;">ios_share</i>'
        var android_icon = '<i class="va-icon material-icons" aria-hidden="true" notranslate="" style="font-size: 24px; height: 24px; line-height: 24px;">more_vert</i>'
        this.$vaToast.closeAll()
        platform === "iOS" && this.$vaToast.init({ 
          message: 'Нажмите значок ' + ios_icon + ' и выберите пункт "На экран Домой"', 
          color: 'info', position: 'bottom', dangerouslyUseHtmlString: true 
        })
        platform === "Android" && this.$vaToast.init({ 
          message: 'Нажмите значок ' + android_icon + ' и выберите пункт "Добавить на главный экран"', 
          color: 'info', position: 'top-right', dangerouslyUseHtmlString: true 
        })
    },
    subscribe() {
      // this.waitingFCM = true
      EventBus.dispatch("setPushServiceStatus", {value: true, silent: false})
    },
    unsubscribe() {
      // this.waitingFCM = true
      EventBus.dispatch("setPushServiceStatus", {value: false, silent: false})
    },
    cleanFirebaseDB() {
      this.cleaningFirebase = true
      EventBus.dispatch("cleanFirebaseDB")
    },
    showUploadButton() {
      this.avatarHover = true
    },
    hideUploadButton() {
      var that = this
      setTimeout(function() {
        that.avatarHover = false}.bind(this)
      , 2000)
    },
    setFile() {
      // Предпросмотр
      const input = this.$refs.avatarFile
      const files = input.files
      const filename = files[0].name
      
      if (files && files[0]) {
        const reader = new FileReader
        reader.onload = e => {
          this.avatarPreview = e.target.result
        }
        reader.readAsDataURL(files[0])
        this.$emit('input', files[0])
      }
      // Уменьшаем разрешение и генерируем новую форму
      resizeImage({ file: files[0], maxSize: 256, os: this.platform })
      .then((blobImage) => {
          let file = new FormData()
          file.set('photo', blobImage, filename)
          this.avatar = file
      })
      
    },
    updateProfile() {
      console.log('refreshin profile')
      this.$store.dispatch('app/getProfile')
    },
    groupsParser(groups) {
      groups.forEach((element) => {return element.name});
    },
    listDevices() {
      axiosInstance
          .post(`/devices/?user=`+this.userProfile.id,)
          .then(response => {
            console.log('Device list ok');
            this.devices = response.data.results
          })
          .catch(error => {
            console.log('Device list error: ' + error);
          })
    },
    uploadAvatar() {
      console.log('File is on the way')
      this.avatarIsUploading = true
      this.avatarHover = false
      axiosInstance
        .patch(`/profile/`, this.avatar, { headers: {'Content-Type': 'multipart/form-data'} })
        .then(response => {
          this.avatar = undefined
          this.avatarPreview = null
          // console.log('New profile is ' + JSON.stringify(response.data))
          this.$store.dispatch('app/getProfile')
          .then(()=>{
            this.$vaToast.init({ message: 'Фото профиля обновлено', color: 'success', position: 'bottom-right' })
          })
        })
        .catch(error => {
        console.log('Avatar Request Failed: ' + error);
        this.gotError = true;
        })
        .finally(() => {
          console.log('Avatar: Request completed')
          this.avatar = undefined
          this.isLoading = false
          this.avatarIsUploading = false
        });
      },
    patchProfile(userProfile) {
      userProfile.photo && delete userProfile.photo
      userProfile.id && delete userProfile.id
      userProfile.last_login && delete userProfile.last_login
      userProfile.user_permissions && delete userProfile.user_permissions
      userProfile.groups && delete userProfile.groups
      userProfile.date_joined && delete userProfile.date_joined
      userProfile.photo && delete userProfile.photo
      userProfile.pushservice && delete userProfile.pushservice
      userProfile.username && delete userProfile.username
      axiosInstance
        .patch(`/profile/`, userProfile)
        .then(response => {
          this.$store.dispatch('app/getProfile')
          .then(()=>{
            this.$vaToast.init({ message: 'Профиль обновлен', color: 'success', position: 'bottom-right' })
            this.editSuccess = true
            this.editProfile = false
          })
          
          // console.log('New profile is ' + JSON.stringify(this.userProfile))
        })
        .catch(error => {
          console.log('Profile Request Failed: ' + error);
          this.editError = true;
        })
      },
  },
  created() {
    !this.$store.state.auth.status.loggedIn && this.$router.push('/login')
    this.$store.state.auth.status.loggedIn && this.updateProfile()
  },
  mounted() {
    EventBus.on("PushStatusReady", (e) => {
      console.log('Получили промис: ' + e.detail)
      this.waitingFCM = false
      if (e.result === "error") this.pushServiceStatus = false
    })
    EventBus.on("cleanFirebaseDBfinished", () => {
      this.cleaningFirebase = false
    })
  }
};
</script>