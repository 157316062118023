<template>
  <VaCard stripe stripe-color="success">
      <VaCardTitle class="rcflex rcflex-btw row">
        <VaButton
        icon="arrow_back_ios_new"
        color="primary"
        icon-color="#333"
        class="mr-3 rcflex"
        preset="secondary"
        hover-behavior="opacity"
        :hover-opacity="0.4"
        @click="$router.back()"
      />
        <span class="">Амбулаторная карта пациента</span>
        <div class="">
          <VaButton preset="primary" icon="edit" class="rcflex mr-2" @click="$router.push('/patients/' + patientId + '/edit')"/>
        </div>
      </VaCardTitle>
  
      <VaCardContent>
  
        <VaButton
          icon="add"
          preset="primary"
          class="mr-2 mb-2"
          @click="$router.push('/regs/add/' + patientId )"
          >Записать на прием
        </VaButton>
  
        <VaButton
          v-if="!medhistoryExists && iamDoctor"
            icon="post_add"
            preset="primary"
            class="mr-2 mb-2"
            @click="confirmCreateMedhistory"
          >
          Начать историю болезни
        </VaButton>
  
        <VaButton
        v-if="medhistoryExists"
            icon="quick_reference_all"
            preset="primary"
            class="mr-2 mb-2"
            @click="$router.push('/medhistory/' + medhistory.id)"
          >
          Открыть историю болезни
        </VaButton>
        <VaMenu closeOnClickOutside=true ref="printMenu">
          <template #anchor>
            <VaButton class="mr-2 mb-2" icon="print" preset="primary">Печать</VaButton>
          </template>
          <VaMenuItem @selected="print('profile');" icon="user_attributes">
            Амбулаторная карта
          </VaMenuItem>  
          <VaMenuItem @selected="print('acceptOPD');" icon="contract">
            Согласие на ОПД
          </VaMenuItem>
          <VaMenuItem @selected="print('acceptLN');" icon="contract">
            Согласие на оформление ЛН
          </VaMenuItem>
          <VaMenuItem @selected="print('paidServices');" icon="contract">
            Согласие на платные услуги
          </VaMenuItem>
          <VaMenuItem @selected="print('AgreementPaidServices');" icon="contract">
            Договор на оказание платных услуг
          </VaMenuItem>
          <VaMenuItem @selected="print('omsServices');" icon="contract">
            Согласие на услуги по ОМС
          </VaMenuItem>
            <VaDropdown trigger="hover" placement="right-center">
              <template #anchor>
                <VaMenuItem icon="contract">
                  Анестезия <VaIcon name="chevron_right"/>
                </VaMenuItem>
              </template>
              <VaDropdownContent>
                <VaMenuItem @selected="$refs.printMenu.close();print('acceptKEN')" icon="ecg">
                  КЭН
                </VaMenuItem>
                <VaMenuItem @selected="$refs.printMenu.close();print('acceptPA');" icon="ecg">
                  Проводниковая анестезия
                </VaMenuItem>
                <VaMenuItem @selected="$refs.printMenu.close();print('acceptSMA');" icon="ecg">
                  СМА
                </VaMenuItem>
                <VaMenuItem @selected="$refs.printMenu.close();print('acceptTVA');" icon="ecg">
                  ТВА
                </VaMenuItem>
              </VaDropdownContent>
            </VaDropdown>
        </VaMenu>
        <VaSkeletonGroup v-if="isLoading">
          <VaSkeleton tag="h5" variant="text" class="va-h5" :lines="1" height="28px"/>
          <VaSkeleton tag="p" variant="text" class="mb-5 va-h6" lines="12" width="40%"/>
          <VaSkeleton variant="rounded" class="va-button va-button--normal va-button--left-icon mr-3 mb-2" height="2em" width="186px"/>
          <VaSkeleton variant="rounded" class="va-button va-button--normal va-button--left-icon mr-3 mb-2" height="2em" width="214px"/>
        </VaSkeletonGroup>
        
        <div v-else class="va-table-responsive">
          <div class="va-list-label rc-text-l va-text-left mt-4">{{ patient.full_name }}, {{ patient.birthdate }}</div>
  
          <PatientProfile :patient="patient" />
  
          <VaCollapse
              icon="person"
              v-model="toggleCard"
              class="min-w-96 mb-3 rc-text-m"
              header="История амбулаторных приемов"
            >
              <VaList class="ma-3">
                <VaListItem v-if="regs.length>0"
                  v-for="(reg, index) in regs"
                  :key="index"
                  class="list__item"
                >
  
                  <VaListItemSection>
  
                    <VaListItemLabel class="va-h7">
                      {{ reg.regDate }}
                    </VaListItemLabel>
  
                    <VaListItemLabel>
                      Врач: {{ reg.doctor_full_name }}
                    </VaListItemLabel>
  
                    <VaListItemLabel>
                      Причина обращения: {{ reg.examReason }}
                    </VaListItemLabel>
  
                    <VaListItemLabel  class="mb-4 va-secondary" >
                      {{ reg.visited == 0 ? "Неявка" : "Визит состоялся" }}
                    </VaListItemLabel>
  
                  </VaListItemSection>
  
                </VaListItem>
                <span v-else>Пациент еще не посещал клинику</span>
              </VaList>
              </VaCollapse>
  
          </div>
      </VaCardContent>
    </VaCard>
    <VaModal
      :allowBodyScroll=true
      ref="printModal"
      v-model="printModal"
      hideDefaultActions
      fixed-layout
      title="Печать документа" >
      <div id="print">
        <AcceptOPD :patient="patient" :date="date" v-if="document==='acceptOPD'"/>
        <AcceptLN :patient="patient" :date="date" v-if="document==='acceptLN'"/>
        <PaidServices :patient="patient" :date="date" v-if="document==='paidServices'"/>
        <OmsServices :patient="patient" :date="date" v-if="document==='omsServices'"/>
        <AgreementPaidServices :patient="patient" :date="date" v-if="document==='AgreementPaidServices'"/>
        <PatientProfilePrintForm :patient="patient" :date="date" v-if="document==='profile'"/>
        <AcceptKEN :patient="patient" :date="date" :anesthesist="anesthesist" v-if="document==='acceptKEN'"/>
        <AcceptPA :patient="patient" :date="date" :anesthesist="anesthesist" v-if="document==='acceptPA'"/>
        <AcceptSMA :patient="patient" :date="date" :anesthesist="anesthesist" v-if="document==='acceptSMA'"/>
        <AcceptTVA :patient="patient" :date="date" :anesthesist="anesthesist" v-if="document==='acceptTVA'"/>
      </div>
      <template #footer>
        <VaButton
          class="center" 
          preset="primary"
          color="secondary"
          @click="$refs.printModal.hide()" 
          >
          Закрыть
        </VaButton>
      </template>
    </VaModal>
    <VaModal
      :allowBodyScroll=true
      ref="selectA9Nmodal"
      v-model="selectA9N"
      hideDefaultActions
      fixed-layout
      title="Уточним детали" >
      <p class="mb-2">Кто осмотрел пациента?</p>
      <VaSelect
            class="mb-2"
            v-model="anesthesistId"
            label="Анестезиолог-реаниматолог"
            placeholder="Выберите из списка"
            text-by="full_name"
            value-by="id"
            :options="anesthesists"
        />
        <div class="rcflex row rcflex-btw">
                <VaButton
                  class="center" 
                  preset="primary"
                  color="secondary"
                  @click="$refs.selectA9Nmodal.hide()" 
                  >
                  Отмена
                </VaButton>
                <VaButton
                  class="center" 
                  preset="primary"
                  @click.before="$refs.selectA9Nmodal.hide()" 
                  @click="printAccept(document);"
                  >
                  Продолжить
                </VaButton>
              </div>
    </VaModal>
  </template>
  <script setup>
    import { formatDate } from "@/common/rcfx"
  </script>
  <script>
  import axiosInstance from "../services/api"
  import EventBus from "../common/EventBus"
  import { RouterLink } from 'vue-router'
  import PatientProfilePrintForm from '@/components/documents/PatientProfilePrintForm'
  import PatientProfile from '@/components/PatientProfile'
  import WindowPortal from '@/components/WindowPortal'
  import AcceptOPD from '@/components/documents/AcceptOPD'
  import AcceptLN from '@/components/documents/AcceptLN'
  import PaidServices from '@/components/documents/PaidServices'
  import OmsServices from '@/components/documents/OmsServices'
  import AcceptKEN from '@/components/documents/AcceptKEN'
  import AcceptPA from '@/components/documents/AcceptPA'
  import AcceptSMA from '@/components/documents/AcceptSMA'
  import AcceptTVA from '@/components/documents/AcceptTVA'
  import AgreementPaidServices from "@/components/documents/AgreementPaidServices"
import { VaCardContent, VaDropdown, VaMessageList } from "vuestic-ui/web-components"
  
  export default {
      components: { RouterLink, PatientProfile, WindowPortal, AcceptOPD, AcceptLN, PaidServices, OmsServices, AcceptKEN, AcceptPA, AcceptSMA, AcceptTVA, AgreementPaidServices },
      props: {
        patientId: String,
      },
      data() {
          return {
              bloodTypes: [
                "",
                "0(I)",
                "A(II)",
                "B(III)",
                "AB(IV)",
              ],
              isLoading: true,
              regsIsLoading: true,
              patient: Array,
              patientData: Array,
              regs: Array,
              regsCount: Number,
              medhistoryExists: false,
              medhistory: {},
              printModal: false,
              open: false,
              toggleCard: false, 
              document: '',
              date: formatDate(new Date(), "ru"),
              apptalk: new BroadcastChannel('inapp'),
              anesthesists: [],
              anesthesistId: '',
              anesthesist: {},
              selectA9N: false,
          };
      },
      watch: {
        isLoading(val) {
          if (val===false) {this.getRegs()}
        },
        anesthesistId(newValue) {
          if (newValue) {
            this.anesthesist = this.anesthesists.filter((item) => item.id === newValue)[0]
          }
        },
      },
      computed: {
        userProfile() {
          return this.$store.state.app.account.profile 
        },
        iamDoctor: function() {
          return this.userProfile.iamDoctor
        },
      },
      methods: {
        async print(document) {
          this.printModal = true
          this.document = document
          await this.printModal && this.$htmlToPaper('print', null, ()=>{this.$refs.printModal.hide()})
        },
        toggleSelectA9NModal(document) {
          this.document = document
          this.selectA9N = !this.selectA9N
        },
        getAnesthesists() {
        axiosInstance
          .get('/staff/?group=anesthesists')
          .then(response => {
            this.anesthesists = response.data.results
          })
          .catch(error => {
            console.log('get staff err')
          })
        },
        printAccept(document) {
          this.document = document
          this.open = !this.open
        },
        checkMedhistory() {
          axiosInstance
              .get(`/medhistory/patient/` + this.patientId)
              .then(response => {
                console.log('count is:' + response.data.count)
                  if (response.data.count > 0) {
                    this.medhistory = response.data.results[0]
                    this.medhistoryExists = true
                  }
              })
              .catch(error => {
                console.log('Templates: error ' + error);
          })
              .finally(() => {
                console.log('Templates: Request completed')
          });
        },
        confirmCreateMedhistory() {
          this.$vaModal.confirm({
            size: "medium",
            message: 'Вы уверены что хотите начать историю болезни для пациента ' + this.patient.full_name  + '?',
            okText: "Да, начнем",
            cancelText: "Нет",
            allowBodyScroll: false,
          })
          .then((ok) => ok && this.$router.push('/medhistory/add/' + this.patientId))
        },
        getPatient(id) {
          console.log('id is ' + id)
          axiosInstance
              .get(`patients/`+id+`/`)
              .then(response => {
                this.patient = response.data;
          })
              .catch(error => {
              console.log('Axios Request Failed: ' + error);
              this.gotError = true;
          })
              .finally(() => {
                console.log('One patient: Request successfully completed')
                this.isLoading = false
          });
        },
        getRegs() {
          axiosInstance
            .get('registrations/patient/' + this.patient.id)
            .then((response) => {
              // console.log('Regs: ok')
              // console.log(JSON.stringify(response.data.results))
                this.regsCount = response.data.count
                this.regs = response.data.results

            },
            (error) => {
              //console.log(JSON.stringify(error))
              console.log('Regs request failed')
              this.content =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();

              if (error.response && error.response.status === 400) {
                EventBus.dispatch("logout");
              }
            })
            .finally(() => {
              this.regsIsLoading = false
            })
        },
      },
      mounted() {
        //const pid = Number.parseInt(this.patientId)
        if (!isNaN(Number(this.patientId))) {
          this.getAnesthesists()
         // console.log(pid)
          this.getPatient(this.patientId)
          this.checkMedhistory()
        }else{
          console.log('got string ID')
          // this.$router.push('/patients')
        }
      },
  }
  
  </script>
  <style>
  </style>
    