import api from './api';

class UserService {
  getProfile() {
    return api.get('/profile/')
    .then((response) => {
      return response.data;
    })
    .catch((e)=>{
      return e
    })
  }
 
  getPublicContent() {
    return api.get('/test/all');
  }

  getUserBoard() {
    return api.get('/test/user');
  }

  getModeratorBoard() {
    return api.get('/test/mod');
  }

  getAdminBoard() {
    return api.get('/test/admin');
  }

  setPushServiceStatus(value) {
    return api.patch(`/profile/`, {"pushservice":value})
  }
}

export default new UserService();
