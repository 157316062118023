<template>
    <VaCardTitle class="rc-text-m">Внутренний порядковый номер № {{ preop.id }}</VaCardTitle>
    <VaCardTitle class="rc-text-m">Диагноз</VaCardTitle>
    <VaCardContent>
    {{ preop.diagnosis }}
    </VaCardContent>
    <VaCardTitle class="rc-text-m">Диагноз сопутствующий</VaCardTitle>
    <VaCardContent>
    {{ preop.diagnosis_secondary }}
    </VaCardContent>
    <VaCardTitle class="rc-text-m">Обоснование диагноза</VaCardTitle>
    <VaCardContent>
    {{ preop.proof }}
    </VaCardContent>
    <VaCardTitle class="rc-text-m">Планируемый объем оперативного вмешательства</VaCardTitle>
    <VaCardContent>
    {{ preop.scope }}
    </VaCardContent>
    <VaCardTitle class="rc-text-m">Планируемая анестезия</VaCardTitle>
    <VaCardContent>
    {{ preop.anesthesia }}
    </VaCardContent>
    <VaCardTitle class="rc-text-m">Риск ВТЭО</VaCardTitle>
    <VaCardContent>
    {{ preop.vteo_risc == 0 ? "Низкий" : "Высокий" }}
    </VaCardContent>
    <VaCardTitle class="rc-text-m">Возможные осложнения</VaCardTitle>
    <VaCardContent>
    {{ preop.possible_risc }}
    </VaCardContent>
    <VaCardTitle class="rc-text-m">Аллергические реакции</VaCardTitle>
    <VaCardContent>
    {{ preop.status_alergo }}
    </VaCardContent>
</template>
<script>
export default {
    props: ['preop'],
}
</script>