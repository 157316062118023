<template>
    <VaCardTitle class="rc-text-m">Внутренний порядковый номер № {{ operation.id }}</VaCardTitle>
    <VaCardTitle class="rc-text-m">Начало операции</VaCardTitle>
    <VaCardContent>
    {{ operation.operationStart }}
    </VaCardContent>
    <VaCardTitle class="rc-text-m">Конец операции</VaCardTitle>
    <VaCardContent>
    {{ operation.operationEnd }}
    </VaCardContent>
    <VaCardTitle class="rc-text-m">Предоперационный диагноз</VaCardTitle>
    <VaCardContent>
    {{ operation.diagnosis }}
    </VaCardContent>
    <VaCardTitle class="rc-text-m">Номер операции</VaCardTitle>
    <VaCardContent>
    {{ operation.operationNo }}
    </VaCardContent>
    <VaCardTitle class="rc-text-m">Название операции</VaCardTitle>
    <VaCardContent>
    {{ operation.operationName }}
    </VaCardContent>
    <VaCardTitle class="rc-text-m">Код операции</VaCardTitle>
    <VaCardContent>
    {{ operation.operationCode }}
    </VaCardContent>
    <VaCardTitle class="rc-text-m">Код КСГ</VaCardTitle>
    <VaCardContent>
    {{ operation.operationCodeKSG }}
    </VaCardContent>
    <VaCardTitle class="rc-text-m">Анестезиологическое пособие</VaCardTitle>
    <VaCardContent>
    {{ operation.anesthesia }}
    </VaCardContent>
    <VaCardTitle class="rc-text-m">Ход операции</VaCardTitle>
    <VaCardContent>
    {{ operation.operationLog }}
    </VaCardContent>
    <VaCardTitle class="rc-text-m">Осложнения</VaCardTitle>
    <VaCardContent>
    {{ operation.complications }}
    </VaCardContent>
    <VaCardTitle class="rc-text-m">Расходный материал</VaCardTitle>
    <VaCardContent>
    {{ operation.inserted }}
    </VaCardContent>
    <VaCardTitle class="rc-text-m">Удаленный макропрепарат</VaCardTitle>
    <VaCardContent>
    {{ operation.removed }}
    </VaCardContent>
    <VaCardTitle class="rc-text-m">Диагноз постоперационный</VaCardTitle>
    <VaCardContent>
    {{ operation.diagnosis_postop }}
    </VaCardContent>
    <VaCardTitle class="rc-text-m">Код диагноза</VaCardTitle>
    <VaCardContent>
    {{ operation.mkb10 }}
    </VaCardContent>
    <VaCardTitle class="rc-text-m">Рекомендации врача-оператора по дальнейшей тактике ведения</VaCardTitle>
    <VaCardContent>
    {{ operation.recom }}
    </VaCardContent>
    <VaCardTitle class="rc-text-m">Операционная команда</VaCardTitle>
    <VaCardContent>

    <div class="ml-2 pa-2 rcflex row vcenter">{{ operation.operator_full_name }}
    <VaAlert outline color="danger" class="ml-2 pa-2">Оператор</VaAlert></div>

    <div class="ml-2 pa-2 rcflex row vcenter" v-if="operation.assistOne_full_name">{{ operation.assistOne_full_name }}
    <VaAlert outline color="danger" class="ml-2 pa-2">Ассистент оператора №1</VaAlert></div>

    <div class="ml-2 pa-2 rcflex row vcenter" v-if="operation.assistTwo_full_name">{{ operation.assistTwo_full_name }}
    <VaAlert outline color="danger" class="ml-2 pa-2">Ассистент оператора №2</VaAlert></div>

    <div class="ml-2 pa-2 rcflex row vcenter" v-if="operation.opSister_full_name">{{ operation.opSister_full_name }}
    <VaAlert outline color="info" class="ml-2 pa-2">Операционная медсестра</VaAlert></div>

    <div class="ml-2 pa-2 rcflex row vcenter" v-if="operation.anesthetist_full_name">{{ operation.anesthetist_full_name }}
    <VaAlert outline color="primary" class="ml-2 pa-2">Анестезиолог</VaAlert></div>

    <div class="ml-2 pa-2 rcflex row vcenter" v-if="operation.anSister_full_name">{{ operation.anSister_full_name }}
    <VaAlert outline color="primary" class="ml-2 pa-2">Анестезиологическая медсестра</VaAlert></div>

    <div class="ml-2 pa-2 rcflex row vcenter" v-if="operation.xraytech_full_name">{{ operation.xraytech_full_name }}
    <VaAlert outline color="warning" class="ml-2 pa-2">Рентген-лаборант</VaAlert></div>

    
    </VaCardContent>


</template>
<script>
export default {
    props: ['operation'],
}
</script>