<template>
<VaCard stripe stripe-color="success">
    <VaCardTitle>
      <VaButton
      icon="arrow_back_ios_new"
      color="primary"
      icon-color="#333"
      class="mr-3"
      preset="secondary"
      hover-behavior="opacity"
      :hover-opacity="0.4"
      @click="$router.back()"
    />
    Создание истории болезни
    </VaCardTitle>
    <VaCardContent>
    Создаем историю болезни для пациента:  {{patient.lastname  + ' ' + patient.firstname  + ' ' + patient.patronymic}}
  </VaCardContent>
    <VaCardContent v-if="medhistoryExists">
      <VaAlert
        icon="warning"
        color="warning"
        class="mb-3"
      >
        Для этого пациента уже есть открытая История болезни № {{ medhistory.id }} от {{ medhistory.openDate }}
      </VaAlert>
      <VaButton
            class="mr-3"
            icon="cancel"
            preset="primary"
            color="secondary"
            @click="$router.back()"
            >
            Отменить
          </VaButton>
        <VaButton
            class="mr-3"
            icon-right="arrow_forward"
            preset="primary"
            color="success"
            @click="$router.replace('/medhistory/' + medhistory.id)"
            >
            Перейти к истории
          </VaButton>
    </VaCardContent>
    <VaCardContent v-else>
      <VaForm ref="medhistoryForm" class="items-baseline gap-6" style="display: flex; flex-direction: column; max-width:600px">
    
        <label class="va-input-label" style="color: var(--va-primary);">Признак оплаты</label>
        <div class="rcflex vcenter">
          <VaSwitch
            v-model="form.paid"
            size="small"
            class="rcflex mt-3 mb-3 mr-3"
          />
          {{ form.paid ? "Платный" : "КСГ"}}
        </div>
        <VaInput class="mb-3"
          v-model="form.room"
          label="Палата"
          placeholder="Укажите палату, если она известна"
        />
      
        <div class="rcflex row">
          <VaButton
            class="mr-3"
            icon="cancel"
            preset="primary"
            color="secondary"
            @click="$router.back()"
            >
            Отменить
          </VaButton>
          <VaButton
            class=""
            icon="save"
            preset="primary"
            color="success"
            @click="createMedhistory"
            >
            Продолжить
          </VaButton>
      </div>
                    
      </VaForm>
    </VaCardContent>
  </VaCard>

</template>
<script>
import axiosInstance from "../services/api";
import EventBus from "../common/EventBus";
import { RouterLink } from 'vue-router'

export default {
    components: { RouterLink },
    props: {
      patientId: String,
    },
    data() {
        return {
          medhistoryExists: false,
          medhistory: {},
          patient: {},
          form: {
            openDate: null,
            closeDate: null,
            paid: false,
            room: '',
            patient: Number(this.patientId),
            user: Number(this.$store.state.app.account.profile.id),
          }
        };
    },
    methods: {
      getPatient(id) {
            axiosInstance
                .get(`patients/`+id+`/`)
                .then(response => {
                  this.patient = response.data;
            })
                .catch(error => {
                this.getPatientError = true;
            })
          },
      createMedhistory() {
        this.form.openDate = new Date
        axiosInstance
            .post(`medhistory/`, this.form)
            .then(response => {
              console.log('Medhistory created, id is: ' + response.data.id);
              this.$router.replace('/medhistory/' + response.data.id)
        })
            .catch(error => {
            console.log('Axios Request Failed: ' + error);
            this.createError = true;
        })
            .finally(() => {
              console.log('Create medhistory: prcess ended')
        });
      },
      checkMedhistory() {
        axiosInstance
            .get(`/medhistory/patient/` + this.patientId)
            .then(response => {
              console.log('count is:' + response.data.count)
                if (response.data.count > 0) {
                  this.medhistory = response.data.results[0]
                  this.medhistoryExists = true
                }
            })
            .catch(error => {
              console.log('Templates: error ' + error);
        })
            .finally(() => {
              console.log('Templates: Request completed')
        });
      },
    },
    
    mounted() {
      this.getPatient(this.patientId)
      this.checkMedhistory()
    },
}

</script>
<style>
</style>
  