<template>
<div class="print">
    <p style="text-align:center;font-weight:bold;">Согласие на обработку персональных данных пациента</p>
    <p style="text-align:right">{{ date }} г.</p>
    <p style="text-align:justify;text-indent: 2em;">Я,   {{ patient.full_name}},

    {{ patient.sex == "m" ? 'проживающий' : 'проживающая' }} по адресу: {{ patient.address}},
    паспорт: {{ passport}},

    в соответствии с требованиями ст.9 Федерального закона от 27.07.06 г. №152-ФЗ «О персональных данных». Подтверждаю свое согласие ООО «Клиника Профессора» (далее-Оператор) на обработку моих (его, ее) персональных данных, включающих: фамилию, имя, отчество, пол, дату рождения, адрес проживания, контактный телефон, данные о полисе обязательного медицинского страхования, страховой номер индивидуального лицевого счета Пенсионного фонда Российской Федерации (СНИЛС), данные о состоянии моего (его,ее) здоровья, заболеваниях, случаях обращения за медицинской помощью при условии, что их обработка осуществляется лицом, профессионально занимающимся медицинской деятельностью и обязанным сохранять врачебную тайну.
    Предоставляю Оператору осуществлять все действия с моими (его, ее) персональными данными, включая сбор, систематизацию, накопление, хранение, обновление, изменение, обезличивание, использование, блокирование, уничтожение.
    В процессе оказания мне (ему, ей) Оператором медицинской помощи я представляю право медицинским работникам передавать мои (его, ее) персональные данные, содержащие сведения, составляющие врачебную тайну, другим должностным лицам Оператора, в интересах моего (его, ее) обследования и лечения. Оператор имеет право во исполнение соих обязательств по работе в системе обязательного медицинского страхования на обмен (прием и передачу) моих (его,ее) персональных данных со страховой медицинской организацией и Территориальным фондом обязательного медицинского страхования с использованием цифровых носителей и по каналам связи с соблюдением мер, обеспечивающих их защиту от несанкционированного доступа, при условии, что их прием и обработка будут осуществляться лицом, обязанным сохранять профессиональную тайну.
    Настоящее согласие дано мной и действует бессрочно.
    Я оставляю за собой право отозвать мое согласие, которое может быть направлено в письменном виде в адрес Оператора.
    </p><br/>
    <p>Подпись субъекта персональных данных (с расшифровкой):</p>

    ___________________________/{{ patient.full_name}}/
</div>
</template>
<script>
export default {
    props: ['patient','date'],
    computed: {
        passport() {
            if (this.$isJSON(this.patient.passport)) {
                let p = JSON.parse(this.patient.passport)
                return 'серия ' + p.series + ' номер ' + p.number + ' выдан ' + p.issued + ' ' + p.date
            }else{
                return this.patient.passport.length>0 ? this.patient.passport : 'не указаны'
            }
      }
    }
}
</script>
<style scoped>
    @media not print { body {display:block} }
</style>