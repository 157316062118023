<template>
<VaCard stripe stripe-color="success">
    <VaCardTitle>
      <VaButton
      icon="arrow_back_ios_new"
      color="primary"
      icon-color="#333"
      class="mr-3"
      preset="secondary"
      hover-behavior="opacity"
      :hover-opacity="0.4"
      @click="$router.back()"
    />
    {{ $route.name == 'patientEdit' ? 'Редактирование' : 'Создание' }} карточки пациента
    </VaCardTitle>

    <VaCardContent style="text-align:left">
      
      <VaSkeletonGroup v-if="isLoading">
        <VaSkeleton variant="text" class="va-h3" :lines=30 height="16em" lineGap="1em" style="width:600px"/>
        <VaSkeleton variant="rounded" class="va-button mr-3 mb-2" height="2em" style="max-width:100px"/>
      </VaSkeletonGroup>

        <VaForm ref="patientForm" v-show="!isLoading" class="items-baseline gap-6" style="display: flex; flex-direction: column; max-width:600px">
          <VaInput class="mb-3"
            v-model="form.lastname"
            :rules="[(value) => (value && value.length > 0) || 'First name is required']"
            label="Фамилия"
          />

          <VaInput class="mb-3"
            v-model="form.firstname"
            :rules="[(value) => (value && value.length > 0) || 'First name is required']"
            label="Имя"
          />
        
          <VaInput class="mb-3"
            v-model="form.patronymic"
            :rules="[(value) => (value && value.length > 0) || 'Last name is required']"
            label="Отчество"
          />

          <label class="va-input-label" style="color: var(--va-primary);">Пол</label>
          <VaRadio class="mb-3"
            v-model="form.sex"
            :rules="[
              (v) => v !== null || 'Необходимо выбрать пол',
            ]"
            :options="[
              {
                text: 'Мужской',
                value: 'm',
              },
              {
                text: 'Женский',
                value: 'f',
              },
            ]"
            value-by="value"
          />

          <VaInput class="mb-3"
            v-model="form.nation"
            :rules="[(value) => (value && value.length > 0) || 'Last name is required']"
            label="Национальность"
          />

          <VaInput class="mb-3"
            v-model="form.education"
            :rules="[(value) => (value && value.length > 0) || 'Last name is required']"
            label="Образование"
          />
          <VaInput class="mb-3"
              style="width:120px"
              v-model="bd"
              label="Дата рождения"
              :mask="{date: true, datePattern: ['d', 'm', 'Y'], delimiter: '.', onValueChanged: function(e) {birthdate = e.target.value}}"
            />
          <label class="va-input-label" style="color: var(--va-primary);">Паспортные данные</label>
          <VaAlert color="#eeeeee70" style="width:100%">
            <div class="pa-2" style="background-color:#ffffff" v-if="!$isJSON(form.passport) && mode==='edit'">
              <p class="mb-2">Cейчас паспортные данные в старом формате:</p>
              <p class="mb-2">{{ form.passport.length > 0 ? form.passport : 'не указаны' }}</p>
            </div>
            <VaInput class="mb-3 mr-2"
              style="max-width:60px"
              v-model="pt.series"
              label="Серия"
              :mask="{numericOnly: true, blocks: [2,2], delimiters: [' '], onValueChanged: function(e) {renderPassport(e,'series')}}"
            />
            <VaInput class="mb-3 mr-2"
              style="max-width:70px"
              v-model="pt.number"
              label="Номер"
              :mask="{blocks: [6], onValueChanged: function(e) {renderPassport(e,'number')}}"
            />
            <VaInput class="mb-3"
              style="width:100%"
              v-model="pt.issued"
              label="Кем выдан"
              @input="renderPassport(pt.issued,'issued')"
            />
            <VaInput class="mb-3"
              style="width:100px"
              v-model="pt.date"
              label="Дата выдачи"
              :mask="{date: true, datePattern: ['d', 'm', 'Y'], delimiter: '.', onValueChanged: function(e) {renderPassport(e,'date')}}"
            />
          </VaAlert>
          <label class="va-input-label" style="color: var(--va-primary);">Семейное положение</label>
          <VaSelect
            class="mb-3"
            v-model="form.marriage"
            :options="marriage"
            :text-by="(option) => option.value"
            :value-by="(option) => option.value"
          />
          <VaInput class="mb-3"
            v-model="form.polis_issuer"
            label="Страховая организация"
          />
          
          <VaInput class="mb-3"
            v-model="form.polis"
            label="Полис ОМС"
            placeholder="#### #### #### ####"
            :mask="{blocks: [4,4,4,4]}"
          />
        
          <VaInput class="mb-3"
            v-model="form.snils"
            label="СНИЛС"
            placeholder="###-###-### ##"
            :mask="{blocks: [3,3,3,2], delimiters: ['-','-',' ']}"
          />
        
          <VaInput class="mb-3"
            v-model="form.address"
            :rules="[(value) => (value && value.length > 0) || 'Введите адрес']"
            label="Адрес"
          />
          
          <VaSwitch
            v-model="form.villager"
            label="Сельский житель"
            size="small"
            class="mb-3"
          />
        
          <VaInput class="mb-3"
            v-model="form.phone"
            :rules="[(value) => (value && value.length > 0) || 'Last name is required']"
            label="Телефон"
            placeholder="#(###)###-##-##"
            :mask="{blocks: [1,3,3,2,2], delimiters: ['(',')','-','-']}"
          />

          <VaSwitch
            v-model="disabilityStatus"
            label="Есть инвалидность"
            size="small"
            class="mb-3"
          />

          <VaInput class="mb-3"
            :disabled="!disabilityStatus"
            v-model="form.disability"
            :rules="[(value) => (value && value.length > 0) || 'Last name is required']"
            label="Группа инвалидности"
            :placeholder="disabilitiNote"
          />
        
          <VaInput class="mb-3"
            v-model="form.works"
            :rules="[(value) => (value && value.length > 0) || 'Last name is required']"
            label="Место работы"
          />
        
          <VaInput class="mb-3"
            v-model="form.artpressure"
            :rules="[(value) => (value && value.length > 0) || 'Last name is required']"
            label="Рабочее АД"
          />

          <VaInput class="mb-3"
            v-model="form.pulse"
            :rules="[(value) => (value && value.length > 0) || 'Last name is required']"
            label="Пульс, уд. в мин."
            mask="numeral"
          />

          <VaInput class="mb-3"
            v-model="form.spo2"
            :rules="[(value) => (value && value.length > 0) || 'Last name is required']"
            label="Сатурация, %"
            mask="numeral"
          />

          <VaInput class="mb-3"
            v-model="form.temp"
            :rules="[(value) => (value && value.length > 0) || 'Last name is required']"
            label="Температура тела, °C"
            mask="numeral"
          />
        
          <VaInput class="mb-3"
            v-model="form.height"
            :rules="[(value) => (value && value.length > 0) || 'Last name is required']"
            label="Рост, см"
            mask="numeral"
          />

          <VaInput class="mb-3"
            v-model="form.weight"
            :rules="[(value) => (value && value.length > 0) || 'Last name is required']"
            label="Вес, кг"
            mask="numeral"
          />
          
          <label class="va-input-label" style="color: var(--va-primary);">Группа крови</label>
          <VaSelect
            v-model="form.bloodtype"
            :options="bloodTypes"
            :text-by="(option) => option.text"
            :value-by="(option) => option.value"
            placeholder="Выберите группу крови"
          />
        
          <label class="va-input-label" style="color: var(--va-primary);">Резус-фактор</label>
          <VaSelect
            class="mb-3"
            v-model="form.rhfactor"
            :options="rhfactor"
            :text-by="(option) => option.text"
            :value-by="(option) => option.value"
            placeholder="Выберите резус-фактор"
          />

          <VaSwitch
            v-model="diagnosisExists"
            label="Указать диагноз при поступлении"
            size="small"
            class="mb-3"
          />

          <VaInput class="mb-3"
            :disabled="!diagnosisExists"
            v-model="form.diagnosis_first"
            label="Диагноз при поступлении"
          />

        </VaForm>

        <VaButton class="mt-3" v-show="!isLoading && !patientId" @click="addPatient()">
            Добавить
          </VaButton>

          <VaButton class="mt-3" v-show="!isLoading && patientId" @click="patchPatient()">
            Сохранить
          </VaButton>

        </VaCardContent>
  </VaCard>
</template>
<script setup>
  import { formatDate } from '@/common/rcfx.js'
</script>
<script>
import axiosInstance from "../services/api";
import EventBus from "../common/EventBus";
import { RouterLink } from 'vue-router'
import { useForm } from 'vuestic-ui'

export default {
    components: { RouterLink },
    props: {
      patientId: String,
    },
    data() {
        return {
            validate: useForm('patientForm').validate, 
            isValid: useForm('patientForm').isValid,
            diagnosisExists: false,
            bloodTypes: [
              {value: "1", text: "0(I)"},
              {value: "2", text: "A(II)"},
              {value: "3", text: "B(III)"},
              {value: "4", text: "AB(IV)"},
            ],
            rhfactor: [
              {value: "+", text: "Rh(+)"},
              {value: "-", text: "Rh(-)"},
            ],
            marriage: [
              {value: "состоит в зарегистрированном браке" },
              {value: "не состоит в зарегистрированном браке"},
              {value: "вдова/вдовец"},
              {value: "неизвестно"},
            ],
            mode: this.$route.name == 'patientEdit' ? 'edit' : 'add',
            endYear: new Date().getFullYear(),
            startYear: 1900,
            weekdayNames:[ "ВС", "ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ" ],
            monthNames:[ "Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь" ],
            disabilityStatus: false,
            disabilitiNote:'',
            birthdate: null,
            bd: null,
            form: {
              lastname:'',
              firstname:'',
              patronymic:'',
              birthdate: null,
              passport:'',
              polis_issuer:'',
              polis:'',
              address:'',
              villager:false,
              phone:'',
              snils:'',
              sex:'',
              disability:'Нет',
              works:'',
              height:null,
              weight:null,
              artpressure:'',
              pulse:'',
              spo2:'',
              temp:'',
              bloodtype:'',
              rhfactor:'',
            },
            pt: {
              series: "",
              number: "",
              issued: "",
              date: "",
            },
            passport: {
              series: "",
              number: "",
              issued: "",
              date: "",
            },
            yearView: { type: "year", year: 2000 },
            addedSuccess: false,
            gotError: false,
            newPatientData: null,
            isLoading: true,
            isValid: true,
            patient: Array,
            patientData: Array,
        };
    },
    watch: {
      disabilityStatus: function(val) {
        if (val == true)
        {
          console.log('disability status is: ' + val)
          this.form.disability = this.form.disability!="нет" && this.form.disability!="Нет" ? this.form.disability : ""
          this.disabilitiNote = "Введите группу инвалидности"
        }else{
          console.log('disability status is: ' + val)
          this.form.disability = "Нет"
        }
      },
    },
    computed: {
    },
    methods: {
      renderPassport(e, field) {
          if (field === "series") {
            this.passport.series = e.target.value
          }
          if (field === "number") {
            this.passport.number = e.target.value
          }
          if (field === "issued") {
            this.passport.issued = e
          }
          if (field === "date") {
            this.passport.date = e.target.value
          }
      },
      validateBirthday(value) {
        if (value==null) {
          return 'Field is required'
        }
      },
      getPatient(id) {
        console.log('id is ' + id)
        axiosInstance
            .get(`patients/` + id + '/')
            .then(response => {
              this.form = response.data;
              console.log(this.form.birthdate)
              // this.bd = formatDate(this.form.birthdate, "ru") 
              console.log('asdasdasdasdasds'+this.form.birthdate)
              this.bd = this.form.birthdate
              if (this.form.disability!='Нет' && this.form.disability!='нет')
              {
                this.disabilityStatus=true
              }
              // this.pt = $isJSON(this.form.passport) ? JSON.parse(this.form.passport) : {}
              // this.passport = $isJSON(this.form.passport) ? JSON.parse(this.form.passport) : {}
              this.pt = JSON.parse(this.form.passport)
              this.passport = JSON.parse(this.form.passport)
        })
            .catch(error => {
            console.log('Axios Request Failed: ' + error);
            this.gotError = true;
        })
            .finally(() => {
              console.log('One patient: Request successfully completed')
              // console.log(JSON.stringify(this.form))
              this.isLoading = false
        });
      },
      patchPatient() {
        console.log('Trying to edit a patient')
        // console.log(this.birthdate)
        // console.log(this.form.birthdate)
        delete this.form.id
        delete this.form.registered
        delete this.form.updated
        this.form.passport = JSON.stringify(this.passport)

        // var d = new Date(this.form.birthdate);
        // const dateString = d.getFullYear()+'-'+String(d.getMonth()).padStart(2, '0')+'-'+String(d.getDate()).padStart(2, '0')
        // this.form.birthdate = dateString
        if (this.birthdate != undefined && this.birthdate !=null && this.birthdate!='') {
          console.log('if: '+ this.birthdate)
          // if (this.form.birthdate == null) {
          //   this.form.birthdate = formatDate(this.birthdate,'us')  
          // }else{
          //   console.log(this.birthdate)
            var d = this.birthdate.split('.').reverse().join('-')
            this.form.birthdate = formatDate(new Date(d),'us')
          // }
        }else{
          console.log('else: '+ this.form.birthdate)
           this.form.birthdate = null
        }

        //this.form.birthdate = this.birthdate != undefined && this.birthdate != null ? formatDate(this.birthdate,'us') : null
        // const dateString = [d[2],d[1],d[0]]
        // this.form.birthdate = dateString.join('-')

        //console.log(this.form.birthdate + ' -> ' + dateString)
        //console.log(JSON.stringify(this.form))
        
        axiosInstance
            .patch('patients/' + this.patientId + "/", this.form)
            .then(response => {
              const message = 'Patched ok' + JSON.stringify(response)
          //    console.log(message);
              this.newPatientData = response.data
              this.$vaToast.init({ message: 'Карточка пациента обновлена', color: 'success', position: 'bottom-right' })
              // this.$router.replace('/patients/' + this.newPatientData.id)
              this.$router.back()

        })
            .catch(error => {
            //console.log('Axios Request Failed: ' + JSON.stringify(error))
            this.$vaToast.init({ message: 'Ошибка изменений', color: 'warning', position: 'bottom-right'})
        })
            .finally(() => {
              this.isLoading = false
              
        });
      },
      addPatient() {
        console.log('Trying to edit a patient')
        delete this.form.id
        delete this.form.registered
        delete this.form.updated
        this.form.passport = JSON.stringify(this.passport)
        if (this.birthdate == null) {
          this.form.birthdate = null
        }else{
          // var d = new Date(this.birthdate);
          // const dateString = d.getFullYear()+'-'+String(d.getMonth()+1).padStart(2, '0')+'-'+String(d.getDate()).padStart(2, '0')
          // this.form.birthdate = dateString
          var d = this.birthdate.split('.').reverse().join('-')
          this.form.birthdate = formatDate(new Date(d),'us')
        }
        //console.log(dateString)
        console.log(JSON.stringify(this.form))
        
        axiosInstance
            // .patch('patients/' + (this.patientId ? this.patientId : '') + '/', "")
            .post('patients/', this.form)
            .then(response => {
              const message = 'Added ok' + JSON.stringify(response)
              console.log(message);
              this.newPatientData = response.data
              this.$vaToast.init({ message: 'Новая карточка пациента создана', color: 'success', position: 'bottom-right' })
              this.$router.replace('/patients/' + this.newPatientData.id)
        })
            .catch(error => {
              this.$vaToast.init({ message: 'Ошибка создания карточки, попробуйте позже', color: 'warning', position: 'bottom-right'})
              console.log('Axios Request Failed: ' + JSON.stringify(error))
        })
            .finally(() => {
              this.isLoading = false
              
        });
      },
    },
    mounted() {
      console.log('mode is: ' + this.mode)
      if (this.mode=="edit") { 
        this.getPatient(this.patientId)
      }
      else {
        this.isLoading=false
      }
    },
}

</script>
<style>
</style>
  