<template>
      <div class="print profile">
        <p style="text-align:right">
          ООО "Клиника профессора"
        </p>
        <p class="bold center header">
        ОТЧЕТ ОБ ОПЕРАЦИИ от {{ formatDate(new Date, "ru") }}
        </p>
        <b>ФИО пациента:</b> {{ patient.full_name ? patient.full_name : "не указаны" }}<br/>
        <b>Возраст:</b> {{ patient.birthdate ? patientAge : "не указан" }} ({{ patient.birthdate }})<br/>
        <b>Дата операции:</b> {{ operation.operationStart.split(' ')[0] }}<br/>
        <b>Оперирующий хирург:</b> {{ operation.operator_full_name }}<br/><br/>
        <table class="rc-table" width="100%">
          <tr>
            <th>
              Место для наклеивания стикеров
            </th>
          </tr>
          <tr>
            <td style="height:670px">
            </td>
          </tr>
        </table>
        <br/>
        Прошу после получения отчета:<br/>
          <input type="checkbox" /> Пополнить склад (доставить использованные компоненты)<br/>
          <input type="checkbox" /> Забрать склад (исплантаты и инструменты)<br/>
          <input type="checkbox" /> Связаться по телефону<br/>
        <br/>
        Врач:  ___________________ {{ operation.operator_full_name }}
        </div>
</template>
<script setup>
    import { formatDate } from '@/common/rcfx.js'
</script>
<script>
export default {
    data() {
      return {
        asd: true,
      }
    },
    computed: {
      patient() {
        return this.$store.state.app.patient
      },
      operation() {
        return this.$store.state.app.operation
      },
      patientAge() {
        var birthdate = new Date(this.patient.birthdate.split('.').reverse().join('-'))
        var today = new Date()
        return Math.floor((today-birthdate)/3600000/24/365)
      },
    },
}
</script>