<template>
  <VaCard stripe stripe-color="primary">
    <VaCardTitle>
      Пациенты
    </VaCardTitle>
      <VaCardContent style="text-align:left">
      <div class="va-text-right">
      <VaButton icon-right="person_add" class="mb-3" preset="primary" @click="$router.push('/patients/add')">
        Добавить
      </VaButton>
    </div>
      <VaInput clearableIcon="true" background="#ffffff" v-model.trim="query" @keyup.esc="resetSearch" class="mb-2 md12" style="width:100%;" aria-autocomplete="off" placeholder="Найти пациента">
        <template #prependInner>
          <VaIcon name="person_search" color="secondary"/>
        </template>
      </VaInput>
      <VaAlert v-if="tooShortQuery"
            color="info"
            border="left"
            class="mb-6">
            Для поиска небходимо минимум 3 символа
      </VaAlert>
      <div class="va-table-responsive" v-if="patients.length>0">
        
        <VaDataTable
        :columns="columns" 
        :items="patients" 
        :loading="isTableLoading"
        striped 
        clickable
        @row:click="showPatient"
        />
      </div>
      
      <p v-else>Пациентов по запросу <b>{{ query }}</b> не найдено</p>
      <p class="mt-2" v-show="!isTableLoading">Всего пациентов в <span v-if="query.length<3">базе:</span><span v-else>выборке:</span> {{ patientsCount }}</p>
      <VaSkeleton v-show="isTableLoading" tag="p" variant="text" class="mt-2" lastLineWidth="25%" :lines="1"/>
      
    </VaCardContent>
    <VaPagination
    v-if="totalPages > 1"
    v-model="currentPage"
    :pages="totalPages"
    :visible-pages="4"
    buttons-preset="secondary"
    class="justify-center sm:justify-start mb-4"
  />
  </VaCard>

</template>
<script>
import axiosInstance from "../services/api";
import debounce from 'lodash.debounce'

export default {
  props: {
      page: Number,
    },
  data() {
        return {
            columns: [
              { key: "id", width: 1 },
              { key: "full_name", label: "ФИО пациента", width: 25 },
              { key: "birthdate", label: "Дата рождения", width: 24 },
            ],
            currentPage:this.$store.state.app.patients.page,
            showSidebar:this.$store.state.app.sidebarStatus,  
            patients: [],
            prev: String,
            next: String,
            patientsCount: Number,
            isTableLoading: true,
            query: '',
            tooShortQuery: false,
        }
    },
  watch: {      
    showSidebar: function(value){
      this.$store.dispatch('app/toggleSidebar',value)
    },
    currentPage: function(newValue){
      this.$store.dispatch('app/changePage',newValue)
      this.getPatients(newValue)
    },
    query: function (newValue) {
      if (newValue.length > 2) {
        this.tooShortQuery = false
        this.debouncedSearch()
      }else{
        this.tooShortQuery = (newValue.length>0) ? true : false
        this.getPatients()
      }
      console.log('query is modified')
    },
  },
  computed: {
    totalPages() {
      return Math.ceil(this.patientsCount / 10)
    },
  },
  methods: {
    showPatient(event) {
      this.$router.push('/patients/' + event.item.id)
    },
    resetSearch() {
      this.query=""
      this.getPatients()
    },
    // Прямой запрос без UserService

    getPatients(page=1) {
      this.isTableLoading = true
      axiosInstance
        .get('/patients/' +'?page_size=10&page=' + page)
        .then((response) => {
            this.patientsCount = response.data.count
            this.patients = response.data.results
        },
        (error) => {
          //console.log(JSON.stringify(error))
          console.log('Patients request failed')
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          // if (error.response && error.response.status === 400) {
          //   EventBus.dispatch("logout");
          // }
        }
        )
        .finally(() => {
          this.isTableLoading = false
        })
    },
    searchPatient() {
      this.isTableLoading = true
      console.log('*****************Search executed')
            console.log('THE SEARCH QUERY IS: ' + this.query)
            axiosInstance
              .get('patients/?search=' + this.query)
              .then(response => {
                console.log(JSON.stringify(response.data))
                this.patientsCount = response.data.results.length
                this.patients = response.data.results
              })
              .catch(error => {
                console.log('Search: Axios Request Failed: ' + error);
                this.gotError = true;
              })
              .finally(() => (console.log('Search: Request successfully completed')))
                this.isTableLoading = false
    },    
    debouncedSearch: debounce(function (query) {
              this.searchPatient(query);
            }, 600),
  },
  created() {
    !this.$store.state.auth.status.loggedIn && this.$router.push('/login')
  },
  mounted() {
    this.getPatients(this.currentPage)
  },
}

</script>

<style>
</style>
