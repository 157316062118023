<template>
<VaCard stripe stripe-color="success">
    <VaCardTitle>
    Персонал
    </VaCardTitle>

    <VaCardContent style="text-align:left">

        <VaForm v-if="iamBoss || iamSuperUser" ref="userForm" class="items-baseline gap-6 mb-4" style="display: flex; flex-direction: column; max-width:100%">

          <VaInput class="mb-3"
            requiredMark
            v-model="form.full_name"
            :rules="[(value) => (value && value.length > 0) || 'Нужно ввести ФИО сотрудника']"
            label="ФИО сотрудника"
            placeholder="Например - Иванов Иван Иванович"
            style="max-width:300px"
          />

          <VaInput class="mb-3"
            v-model="form.email"
            label="Email сотрудника"
            placeholder="ivanov.ivan@mail.ru"
            style="max-width:300px"
          />

          <label class="va-input-label" style="color: var(--va-primary);">Группа <span class="va-input-label__required-mark">*</span></label>
          <VaSelect class="mb-3"
            :rules="[(value) => (value && value.length > 0) || 'Нужно выбрать хотя бы одну группу']"
            v-model="form.groups"
            :options="groups"
            :value-by="(option) => option.id"
            :text-by="(option) => option.name"
            multiple
            style="max-width:300px"
          />

          <VaInput class="mb-3"
            :rules="[(value) => (value && value.length > 0) || 'Нужно ввести логин сотрудника']"
            requiredMark
            autocomplete="false"
            v-model="form.username"
            label="Имя пользователя"
            placeholder="Например - ivanov"
            style="max-width:300px"
          />

          <VaInput class="mb-3"
            :rules="[(value) => (value && value.length > 0) || 'Нужно придумать пароль']"
            requiredMark
            v-model="form.password"
            :type="isPasswordVisible ? 'text' : 'password'"
            label="Пароль"
            @click-append-inner="isPasswordVisible = !isPasswordVisible"
            style="max-width:300px"
          >
            <template #appendInner>
              <VaIcon
                :name="isPasswordVisible ? 'visibility_off' : 'visibility'"
                size="small"
                color="primary"
              />
            </template>
          </VaInput>

          <VaButton class="mt-3" preset="primary" v-if="templateId" style="max-width:190px" @click="patchTemplate">
            Сохранить изменения
          </VaButton>
          <VaButton class="mt-3" icon="person_add" preset="primary" v-else style="max-width:190px" @click="validate() && addUser()">
            Добавить
          </VaButton>
        </VaForm>

        <VaDataTable
          :columns="columns"
          sticky-header
          :items="staff"
          hoverable
        >
          <template #cell(full_name)="{ rowData }">
            <div class="rcflex row vcenter">
              <div>
              <img
                :src="rowData.photo ? rowData.photo : '/img/defaultavatar.jpg'"
                class="profile-avatar va-file-upload__field mr-3"
                style="width:64px;height:64px;"
              />
            </div><div>
            {{ rowData.full_name }}
          </div>
          </div>
          </template>
          <template #cell(groups)="{ rowData }">
            <div v-for="group in rowData.groups">
              {{ group.name }}
            </div>
          </template>
        </VaDataTable>

        </VaCardContent>
  </VaCard>

</template>
<script>
import axiosInstance from "../services/api";
import EventBus from "../common/EventBus";
import { RouterLink } from 'vue-router'
import { useForm } from 'vuestic-ui'

export default {
    components: { RouterLink },
    props: { 
      templateId: String,
    },
    data() {
        return {
          validate: useForm('userForm').validate, 
          isValid: useForm('userForm').isValid,
          resetForm: useForm('userForm').reset,
          columns: [
              //{ key: "id", width: 1 },
              { key: "full_name", label: "ФИО", width: "50%" },
              { key: "username", label: "Имя пользователя"},
              { key: "groups", label: "Группы", width: "30%" },
            ],
          staff:[],
          groups:[],
          group:'',
          type: '',
          addError: false,
          addSuccess: false,
            form: {
              full_name: '',
              groups: [],
              username: '',
              email: '',
              password: '',
            },
            isPasswordVisible: false,
            templates: [],
          };
    },
    computed: {
      iamBoss: function() {
        return this.$store.state.auth.status.loggedIn ? this.$store.state.app.account.profile.boss ? true : false : false
      },
      iamBoss: function() {
        return this.$store.state.auth.status.loggedIn ? this.$store.state.app.account.profile.is_superuser : false
      },
    },
    watch: {
      type: function(newValue) {
          console.log('type changed')
          this.form.type = this.type
    },
    },
    methods: {
      sortByGroup(staff) {
        this.staff.doctors = staff.filter(o=>o.groups.find(a=>a.name == 'Врачи'))
        this.staff.anSisters = staff.filter(o=>o.groups.find(a=>a.name == 'Анестезиологические медсестры'))
        this.staff.opSisters = staff.filter(o=>o.groups.find(a=>a.name == 'Операционные медсестры'))
        this.staff.anesthesists = staff.filter(o=>o.groups.find(a=>a.name == 'Анестезиологи'))
        this.staff.xraytechs = staff.filter(o=>o.groups.find(a=>a.name == 'Рентген-лаборанты'))
        this.staff.nurses = staff.filter(o=>o.groups.find(a=>a.name == 'Медсестры'))
      },
      sortStaff(array, key) {
        return array.sort((a, b) => (a[key] > b[key]) ? 1 : ((b[key] > a[key]) ? -1 : 0));
      },
      getStaff() {
        axiosInstance
            .get('/staff/')
            .then(response => {
              const result = response.data.results
              // console.log(JSON.stringify(result))
              this.staff = this.sortStaff(result, 'full_name')
              //this.sortStaff(response.data.results)
        })
            .catch(error => {
            this.gotError = true;
        })
      },
      getGroups() {
        axiosInstance
            .get('/groups/')
            .then(response => {
              this.groups = response.data.results
              
        })
            .catch(error => {
            console.log('Groups Request Failed: ' + error);
            this.gotError = true;
        })
            .finally(() => {
              this.isLoading = false
        });
      },
      addUser() {
        axiosInstance
            .post(`/staff/new`, this.form)
            .then(response => {
              this.resetForm()
              this.$vaToast.init({ message: 'Сотрудник добавлен', color: 'success', position: 'bottom-right' })
              this.getStaff()
              //this.$router.push({name: 'templates'})
        })
            .catch(error => {
              console.log('User add : error ' + error);
              this.$vaToast.init({ message: 'Ошибка при добавлении сотрудника', color: 'warning', position: 'bottom-right' })
        })
      },
      patchUser() {
            
            if (this.form.type == 0) {
                this.form.cure_plan = " "
              }else{
                this.form.recom = " "
              }

            axiosInstance
            .patch(`templates/` + this.templateId, this.form)
            .then(response => {
              this.addedSuccess = true
              this.form = response.data;
              this.$vaToast.init({ message: 'Сотрудник успешно изменен', color: 'success', position: 'bottom-right' })
            })
            .catch(error => {
              console.log('Patch user error: ' + error);
              this.addedError = true
              this.$vaToast.init({ message: 'Ошибка при изменении сотрудника', color: 'warning', position: 'bottom-right' })
        })
      },
    },
    created() {
      !this.$store.state.auth.status.loggedIn && this.$router.push('/login')
    },
    mounted() {
      this.getGroups()
      this.getStaff()
    },
}

</script>
<style>
</style>
  