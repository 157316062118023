<template>
  <div v-if="open===true" ref="printPage">
    <slot />
  </div>
</template>

<script>
function copyStyles(sourceDoc, targetDoc) {
  console.log(sourceDoc.styleSheets[66])
  // sourceDoc.styleSheets.forEach(styleSheet => {
  for (let styleSheet in sourceDoc.styleSheets) {
    console.log(JSON.stringify(sourceDoc.styleSheets))
    targetDoc.body.appendChild("<!-- TEST -->");
    if (styleSheet.cssRules) {
      // for <style> elements
      const newStyleEl = sourceDoc.createElement("style");

      // Array.from(styleSheet.cssRules).forEach(cssRule => {
        for (let cssRule in styleSheet.cssRules) {
        // write the text of each rule into the body of the style element
        newStyleEl.appendChild(sourceDoc.createTextNode(cssRule.cssText));
      }
      
      targetDoc.head.appendChild(newStyleEl);
    } else if (styleSheet.href) {
      // for <link> elements loading CSS from a URL
      const newLinkEl = sourceDoc.createElement("link");

      newLinkEl.rel = "stylesheet";
      newLinkEl.href = styleSheet.href;
      targetDoc.head.appendChild(newLinkEl);
    }
  }
}

export default {
  name: 'window-portal',
  model: {
    prop: 'open',
    event: 'close'
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    action: {
      type: String,
      default: "preview",
    }
  },
  data() {
    return {
      windowRef: null,
    }
  },
  watch: {
    open(newOpen) {
      if(newOpen) {
        // console.log('testing: '+newOpen)
        this.openPortal(this.action);
      }
      // } else {
      //   // console.log('testing: '+newOpen)
      //   this.closePortal();
      // }
    }
  },
  methods: {
    async print(page) {
      if (await this.$el.length >0) {
        page.document.body.appendChild(this.$el);
      }
      page.focus()
      if (await !page.print()) {
        this.closePortal()
      }
    },
    async printPortal() {
      this.windowRef = window.open("", "printWindow", "width=1200,height=800,left=" + (screen.availWidth / 2 - 600) + ",top=" + (screen.availHeight / 2 - 400) + ",toolbar=0,scrollbars=0,status=0");
       if (await this.$el) {
        // copyStyles(window.document, this.windowRef.document);
        this.windowRef.document.body.appendChild(this.$refs.printPage);
       }
      this.windowRef.document.close();
      this.windowRef.focus()
      if (await !this.windowRef.print()) {
        this.closePortal()
      }
    },
    previewPortal() {
      // copyStyles(window.document, this.windowRef.document);
      this.windowRef = window.open("", "", "width=600,height=400,left=200,top=50");
      this.windowRef.document.body.appendChild(this.$el);
      this.windowRef.addEventListener('beforeunload', this.closePortal);
    },
    openPortal(action) {
      if (action === "print") this.printPortal();
      if (action === "preview") this.previewPortal();
    },
    closePortal() {
      // console.log('closing')
      if(this.windowRef) {
        this.windowRef.close();
        this.windowRef = null;
      }
    },
  },
  mounted() {
    console.log('testing component')

    if(this.open) {
      if (this.action === "print") this.printPortal();
      if (this.action === "preview") this.previewPortal();
    }
  },
  beforeDestroy() {
    if (this.windowRef) {
      this.closePortal();
    }
  }
}
</script>