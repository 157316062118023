<template>
<div class="print">
    <p style="text-align:center;font-weight:bold;">Информированное добровольное согласие на анестезиологическое обеспечение медицинского вмешательства</p>
    <p style="text-align:justify;text-indent: 2em;">Я,   {{ patient.full_name}},
    {{ patient.sex == "m" ? 'проживающий' : 'проживающая' }} по адресу: {{ patient.address}}, паспорт: {{ passport}},
    находясь на лечении (обследовании) в травматолого-ортопедическом отделении клиники, добровольно даю свое согласие на проведение мне (представляемому): СМА
    <p style="text-align:justify;text-indent: 2em;">Я {{ patient.sex == "m" ? 'поставил' : 'поставила' }} в известность врача обо всех проблемах связанных со здоровьем, в том числе об аллергических проявлениях или индивидуальной непереносимости лекарственных препаратов, пищи, бытовой химии, пыльцы цветов; обо всех перенесенных мною (представляемым) и известных мне травмах, операциях, заболеваниях, анестезиоло¬гических пособиях; об экологических и производственных факторах физической, химиче¬ской или биологической природы, воздействующих на меня (представляемого) во время жизнедеятельности, о принимаемых лекарственных средствах. Я {{ patient.sex == "m" ? 'сообщил' : 'сообщила' }} правдивые сведения о наследственности, употреблении алкоголя, наркотических и токси¬ческих средств;</p>
    <p style="text-align:justify;text-indent: 2em;">Я {{ patient.sex == "m" ? 'информирован' : 'информирована' }} о целях, характере и неблагоприятных эффектах анестезиологического обеспечения медицинского вмешательства, а также о том, что предстоит мне (представляемому) делать во время его проведения;</p>
    <p style="text-align:justify;text-indent: 2em;">Я {{ patient.sex == "m" ? 'предупрежден' : 'предупреждена' }} о факторах риска и понимаю, что проведение анестезиологического обеспечения медицинского вмешательства сопряжено с риском нарушений со стороны сердечно-сосудистой, нервной, дыхательной и других систем жизнедеятельности организма, непреднамеренного причинения вреда здоровью, и иного небла¬гоприятного исхода.</p>
    <p style="text-align:justify;text-indent: 2em;">Мне разъяснено и я осознаю, что во время анестезиологического пособия могут возникнуть непредвиденные обстоятельства и осложнения. В таком случае, я согласен (согласна) на то, что вид и тактика анестезиологического пособия может быть изменена врачами по их усмотрению.</p>
    <p style="text-align:justify;text-indent: 2em;">Мне была предоставлена возможность задать вопросы о степени риска и пользе анестезиологического обеспечения медицинского вмешательства, и врач дал понятные мне исчерпывающие ответы.</p>
    <p style="text-align:justify;text-indent: 2em;">Я {{ patient.sex == "m" ? 'ознакомлен' : 'ознакомлена' }} и {{ patient.sex == "m" ? 'согласен' : 'согласна' }} со всеми пунктами настоящего документа, положения которого мне разъяснены в доступной форме, мною поняты и добровольно даю свое согласие на проведение анестезиологического обеспечения медицинского вмешательства в предложенном объеме.</p>
    <p style="text-align:justify;text-indent: 2em;">О последствиях/возможных осложнениях при выполнении анестезии: постпункционные головные боли, коллапс, острый инфаркт миокарда, инсульты, невропатии, диспноэ, мозаичный спинальный блок, высокая спинальная блокада, синдром конского хвоста, ин¬фаркт спинного мозга, инфекционные осложнения, аллергические реакции вплоть до ана¬филактического шока, нарушения эректильной функции, ранение нерва, (указываются врачом) и связанных с ними рисках {{ patient.sex == "m" ? 'информирован' : 'информирована' }} врачом анестезиологом-реаниматологом: {{ anesthesist.full_name }}</p>
    <br/>

    Дата: {{ date }} г. _______________ Подпись пациента/законного представителя 
    <p style="text-align:right">{{ patient.full_name}}</p>
    <br/>			
    Расписался в моем присутствии:
    <br/>
    Врач: __________________________________________   Подпись	____________
    </p>
</div>
</template>
<script>
export default {
    props: ['patient', 'anesthesist','date'],
    computed: {
        passport() {
            if (this.$isJSON(this.patient.passport)) {
                let p = JSON.parse(this.patient.passport)
                return 'серия ' + p.series + ' номер ' + p.number + ' выдан ' + p.issued + ' ' + p.date
            }else{
                return this.patient.passport.length>0 ? this.patient.passport : 'не указаны'
            }
      }
    }
}
</script>