import api from "./api";
import TokenService from "./token.service";

class AuthService {
  login({ username, password }) {
    return api
      .post("token/", {
        username,
        password
      })
      .then((response) => {
        if (response.data.access) {
          TokenService.setUser(response.data);
        }
        return response.data;
      })
  }

  refreshAccessToken() {
    return api
      .post("token/refresh/", {
        refresh: TokenService.getLocalRefreshToken(),
      })
      .then((response) => {
        const accessToken = response.data.access
        TokenService.updateLocalAccessToken(accessToken)
        return response.data;
      })
      .catch((e) => {
        return e
      })
  }

  getProfile() {
    return api
      .post("profile/")
      .then((response) => {
        if (response.data) {
          return response.data;
          //userService.
        }
      })
  }

  logout() {
    TokenService.removeUser();
  }
  
  expired() {
    TokenService.removeOnlyTokens();
  }

  register({ username, email, password }) {
    return api.post("/signup", {
      username,
      email,
      password
    });
  }
}

export default new AuthService();
