<template>
      <div class="print profile">
        <p class="bold center">
            ООО «Клиника Профессора»<br/>
            ИНН 1435316805, КПП 143501001<br/>
            Адрес: 677010, г. Якутск, ул. Радужная, 69/12<br/>
            Телефон: +7 (914)2776990; электронный адрес: klinika.professora@mail.ru
        </p>
        <p class="bold center header">
          ЛИСТ НАЗНАЧЕНИЙ
        </p>
        <div class="rc-flex row">
          <div><b>Режим стационарный</b></div><div><b>ФИО пациента:</b> {{ patient.full_name ? patient.full_name : "не указаны" }}</div><div><b>Возраст:</b> {{ patient.birthdate ? patientAge : "не указан" }} ({{ patient.birthdate }})</div>
        </div>
        <div class="rc-flex col" style="margin-bottom:1rem">
          <div><b>Диетический стол №____</b></div>
          <div><b>Диагноз: </b>{{ hosvisit.diagnosis }}</div>
        </div>
        <table class="rc-table" width="100%">
          <tr>
            <th style="min-width: 15rem;">Препарат</th>
            <th v-for="day in totalDays">
              {{ day }}
            </th>
          </tr>
          <tr v-for="prescription in prescriptions">
            <td>{{ prescription.name }}, {{ prescription.dosage }}, {{ prescription.method }}, {{ prescription.freq }} рвд. {{ prescription.comment }}</td>
            <td v-for="day in totalDays">
            </td>
          </tr>
          <tr>
            <td class="bold center">Подпись лечащего врача</td>
            <td v-for="day in totalDays">
            </td>
          </tr>
          <tr>
            <td class="bold center">Подпись дежурной медсестры</td>
            <td v-for="day in totalDays">
            </td>
          </tr>
        </table>
        </div>
        <div class="pagebreak" />
</template>
<script setup>
    import { formatDate } from '@/common/rcfx.js'
</script>
<script>
export default {
    computed: {
      patient() {
        return this.$store.state.app.patient
      },
      medhistory() {
        return this.$store.state.app.medhistory
      },
      hosvisit() {
        return this.$store.state.app.hosvisit
      },
      prescriptions() {
        return this.$store.state.app.prescriptions
      },
      patientAge() {
        var birthdate = new Date(this.patient.birthdate.split('.').reverse().join('-'))
        var today = new Date()
        return Math.floor((today-birthdate)/3600000/24/365)
      },
      totalDays() {
        var start = this.prescriptions.sort((a,b) => new Date(a.dateStart.split(".").reverse().join("-")) - new Date(b.dateStart.split(".").reverse().join("-")))[0].dateStart
        var end = this.prescriptions.sort((a,b) => new Date(b.dateEnd.split(".").reverse().join("-")) - new Date(b.dateEnd.split(".").reverse().join("-")))[this.prescriptions.length - 1].dateEnd
        var totalDays = (new Date(end.split(".").reverse().join("-")) - new Date(start.split(".").reverse().join("-")) ) / (60 * 60 * 24 * 1000);
        var days = []
        var dateStart = new Date(start.split(".").reverse().join("-"))
        var dateEnd = new Date(end.split(".").reverse().join("-"))
        days.push(start)
        for (let i = 0; i < totalDays; i++) {
          days.push(formatDate(new Date(dateStart.setDate(dateStart.getDate()+1)), "ru"))
        }
        return days
      },
    },
  }
</script>