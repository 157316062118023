<template>
  <VaCard stripe stripe-color="success" v-if="!preopExists">
    <VaCardTitle>
      <VaButton
      icon="arrow_back_ios_new"
      color="primary"
      icon-color="#333"
      class="mr-3"
      preset="secondary"
      hover-behavior="opacity"
      :hover-opacity="0.4"
      @click="$router.back()"
    />
    Титульный лист
    </VaCardTitle>
  
    <VaCardContent>
      <VaAlert
        color="primary"
        border="left"
        class="mb-6"
        >
        Этот титульный лист будет прикреплен к истории болезни №{{ medhistoryId }}
      </VaAlert>
      
        <VaForm ref="titleForm" class="items-baseline gap-6" style="display: flex; flex-direction: column; max-width:100%">
          <VaInput class="mb-3"
            v-model="form.medcard"
            label="Номер медицинской карты"
            placeholder=""
          />

          <label class="va-input-label" style="color: var(--va-primary);">Транспортировка</label>
          <VaButtonToggle
            v-model="form.transporting"
            preset="secondary"
            border-color="secondary"
            :options="transportingOptions"
            class="mb-2"
          />

          <VaInput class="mb-3"
            v-model="form.relative_full_name"
            label="ФИО родственника"
            placeholder=""
          />

          <VaInput class="mb-3"
            v-model="form.relative_phone"
            label="Телефон родственника"
            placeholder=""
          />

          <VaInput class="mb-3"
            v-model="form.referal"
            label="Кем направлен больной"
            placeholder=""
          />

          <label class="va-input-label" style="color: var(--va-primary);">Исход заболевания</label>
          <VaButtonToggle
            v-model="form.cure_result"
            preset="secondary"
            border-color="secondary"
            :options="cureResultsOptions"
            class="mb-2"
          />

          <label class="va-input-label" style="color: var(--va-primary);">Другие виды лечения</label>
          <div class="rcflex row mb-3">
            <VaCheckbox
              v-model="cure_types"
              array-value="специальное хирургическое"
              label="Специальное хирургическое"
              class="mb-6"
            />
            <VaCheckbox
              v-model="cure_types"
              array-value="симптоматическое"
              label="Симптоматическое"
              class="mb-6"
            />
            <VaCheckbox
              v-model="cure_types"
              array-value="физиолечение"
              label="Физиолечение"
              class="mb-6"
            />
            <VaCheckbox
              v-model="cure_types"
              array-value="перевязки"
              label="Перевязки"
            />
            <VaCheckbox
              v-model="cure_types"
              array-value="паллиативное"
              label="Паллиативное"
            />
          </div>

          <VaTextarea
            class="mb-4"
            v-model="form.comment"
            label="Комментарий"
            placeholder=""
            autosize
            :minRows=5
            :max-rows=10
          />

        <div class="rcflex mt-4">
          <VaButton
            preset="primary"
            color="secondary"
            icon="close"
            class="mr-2"
            @click="$router.back()"
          >
            Отмена
          </VaButton>
          <VaButton v-if="title.id && title != null"
            style="max-width:230px"
            class="" 
            @click="patchTitle"
            preset="primary"
            icon-right="save"
          >
            Сохранить изменения
          </VaButton>
          <VaButton v-else
            style="max-width:200px"
            class="" 
            @click="addTitle"
            preset="primary"
            icon-right="system_update_alt"
          >
            Сохранить 
          </VaButton>
        </div>          
      </VaForm>
    </VaCardContent>
  </VaCard>

</template>
<script>
import axiosInstance from "../services/api";
import EventBus from "../common/EventBus";
import PatientProfile from '@/components/PatientProfile';

export default {
    components: { PatientProfile },
    data() {
        return {
            transportingOptions: [
              { label: "На кресле", value: "на кресле" },
              { label: "На кровати",value: "на кровати" },
              { label: "Может ходить",value: "может ходить" },
            ],
            cureResultsOptions: [
              { label: "Улучшение", value: "улучшение" },
              { label: "Без перемен",value: "без перемен" },
              { label: "Ухудшение",value: "ухудшение" },
            ],
            showFillButton: true,
            joint: false,
            cure_types: [],
            form: {
              medcard: '',
              transporting: '',
              relative_full_name: '',
              relative_phone: '',
              referal: '',
              cure_result: '',
              cure_types: '',
              patient: this.patientId,
              medhistory: this.medhistoryId,
              user: this.userId,
            },
            medhistory: {},
            patient: {},
            patientReady: false,
          };
    },
    computed: {
      medhistoryId() {
        return this.$store.state.app.medhistory ? this.$store.state.app.medhistory.id: null
      },
      patientId() {
        return this.$store.state.app.patient ? this.$store.state.app.patient.id : null
      },
      userId() {
        return this.$store.state.app.account.profile ? this.$store.state.app.account.profile.id : null
      },
      title() {
        return this.$store.state.app.title ? this.$store.state.app.title : null
      }
    },
    methods: {
      addTitle() {
        this.form.user = this.userId
        this.form.medhistory = this.medhistoryId
        this.form.patient = this.patientId
        this.form.cure_types = JSON.stringify(this.cure_types)
        axiosInstance
        .post(`/title`, this.form)
        .then(response => {
          this.$vaToast.init({ message: 'Титульный лист добавлен', color: 'success', position: 'bottom-right' })
          this.$router.replace('/medhistory/' + this.medhistoryId)
        })
        .catch(error => {
          console.log('Title: error ' + error);
        })
      },
      patchTitle() {
        // delete this.form.id
        delete this.form.updated
        delete this.form.created
        delete this.form.user
        delete this.form.medhistory
        delete this.form.patient
        this.form.cure_types = JSON.stringify(this.cure_types)
        axiosInstance
        .patch(`/title/`+this.title.id, this.form)
        .then(response => {
          this.$vaToast.init({ message: 'Титульный лист изменен', color: 'success', position: 'bottom-right' })
          this.$router.replace('/medhistory/' + response.data.medhistory)
        })
        .catch(error => {
          console.log('Title: patch error ' + error);
        })
      },
    },
    created() {
      if (!this.$store.state.app.patient.id) this.$router.back()
    },
    mounted() {
      if (this.$route.name === "titleEdit") {
        this.form = this.title
        if (this.form.cure_types) this.cure_types = JSON.parse(this.form.cure_types)
      }
    }
}
</script>