class TokenService {
  getLocalRefreshToken() {
    return localStorage.getItem("refresh")
  }

  getLocalAccessToken() {
    return localStorage.getItem("access")
  }

  updateLocalAccessToken(token) {
    localStorage.setItem("access", token)
  }

  getUser() {
    return localStorage.getItem("access")
  }

  setUser(tokens) {
    localStorage.setItem("access", tokens.access);
    localStorage.setItem("refresh", tokens.refresh);
  }

  removeUser() {
    localStorage.removeItem("userprofile");
    localStorage.removeItem("access");
    localStorage.removeItem("refresh");
    localStorage.removeItem("pushToken");
    localStorage.removeItem("pushTokenId");
  }

  removeOnlyTokens() {
    localStorage.removeItem("userprofile");
    localStorage.removeItem("access");
    localStorage.removeItem("refresh");
  }
}

export default new TokenService();
