<template>
<VaCard stripe stripe-color="success">
    <VaCardTitle>
      <VaButton
      icon="arrow_back_ios_new"
      color="primary"
      icon-color="#333"
      class="mr-3"
      preset="secondary"
      hover-behavior="opacity"
      :hover-opacity="0.4"
      @click="$router.back()"
    />
    Осмотр анестезиолога
    </VaCardTitle>

    <VaCardContent style="text-align:left">
        <VaForm v-show="anexamReady" ref="examForm" class="items-baseline gap-6" style="display: flex; flex-direction: column; max-width:100%">
          <label class="va-input-label" style="color: var(--va-primary);">Пациент</label>
          <VaCollapse v-if="!patientLoading"
            icon="person"
            v-model="toggleCard"
            class="min-w-96 mb-3 rc-text-m"
            :header="patient.full_name + (patient.birthdate ? ', '+patient.birthdate : '')"
          >
            <div class="va-table-responsive">
              <PatientProfile :patient="patient" v-if="patient.passport" />
            </div>
          </VaCollapse>
          <label class="va-input-label" style="color: var(--va-primary);">Отделение</label>
          <VaSelect 
            class="mb-3"
            v-model="form.depName"
            :rules="[(v) => v !== null || 'Укажите отделение']"
            :options="[
              {
                text: 'ортопедическое',
              },
              {
                text: 'другое',
              },
            ]"
            value-by="text"
          />

          <label class="va-input-label" style="color: var(--va-primary);">Признак оплаты</label>
          <VaRadio 
            class="mb-3"
            v-model="form.paid"
            :options="[
              {
                text: 'ОМС',
                value: false,
              },
              {
                text: 'Платный',
                value: true,
              },
            ]"
            value-by="value"
          />

          <label class="va-input-label" style="color: var(--va-primary);">Вид операции</label>
          <VaRadio
            class="mb-3"
            v-model="form.urgence"
            :rules="[(v) => v !== null || 'Укажите вид операции']"
            :options="[
              {
                text: 'плановая',
              },
              {
                text: 'экстренная',
              },
              {
                text: 'экстренно-отсроченная',
              },
              {
                text: 'по жизненным показаниям',
              },
            ]"
            value-by="text"
          />

          <VaInput class="mb-3"
            :rules="[(v) => v !== null || 'Заполните это поле']"
            type="text"
            v-model="form.scope"
            label="Планируемый объем оперативного вмешательства"
          />

          <VaInput class="mb-3"
            :rules="[(v) => v !== null || 'Запоните это поле']"
            type="text"
            v-model="form.diagnosis"
            label="Диагноз основной"
          />

          <VaTextarea class="mb-3"
            :rules="[(v) => v !== null || 'Заполните это поле']"
            v-model="form.compliant"
            label="Жалобы"
            autosize
            :minRows=2
            :max-rows=6
          />

          <VaTextarea class="mb-3"
            :rules="[(v) => v !== null || 'Заполните это поле']"
            v-model="form.past_illness"
            label="Перенесенные заболевания"
            autosize
            :minRows=2
            :max-rows=6
          />

          <VaInput
            :rules="[(v) => v !== null || 'Запоните это поле']"
            class="mb-3"
            type="text"
            v-model="form.diagnosis_secondary"
            label="Сопутствующие заболевания"
          />

          <VaTextarea class="mb-3"
            :rules="[(v) => v !== null || 'Запоните это поле']"
            v-model="form.status_alergo"
            label="Аллергические реакции"
            autosize
            :minRows=2
            :max-rows=3
          />

          <VaInput
            :rules="[(v) => v !== null || 'Запоните это поле']"
            class="mb-3"
            type="text"
            v-model="form.hemotransfusion"
            label="Гемотрансфузии"
          />

          <VaTextarea class="mb-3"
            :rules="[(v) => v !== null || 'Запоните это поле']"
            v-model="form.past_operations"
            label="Перенесенные операции, вид анестезиологического пособия, особенности"
            autosize
            :minRows=2
            :max-rows=3
          />

          <VaTextarea class="mb-3"
            :rules="[(v) => v !== null || 'Запоните это поле']"
            v-model="form.status_objective"
            label="Объективное состояние больного"
            placeholder=""
            autosize
            :minRows=2
            :max-rows=6
          />

          <VaInput
            :rules="[(v) => v !== null || 'Запоните это поле']"
            class="mb-3"
            type="text"
            v-model="form.position"
            label="Положение"
          />

          <VaInput
            :rules="[(v) => v !== null || 'Запоните это поле']"
            class="mb-3"
            type="text"
            v-model="form.body_type"
            label="Телосложение: рост, вес, ИМТ"
          />

          <label class="va-input-label" style="color: var(--va-primary);">Сознание</label>
          <VaRadio
            class="mb-3"
            v-model="form.state"
            :rules="[(v) => v !== null || 'Укажите статус сознания']"
            :options="[
              {
                text: 'ясное',
              },
              {
                text: 'оглушение',
              },
              {
                text: 'сопор',
              },
              {
                text: 'кома I ст.',
              },
              {
                text: 'кома II ст.',
              },
              {
                text: 'кома III ст.',
              },
              {
                text: 'кома IV ст.',
              },
            ]"
            value-by="text"
          />

          <label class="va-input-label" style="color: var(--va-primary);">Зрачки</label>
          <VaRadio
            class="mb-3"
            v-model="form.pupils"
            :rules="[(v) => v !== null || 'Запоните это поле']"
            :options="[
              {
                text: 'сужены',
              },
              {
                text: 'расширены',
              },
              {
                text: 'анизокария',
              },
            ]"
            value-by="text"
          />

          <label class="va-input-label" style="color: var(--va-primary);">Фотореакция</label>
          <VaRadio
            class="mb-3"
            v-model="form.photo_reaction"
            :rules="[(v) => v !== null || 'Запоните это поле']"
            :options="[
              {
                text: 'живая',
              },
              {
                text: 'вялая',
              },
              {
                text: 'отсутствует',
              },
            ]"
            value-by="text"
          />
          
          <VaTextarea class="mb-3"
            v-model="form.skin"
            :rules="[(v) => v !== null || 'Запоните это поле']"
            label="Кожные покровы"
            placeholder=""
            autosize
            :minRows=2
            :max-rows=6
          />

          <label class="va-input-label" style="color: var(--va-primary);">Отеки</label>
          <VaRadio
            class="mb-3"
            v-model="form.edema"
            :rules="[(v) => v !== null || 'Запоните это поле']"
            :options="[
              {
                text: 'нет',
              },
              {
                text: 'есть',
              },
            ]"
            value-by="text"
          />

          <VaTextarea class="mb-3"
            v-model="form.mouth"
            :rules="[(v) => v !== null || 'Запоните это поле']"
            label="Полость рта и шея"
            placeholder=""
            autosize
            :minRows=2
            :max-rows=6
          />

          <label class="va-input-label" style="color: var(--va-primary);">Носовое дыхание</label>
          <VaRadio
            class="mb-3"
            v-model="form.nasal"
            :rules="[(v) => v !== null || 'Запоните это поле']"
            :options="[
              {
                text: 'свободное',
              },
              {
                text: 'затрудненное',
              },
              {
                text: 'отсутствует',
              },
            ]"
            value-by="text"
          />

          <label class="va-input-label" style="color: var(--va-primary);">Характеристика дыхания</label>
          <VaRadio
            class="mb-3"
            v-model="form.breath"
            :rules="[(v) => v !== null || 'Запоните это поле']"
            :options="[
              {
                text: 'спонтанное',
              },
              {
                text: 'патологическое',
              },
            ]"
            value-by="text"
          />

          <VaInput
            :rules="[(v) => v !== null || 'Запоните это поле']"
            class="mb-3"
            type="text"
            v-model="form.av"
            label="ИВЛ"
          />

          <VaTextarea class="mb-3"
            v-model="form.chest"
            :rules="[(v) => v !== null || 'Запоните это поле']"
            label="Дыхание"
            placeholder=""
            autosize
            :minRows=2
            :max-rows=6
          />

          <VaInput
            :rules="[(v) => v !== null || 'Запоните это поле, разрешены только цифры']"
            class="mb-3"
            type="text"
            v-model="form.breath_freq"
            label="ЧДД"
          />

          <label class="va-input-label" style="color: var(--va-primary);">Мокрота</label>
          <VaRadio
            class="mb-3"
            v-model="form.sputum"
            :rules="[(v) => v !== null || 'Запоните это поле']"
            :options="[
              {
                text: 'да',
              },
              {
                text: 'нет',
              },
            ]"
            value-by="text"
          />

          <VaInput
            :rules="[(v) => v !== null || 'Запоните это поле']"
            class="mb-3"
            type="text"
            v-model="form.percussion"
            label="Перкуторно"
          />

          <label class="va-input-label" style="color: var(--va-primary);">Сердечные тоны</label>
          <VaRadio
            class="mb-3"
            v-model="form.heart_tones"
            :rules="[(v) => v !== null || 'Запоните это поле']"
            :options="[
              {
                text: 'ясные',
              },
              {
                text: 'приглушены',
              },
              {
                text: 'глухие',
              },
            ]"
            value-by="text"
          />

          <label class="va-input-label" style="color: var(--va-primary);">Сердечный ритм</label>
          <VaRadio
            class="mb-3"
            v-model="form.heart_beat"
            :rules="[(v) => v !== null || 'Запоните это поле']"
            :options="[
              {
                text: 'ритмичный',
              },
              {
                text: 'аритмичный',
              },
            ]"
            value-by="text"
          />

          <VaInput
            :rules="[(v) => v !== null || 'Запоните это поле']"
            class="mb-3"
            type="text"
            v-model="form.artpressure"
            label="Артериальное давление"
          />

          <VaInput
            :rules="[(v) => v !== null || 'Запоните это поле']"
            class="mb-3"
            type="text"
            v-model="form.heart_rpm"
            label="ЧСС и Сатурация"
          />

          <VaInput
            :rules="[(v) => v !== null || 'Запоните это поле']"
            class="mb-3"
            type="text"
            v-model="form.aditional"
            label="Особенности"
          />

          <VaInput
            :rules="[(v) => v !== null || 'Запоните это поле']"
            class="mb-3"
            type="text"
            v-model="form.tongue"
            label="Язык"
          />

          <VaInput
            :rules="[(v) => v !== null || 'Запоните это поле']"
            class="mb-3"
            type="text"
            v-model="form.smell"
            label="Запах изо рта"
          />

          <VaInput
            :rules="[(v) => v !== null || 'Запоните это поле']"
            class="mb-3"
            type="text"
            v-model="form.stomach"
            label="Живот"
          />

          <label class="va-input-label" style="color: var(--va-primary);">Перистальтика кишечника</label>
          <VaRadio
            class="mb-3"
            v-model="form.peristalsis"
            :rules="[(v) => v !== null || 'Запоните это поле']"
            :options="[
              {
                text: 'активная',
              },
              {
                text: 'вялая',
              },
              {
                text: 'не определяется',
              },
            ]"
            value-by="text"
          />

          <label class="va-input-label" style="color: var(--va-primary);">Печень</label>
          <VaRadio
            class="mb-3"
            v-model="form.liver"
            :rules="[(v) => v !== null || 'Запоните это поле']"
            :options="[
              {
                text: 'не увеличена',
              },
              {
                text: 'увеличена',
              },
            ]"
            value-by="text"
          />

          <label class="va-input-label" style="color: var(--va-primary);">Стул</label>
          <div class="rcflex row mb-3">
            <VaCheckbox
              v-model="form.stool"
              array-value="оформленный"
              label="Оформленный"
              class="mb-6"
            />
            <VaCheckbox
              v-model="form.stool"
              array-value="жидкий"
              label="Жидкий"
              class="mb-6"
            />
            <VaCheckbox
              v-model="form.stool"
              array-value="регулярный"
              label="Регулярный"
              class="mb-6"
            />
            <VaCheckbox
              v-model="form.stool"
              array-value="запоры"
              label="Запоры"
            />
            <VaCheckbox
              v-model="form.stool"
              array-value="мелена"
              label="Мелена"
            />
          </div>

          <label class="va-input-label" style="color: var(--va-primary);">Рвота</label>
          <VaRadio
            class="mb-3"
            v-model="form.vomit"
            :rules="[(v) => v !== null || 'Запоните это поле']"
            :options="[
              {
                text: 'нет',
              },
              {
                text: 'есть',
              },
            ]"
            value-by="text"
          />

          <VaInput
            :rules="[(v) => v !== null || 'Запоните это поле']"
            class="mb-3"
            type="text"
            v-model="form.foodwater_intake"
            label="Время приема пищи и жидкости"
          />

          <label class="va-input-label" style="color: var(--va-primary);">Мочеиспускание</label>
          <div class="rcflex row mb-3">
            <VaCheckbox
              v-model="form.urination"
              array-value="свободное"
              label="Свободное"
              class="mb-6"
            />
            <VaCheckbox
              v-model="form.urination"
              array-value="затрудненное"
              label="Затрудненное"
              class="mb-6"
            />
            <VaCheckbox
              v-model="form.urination"
              array-value="болезненное"
              label="Болезненное"
              class="mb-6"
            />
            <VaCheckbox
              v-model="form.urination"
              array-value="безболезненное"
              label="Безболезненное"
            />
          </div>

          <label class="va-input-label" style="color: var(--va-primary);">Диурез</label>
          <div class="rcflex row mb-3">
            <VaCheckbox
              v-model="form.diuresis"
              array-value="б/о"
              label="Б/о"
              class="mb-6"
            />
            <VaCheckbox
              v-model="form.diuresis"
              array-value="снижен"
              label="Снижен"
              class="mb-6"
            />
            <VaCheckbox
              v-model="form.diuresis"
              array-value="полиурия"
              label="Полиурия"
              class="mb-6"
            />
            <VaCheckbox
              v-model="form.diuresis"
              array-value="контролирует"
              label="Контролирует"
            />
            <VaCheckbox
              v-model="form.diuresis"
              array-value="не контролирует"
              label="Не контролирует"
            />
          </div>

          <label class="va-input-label" style="color: var(--va-primary);">Симптом поколачивания</label>
          <div class="rcflex row mb-3">
            <VaCheckbox
              v-model="form.murphys_symptom"
              array-value="положительный"
              label="Положительный"
              class="mb-6"
            />
            <VaCheckbox
              v-model="form.murphys_symptom"
              array-value="отрицательный"
              label="Отрицательный"
              class="mb-6"
            />
            <VaCheckbox
              v-model="form.murphys_symptom"
              array-value="Слева"
              label="Слева"
              class="mb-6"
            />
            <VaCheckbox
              v-model="form.murphys_symptom"
              array-value="справа"
              label="Справа"
            />
            <VaCheckbox
              v-model="form.murphys_symptom"
              array-value="с обеих сторон"
              label="С обеих сторон"
            />
          </div>

          <VaInput
            :rules="[(v) => v !== null || 'Запоните это поле']"
            class="mb-3"
            type="text"
            v-model="form.anesthesia_recom"
            label="План анестезии"
          />

          <VaInput
            :rules="[(v) => v !== null || 'Запоните это поле']"
            class="mb-3"
            type="text"
            v-model="form.anesthesia_risc"
            label="Риск анестезии"
          />

          <VaTextarea
            class="mb-4"
            v-model="form.recoms"
            label="Рекомендации"
            placeholder=""
            autosize
            :minRows=10
            :max-rows=100
          />
          
          <div class="rcflex">
            <VaButton 
              style="max-width:200px"
              class="mt-4 mr-2" 
              @click="$router.back()"
              preset="primary"
              color="secondary"
              icon="cancel"
            >
            {{ saved ? 'Вернуться' : 'Отменить'}}
            </VaButton>

            <VaButton
              v-if="!anexamId && !saved"
              style="max-width:200px"
              class="mt-4 mr-2" 
              @click="validate() && addAnExam()"
              preset="primary"
              icon-right="system_update_alt"
            >
              Закончить осмотр 
            </VaButton>

            <VaButton
              :disabled="!saved"
              preset="primary"
              icon="print"
              class="mt-4 mr-2"
              @click.before="showPrintModal=!showPrintModal"
              @click="print('anexam',true)"
            >
              Печать
            </VaButton>

            <VaButton
              v-if="anexamId || saved"
              style="max-width:230px"
              class="mt-4 mr-2" 
              @click="validate() && patchAnExam()"
              preset="primary"
              icon-right="save"
            >
              Сохранить изменения
            </VaButton>          
          </div>
        </VaForm>
        </VaCardContent>
  </VaCard>
  <VaModal
    :allowBodyScroll=true
    v-if="showPrintModal"
    :closeButton="true"
    v-model="showPrintModal"
    max-height="85vh"
    hideDefaultActions
    fixed-layout
    :title="'Осмотр анестезиолога-реаниматолога'"
    ref="showPrintModal"
  >
    <template #header>
      <VaButtonToggle
        v-model="printForm"
        preset="secondary"
        :options="formOptions"
        class="mb-4"
      />
    </template>

    <AnExamPrintForm id="anexam"/>

    <template #footer>
      <VaButton
        preset="primary"
        color="secondary"
        icon="close"
        class="mr-2"
        @click="$refs.showPrintModal.hide()"
      >
        Закрыть
      </VaButton>
      <VaButton
        :disabled="!printForm"
        preset="primary"
        icon="print"
        class="mr-2"
        @click="print('print')"
      >
        Печать
      </VaButton>
    </template>
  </VaModal>
</template>
<script>
import axiosInstance from "../services/api";
import PatientProfile from '@/components/PatientProfile';
import { useForm } from 'vuestic-ui'
import AnExamPrintForm from "@/components/printforms/AnExamPrintForm"


export default {
    components: { PatientProfile, AnExamPrintForm },
    props: ['anexamId','patientId','medhistoryId','visitId','regId'],
    data() {
        return {
            validate: useForm('examForm').validate, 
            isValid: useForm('examForm').isValid,
            toggleCard: false,
            template: '',
            form: {
              examDate: new Date(),
              patient: this.patientId,
              depName: 'ортопедическое',
              paid: false,
              urgence: 'плановая',
              scope: '',
              diagnosis: '',
              compliant: '',
              past_illness: '',
              diagnosis_secondary: '',
              status_alergo: '',
              hemotransfusion: '',
              past_operations: '',
              status_objective: '',
              position: '',
              body_type: '',
              state: 'ясное',
              pupils: '',
              photo_reaction: 'живая',
              skin: '',
              edema: 'нет',
              mouth: '',
              nasal: '',
              breath: '',
              av: 'нет',
              chest: '',
              breath_freq: '',
              sputum: '',
              percussion: '',
              heart_tones: '',
              heart_beat: '',
              artpressure: '',
              heart_rpm: '',
              aditional: '',
              tongue: '',
              smell: '',
              stomach: '',
              peristalsis: '',
              liver: '',
              stool: [],
              vomit: '',
              foodwater_intake: '',
              urination: [],
              diuresis: [],
              murphys_symptom: [],
              anesthesia_recom: '',
              anesthesia_risc: '',
              recoms: '',
              medhistory: this.medhistoryId === "0" ? null : this.medhistoryId,
              user: this.$store.state.app.account.profile.id,
            },
            templates: [],
            tmplObject: {},
            patient: {},
            patientLoading: true,
            anexam: {},
            anexamReady: false,
            hosvisit: {},
            showPrintModal: false,
            printModal: false,
            savedId: undefined,
            saved: false,
          };
    },
    computed: {
      bmi() {
        return this.patient.weight && this.patient.height && (this.patient.weight/Math.pow(this.patient.height/100, 2)).toFixed(2)
      },
      // medhistory() {
      //   return this.$store.state.app.medhistory
      // },
      // hosvisit() {
      //   return this.$store.state.app.hosvisit
      // },
    },
    watch: {
      patient: function () {
        // console.log(this.patient.length)
      }
    },
    methods: {
      async print(elid, wait=false) {
        if (wait) {
          await this.showPrintModal && this.$htmlToPaper(elid, null, () => {this.$refs.showPrintModal.hide()});
        }else{
          await this.$htmlToPaper(elid);
        }
      },
      fillTheTmplObject() {
        this.tmplObject = this.templates.filter((record) => record.type == this.depType)
      },
      fillTheProof() {
        this.form.proof = "Диагноз выставлен на основании жалоб, анамнеза болезни, объективных данных, данных лабораторно-инструментальных методов обследования:\n\n"
        + "Жалоб: " + this.form.compliant + "\n"
        + "Анамнеза болезни: " + this.form.anamnes_med + "\n"
        + "Локального статуса: " + this.form.status_local + "\n"
        + "Данных обследований:\n" + this.form.exam_data
      },
      getVisit() {
        axiosInstance
          .get('/visit/'+this.visitId)
          .then(response => {
            this.form = response.data.results[0]
            //this.examType = this.form.examType
            this.tmpDepType = this.form.depType
            this.getPatient(this.form.patient)
          })
          .catch()
      },
      patchAnExam() {
        var from = this.$route.query.from
        delete this.form.id
        delete this.form.created
        delete this.form.updated
        delete this.form.examDate
        const anexamId = this.anexamId ? this.anexamId : this.savedId
        axiosInstance
        .patch('/medhistory/anexam/' + anexamId, this.form)
        .then(response => {
          this.$vaToast.init({ message: 'Осмотр анестезиолога изменен', color: 'success', position: 'bottom-right' })
          from === 'medhistory' && this.$router.replace('/medhistory/' + response.data.medhistory)
          from === 'anexamsmanage' && this.$router.replace('/anexams/manage')
        })
          .catch(error => {
            this.$vaToast.init({ message: 'Ошибка изменений', color: 'warning', position: 'bottom-right' })
            console.log('Patch anexam Failed: ' + JSON.stringify(error))
        })

      },

      addAnExam() {
        //localStorage.setItem('anexam', JSON.stringify(this.form))
        this.form.diuresis = JSON.stringify(this.form.diuresis)
        this.form.murphys_symptom = JSON.stringify(this.form.murphys_symptom)
        this.form.stool = JSON.stringify(this.form.stool)
        this.form.urination = JSON.stringify(this.form.urination)
        console.log('Trying to add a Exam')
        axiosInstance
        .post('medhistory/anexam/new', this.form)
            .then(response => {
              this.saved = true
              this.anexam = response.data
              this.savedId = this.anexam.id
              this.$store.dispatch('app/setAnexam', this.anexam)
              this.$vaToast.init({ message: 'Осмотр анестезиолога сохранен', color: 'success', position: 'bottom-right', dangerouslyUseHtmlString: true, })
              if (this.medhistoryId!=="0") {
                this.$router.replace({name: 'editMedhistory', params: {medhistoryId: this.form.medhistory}})
              }else{
                this.setVisited(this.regId)
                // this.medhistoryId==="0" && this.$router.replace({name: 'VisitsManageView'})
              }
            })
            .catch(error => {
              this.$vaToast.init({ message: 'Ошибка заполнения', color: 'warning', position: 'bottom-right', dangerouslyUseHtmlString: true, })
              this.form.diuresis = JSON.parse(this.form.diuresis)
              this.form.murphys_symptom = JSON.parse(this.form.murphys_symptom)
              this.form.stool = JSON.parse(this.form.stool)
              this.form.urination = JSON.parse(this.form.urination)
                console.log('Addvisit Request Failed: ' + JSON.stringify(error))
                this.addError = true;
            })

      },
      getAnExam() {
        axiosInstance
        .get(`/medhistory/anexam/`+this.anexamId)
        .then(response => {
          this.form = response.data;
          //if (this.$store.state.app.account.profile.id != this.form.user) this.$router.replace({name:'denied', params: {code: 'e601'}})
          this.anexamReady = true
        })
        .catch(error => {
          console.log('Get anexam error')
        })
      },
      getTemplates() {
        axiosInstance
            .get(`templates/`)
            .then(response => {
                this.templates = response.data.results;
                this.tmplObject = this.templates.filter((record) => record.type == 1);
            })
            .catch(error => {
              console.log('Templates: error ' + error);
              this.tempaltes = []
        })
            .finally(() => {
              console.log('Templates: Request completed')
        });
      },
      fillFromHosvisit() {
        if (this.hosvisit.id) {
          this.anexamReady = false
          this.form.diagnosis = this.hosvisit.diagnosis
          this.form.diagnosis_secondary = this.hosvisit.diagnosis_secondary
          this.form.compliant = this.hosvisit.compliant
          this.form.status_alergo = this.hosvisit.status_alergo
          this.form.status_objective = this.hosvisit.status_objective
          this.form.status_alergo = this.hosvisit.status_alergo
          this.anexamReady = true
        }
      },
      getHosvisit() {
        axiosInstance
            .get('/visit/edit/' + this.visitId)
            .then(response => {
                this.hosvisit = response.data;
                this.form.diagnosis = this.hosvisit.diagnosis
                this.form.diagnosis_secondary = this.hosvisit.diagnosis_secondary
                this.form.compliant = this.hosvisit.compliant
                this.form.status_alergo = this.hosvisit.status_alergo
                this.form.status_objective = this.hosvisit.status_objective
                this.form.status_alergo = this.hosvisit.status_alergo
                this.anexamReady = true
            })
            .catch(error => {
              console.log('Hosvisit error: ' + error);
        })
      },
      getPatient() {
        axiosInstance
            .get(`patients/`+this.patientId+`/`)
            .then(response => {
              this.patient = response.data;
              this.$store.dispatch('app/setPatient', this.patient)
              this.form.heart_rpm = (this.patient.pulse ? this.patient.pulse + ' уд. в мин.' : '') + (this.patient.spo2 ? ', SpO2: ' + this.patient.spo2 + '%' : '')
              let bmi = this.bmi
              this.form.body_type = this.patient.height && this.patient.weight ? 'Рост: ' + this.patient.height+ ' см, Вес: ' + this.patient.weight +' кг., ИМТ: ' + bmi + ' - ' + this.bmiString(bmi) : ''
            })
            .catch(error => {
            console.log('Axios Request Failed: ' + error);
        })
            .finally(() => {
              this.patientLoading = false
        });
      },
      getMedhistory() {
        console.log('getting medhistory for patient')
        axiosInstance
            .get(`medhistory/patient/`+this.patientId)
            .then(response => {
              this.form.medhistory = response.data.results[0].id
        })
            .catch(error => {
              console.log('Axios Request Failed: ' + error);
              this.medhistory = false;
        })
            .finally(() => {
              console.log('One patient: Request successfully completed')
        });
      },
      setVisited(reg_id) {
        axiosInstance
          .patch('/registrations/edit/'+ reg_id, {visited: 1})
          .then((response) => {
            console.log('Visited status setted')
          },
          (error) => {
            //console.log(JSON.stringify(error))
            console.log('Visited setting failed')
          })
      },
      bmiString(bmi) {
        if (bmi<=16) {
          return 'Выраженный дефицит массы тела'
        }
        else if (bmi<=18.5) {
          return 'Недостаточная (дефицит) масса тела'
        }
        else if (bmi<=25) {
          return 'Норма'
        }
        else if (bmi<=30) {
          return 'Избыточная масса тела (предожирение)'
        }
        else if (bmi<=35) {
          return 'Ожирение первой степени'
        }
        else if (bmi<=40) {
          return 'Ожирение второй степени'
        }
        else if (bmi>40) {
          return 'Ожирение третьей степени (морбидное)'
        }
      },
    },
    created() {
      // this.fillFromHosvisit()
      // console.log()
      if (this.anexamId) {
        this.getAnExam()
      }else{
        this.getPatient()
        this.visitId !== "0" && this.getHosvisit()
        if (this.visitId === "0") this.anexamReady = true
      }
    },
    mounted() {
      // if (localStorage.getItem('anexam')) {
      //   let a = localStorage.getItem('anexam')
      //   delete a.medhistory
      //   this.form = JSON.parse(a)
      //   this.form.medhistory = this.medhistoryId && this.medhistoryId !== "0" ? this.medhistoryId : null
      // }
    },
}

</script>
<style>
</style>
  