<template>
    <div class="print">
        <p style="text-align:center;font-weight:bold;">Информированное добровольное согласие на оказание платных медицинских услуг</p>
        <p style="text-align:right">{{ date }} г.</p>
        <p style="text-align:justify;text-indent: 2em;">Я, заказчик, потребитель, пациент {{ patient.full_name}},
        в рамках договора на оказание платных медицинских услуг, желаю получить платные медицинские услуги в ООО «Клиника Профессора» (далее — Учреждение), при этом мне разъяснено и мною осознано следующее:<br/>
        1.	Я, получив от сотрудников полную информацию о возможности и условиях предоставления мне платных медицинских услуг и готов их оплатить. Я ознакомлен с положением об организации платных услуг в Учреждении.<br/>
        2.	Мне разъяснено, что я могу получить как один из видов платных медицинских услуг, так и несколько видов услуг.<br/>
        3.	Мне разъяснено, что не соблюдение указаний (рекомендаций) исполнителя (медицинского работника, предоставляющего платную медицинскую услугу, в том числе назначенного режима лечения, могут снизить качество предоставляемой мне платной медицинской услуги. Повлечь за собой невозможность ее завершения в срок или отрицательно сказаться на состоянии моего здоровья.<br/>
        4.	Мною добровольно, без какого-либо принуждения и по согласованию с врачом выбраны дополнительные медицинские услуги, которые я хочу получить в Учреждении и согласен(на) оплатить лечение.<br/>
        5.	Я ознакомлен с действующим прейскурантом (тарифами) и согласен оплатить стоимость медицинской услуги в соответствии с ним.<br/>
        6.	Виды выбранных мною платных медицинских услуг согласованы с врачом и я даю свое согласие на их оплату в кассу Учреждения.<br/>
        7.	Я проинформирован, что по поводу имеющегося у меня заболевания, иных проблем со здоровьем могу получить медицинскую помощь в других лечебных учреждениях, и подтверждаю свое согласие на получение желаемых медицинских услуг в Учреждении.<br/>
        8.	Настоящее согласие мною прочитано, я полностью понимаю преимущества предложенных мне медицинских услуг и даю согласие на их применение.<br/>
        </p>
        <p>Пациент (Заказчик): {{ patient.full_name}} _____________________(подпись)</p>
        <p>Паспорт: {{ passport}}</p>
    </div>
</template>
<script>
export default {
    props: ['patient','date'],
    computed: {
        passport() {
            if (this.$isJSON(this.patient.passport)) {
                let p = JSON.parse(this.patient.passport)
                return 'серия ' + p.series + ' номер ' + p.number + ' выдан ' + p.issued + ' ' + p.date
            }else{
                return this.patient.passport.length>0 ? this.patient.passport : 'не указаны'
            }
      }
    }
}
</script>