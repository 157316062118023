import UserService from '../services/user.service';
const profile = localStorage.getItem('userprofile') ? JSON.parse(localStorage.getItem('userprofile')) : ''
const initialState = { 
  account: {
    profile: profile ? profile : null 
  }, 
  patients: { 
    page: 1 
  }, 
  sidebarStatus: false, 
  version: process.env.VUE_APP_VERSION,
  patient: {},
  medhistory: {},
  anexam: {},
  hosvisit: {},
  preop: {},
  operation: {},
  diary: {},
  prescriptions: {},
  tests: {},
  title: {},
  de: {},
}

export const app = {
  namespaced: true,
  state: initialState,
  actions: {
    setPatient({ commit }, object) {
      commit('patient', object);
    },
    setMedhistory({ commit }, object) {
      commit('medhistory', object);
    },
    setAnexam({ commit }, object) {
      commit('anexam', object);
    },
    setHosvisit({ commit }, object) {
      commit('hosvisit', object);
    },
    setPreop({ commit }, object) {
      commit('preop', object);
    },
    setOperation({ commit }, object) {
      commit('operation', object);
    },
    setDiary({ commit }, object) {
      commit('diary', object);
    },
    setPrescriptions({ commit }, object) {
      commit('prescriptions', object);
    },
    setTests({ commit }, object) {
      commit('tests', object);
    },
    setTitle({ commit }, object) {
      commit('title', object);
    },
    setDE({ commit }, object) {
      commit('de', object);
    },
    resetPatient({ commit }) {
      commit('resetPatient');
    },
    resetMedhistory({ commit }) {
      commit('resetMedhistory');
    },
    resetAnexam({ commit }) {
      commit('resetAnexam');
    },
    resetHosvisit({ commit }) {
      commit('resetHosvisit');
    },
    resetPreop({ commit }) {
      commit('resetPreop');
    },
    resetOperation({ commit }) {
      commit('resetOperation');
    },
    resetDiary({ commit }) {
      commit('resetDiary');
    },
    resetPrescriptions({ commit }) {
      commit('resetPrescriptions');
    },
    resetTests({ commit }) {
      commit('resetTests');
    },
    resetTitle({ commit }) {
      commit('resetTitle');
    },
    resetDE({ commit }) {
      commit('resetDE');
    },
    resetAll({ commit }) {
      commit('resetAll');
    },
    getProfile({ commit }) {
      return UserService.getProfile().then(
        profile => {
          commit('setProfile', profile);
          return Promise.resolve(profile);
        },
        error => {
          commit('setProfileError');
          return Promise.reject(error);
        }
      );
    },
    setPushServiceStatus({ commit }, value) {
      return UserService.setPushServiceStatus(value).then(
        profile => {
          //console.log('profile is ' + JSON.stringify(profile))
          commit('setProfile', profile.data);
          return Promise.resolve(profile);
        },
        error => {
          commit('setProfileError');
          return Promise.reject(error);
        }
      );
    },
    resetProfile({commit}) {
      commit('resetProfile')
    },
    changePage({ commit }, currentPage) {
        commit('page', currentPage);
      },
    toggleSidebar({commit}, showSidebar) {
        commit('sidebarStatus', showSidebar)
    }
  },
  mutations: {
    setProfile(state, profile) {
      // console.log( JSON.stringify(profile))
      state.account.profile = profile;
      if (profile) {
        if (profile.groups.findIndex(group => group.name === "Главврач") != -1) state.account.profile.boss = true
        state.account.profile.iamDoctor = profile.groups.findIndex(group => group.name === "Врачи") != -1 ? true : false
        state.account.profile.iamRegistrator = profile.groups.findIndex(group => group.name === "Регистратор") != -1 ? true : false
        state.account.profile.iamNurse = profile.groups.findIndex(group => group.name === "Медсестры") != -1 ? true : false
        state.account.profile.iamAnesthesist = profile.groups.findIndex(group => group.name === "Анестезиологи") != -1 ? true : false
        localStorage.setItem('userprofile', JSON.stringify(state.account.profile))
      }
    },
    resetProfile(state) {
      state.account.profile = {};
    },
    setProfileError(state) {
      state.account.profile = "{error:true}";
    },
    page(state,currentPage) {
      state.patients.page = currentPage;
    },
    sidebarStatus(state, showSidebar) {
      state.sidebarStatus = showSidebar
    },
    patient(state, object) {
      state.patient = object;
    },
    medhistory(state, object) {
      state.medhistory = object;
    },
    anexam(state, object) {
      state.anexam = object;
    },
    hosvisit(state, object) {
      state.hosvisit = object;
    },
    preop(state, object) {
      state.preop = object;
    },
    operation(state, object) {
      state.operation = object;
    },
    diary(state, object) {
      state.diary = object;
    },
    prescriptions(state, object) {
      state.prescriptions = object;
    },
    tests(state, object) {
      state.tests = object;
    },
    title(state, object) {
      state.title = object;
    },
    de(state, object) {
      state.de = object;
    },
    resetPatient(state) {
      state.patient = {};
    },
    resetMedhistory(state) {
      state.medhistory = {};
    },
    resetAnexam(state) {
      state.anexam = {};
    },
    resetHosvisit(state) {
      state.hosvisit = {};
    },
    resetPreop(state) {
      state.preop = {};
    },
    resetOperation(state) {
      state.operation = {};
    },
    resetDiary(state) {
      state.diary = {};
    },
    resetPrescriptions(state) {
      state.prescriptions = {};
    },
    resetTests(state) {
      state.tests = {};
    },
    resetTitle(state) {
      state.title = {};
    },
    resetDE(state) {
      state.de = {};
    },
    resetAll(state) {
      state.patient = {};
      state.medhistory = {};
      state.anexam = {};
      state.hosvisit = {};
      state.preop = {};
      state.operation = {};
      state.diary = {};
      state.prescriptions = {};
      state.tests = {};
      state.title = {};
      state.de = {};
    },
  }
};
