<template>
<div class="print">
    <p class="bold center">
        ООО «Клиника Профессора»<br/>
        ИНН 1435316805, КПП 143501001<br/>
        Адрес: 677010, г. Якутск, ул. Радужная, 69/12<br/>
        Телефон: +7 (914)2776990; электронный адрес: klinika.professora@mail.ru
    </p>
    
    <hr size=2 width="100%" align=center>
    <p class="bold center">
        ПРЕДОПЕРАЦИОННЫЙ ЭПИКРИЗ
    </p>
    <p style="text-align:left;font-weight:bold;">
        {{ preop.preopDate.split(' ')[0] }} г.<br/>
    </p>
    <p style="text-align:justify">
        <b>ФИО пациента:</b> {{ patient.full_name }}<br/>
        <b>Возраст:</b> {{ patientAge }} ({{ patient.birthdate.split('-').reverse().join('.')}} г.р.)<br/>
        <b>Рост:</b> {{ patient.height }} см, вес: {{ patient.weight }} кг
    </p>
    <p style="text-align:justify">
        <b>Клинический диагноз:</b><br/>
        Основной: {{ preop.diagnosis }}<br/>
        Сопутствующий: {{ preop.diagnosis_secondary }}
        <br/>
        <b>Обоснование диагноза:</b> {{ preop.proof }}<br/>
        <b>Планируемый объем оперативного вмешательства:</b> {{ preop.scope }}<br/>
        <b>Планируемая анестезия:</b> {{ preop.anesthesia }}<br/>
        <b>Риск ВТЭО:</b> {{ preop.vteo_risc == 0 ? "Низкий" : "Высокий" }}<br/>
        <b>Возможные осложнения:</b> {{ preop.possible_risc }}<br/>
        <b>Аллергические реакции:</b> {{ preop.status_alergo }}<br/>
    </p>
    <br/>
    <p style="text-align:justify">
        Врач: {{ preop.doctor_full_name}}<br/>
    </p>
    <div class="pagebreak" />
</div>
    </template>
    <script setup>
        import { formatDate } from '@/common/rcfx.js'
    </script>
    <script>
    export default {
        props: ['visit'],
        computed: {
            patient() {
                return this.$store.state.app.patient
            },
            preop() {
                return this.$store.state.app.preop
            },
            patientAge() {
                var birthdate = new Date(this.patient.birthdate.split('.').reverse().join('-'))
                var today = new Date()
                return Math.floor((today-birthdate)/3600000/24/365)
            }
        }
    }
    </script>