<template>
<VaCard>
  <VaCardContent>
    <div class="rc-flex rc-full-height">
      <div class="vcenter center">
        <VaImage
      class="w-full md:w-1/2 lg:w-1/3"
      style="margin:0 auto"
      :maxWidth=256
      src="./img/icons/icon-512x512.png"
      />
      <h3 class="va-h3">
        Вход
      </h3>

      <VaForm class="center" @submit.prevent="login" autofocus tag="form" ref="loginForm" style="max-width:250px;">
        <VaInput tabindex=1 :error="loginFailed" @input="loginFailed=false;loginError=false" v-model="username" autocomplete="username" class="mb-2" placeholder="Имя пользователя" name="Login" value=""/>
        <VaInput tabindex=2 :error="loginFailed" @input="loginFailed=false;loginError=false" v-model="password" autocomplete="current-password" class="mb-2" placeholder="Пароль" type="password" name="Password" value=""/>
        <VaButton tabindex=3 type="submit" @click="login" icon="login" :loading="loading" class="mb-2" preset="primary" :disabled="loginDisabled">
          Войти
        </VaButton>
        <div v-if="loginFailed" class="mb-2 va-text-block" style="color:red">Имя пользователя или пароль неверны</div>
        <div v-if="loginError" class="mb-2 va-text-block" style="color:red">Возникла сетевая ошибка, попробуйте войти позже</div>
      </VaForm>
    </div>
  </div>
  </VaCardContent>
</VaCard>
</template>
<script setup>
import EventBus from "../common/EventBus";
</script>
<script>
export default {
  computed: {
      loginDisabled: function(){
        if (this.username.length == 0 || this.password.length == 0) 
        {
          return true
        }else{
          return false
        }
      }
    },
  data() {
    return {
        username: "",
        password: "",
        loginFailed: false,
        loginError: false,
        showModal:true,
        loading: false,      
      }
    },
  methods: {
      login() {
          this.loading = true
          //console.log('Login executed: ' + this.username + ' - ' + this.password)
          this.$store.dispatch('auth/login', { username: this.username, password: this.password })
          .then(response => {
            this.username = ""
            this.password = ""
            this.loading = false
            //console.log('Auth success: ' + JSON.stringify(response))
            this.$store.dispatch('app/getProfile')
            .then((profile) => {
                profile.pushservice && EventBus.dispatch("setPushServiceStatus", {value: true, silent: true})
                this.$router.push('/profile')
              })
           }, 
           error => {
             //console.log('Auth error: ' + JSON.stringify(error.response.status))
             this.loading = false
             this.loginFailed = (error.response.status === 401 ? true : false)
             this.loginError = (error.response.status === 400 ? true : false)
           })
      },
  },
   mounted() {
    let apptalk = new BroadcastChannel('inapp')
    apptalk.postMessage({ code: "listenEsc", state: false })
    if (this.$store.state.auth.status.loggedIn) this.$router.push('/about')
    //console.log('-------------Starting Auth Component---------------')
  },
}
</script>
