import api from './api';

class DeviceManager {

  async getDeviceId(token) {
    api
        .post(`/devices/search?token=`+token,)
        .then(response => {
          return response.data.results[0].id
        })
        .catch(error => {
          return error
        })
  }

  async addDevice(token,user,platform) {
    var device = {
      token: token,
      active: true,
      user: user,
      platform: platform,
    }
    return api
        .post(`/devices/`, device)
        .then(response => {
          console.log('Device added');
          localStorage.setItem("pushToken", response.data.token)
          localStorage.setItem("pushTokenId", response.data.id)
          return true
        })
        .catch(async(error) => {
          if (error.response.status === 400) {
            api.get(`/devices/search?token=`+token,)
            .then(response => {
              console.log('Device added');
              localStorage.setItem("pushToken", token)
              localStorage.setItem("pushTokenId", response.data.results[0].id)
              return true
            })
            .catch(()=>{
              return false
            })
          }
        })
  }

  async removeDevice(pushTokenId, pushToken) {
    // console.log('DeviceManager начал работу');

    if (!pushTokenId && pushToken) {
      api.get(`/devices/search?token=`+pushToken,)
      .then(response => {
        console.log('PushTokenId found: ' + response.data.results[0].id)

        return api
        .delete(`/devices/remove/`+response.data.results[0].id,)
        .then(() => {
          console.log('Device removed');
          return true
        })
        .catch(error => {
          console.log('Device remove error: ' + JSON.stringify(error))
          return error
        })
        .finally(() =>{
          console.log('Cleaning localStorage anyway')
          localStorage.removeItem("pushTokenId")
          localStorage.removeItem("pushToken")
        })
      })
      .catch(error => {
        return error
      })
    }else if (pushTokenId) {
      return api
        .delete(`/devices/remove/`+pushTokenId,)
        .then(() => {
          console.log('Device removed');
          return true
        })
        .catch(error => {
          console.log('Device remove error')
          return error
        })
        .finally(() =>{
          console.log('Cleaning localStorage anyway')
          localStorage.removeItem("pushTokenId")
          localStorage.removeItem("pushToken")
        })
      }else{
        console.log('Нет токена и ИД, удалять нечего, возвращаем true');
        return true
      }
  }
}

export default new DeviceManager();
