<template>
<div class="print">
    <p style="text-align:center;font-weight:bold;">
        ПРЕДСТАВЛЕНИЕ НА ВРАЧЕБНУЮ КОМИССИЮ ПО ФАРМАКОТЕРАПИИ<br/>
        от {{ formatDate(new Date(medhistory.openDate.split(' ')[0].split('.').reverse().join('.')), "ru") }}
    </p>
    <br/>
    <p style="text-align:left;font-weight:bold;">
        На лечебно-контрольную комиссию представляется пациент:
    </p>
    <p style="text-align:justify">
        <b>ФИО пациента:</b> {{ patient.full_name }}<br/>
        <b>Возраст:</b> {{ patientAge }} ({{ patient.birthdate.split('-').reverse().join('.')}} г.р.)<br/>
        <b>Рост:</b> {{ patient.height }} см, вес: {{ patient.weight }} кг
    </p>
    <p style="text-align:justify">
        <b>Клинический диагноз:</b><br/>
        <b>Основной:</b> {{ operation ? operation.diagnosis : hosvisit.diagnosis }}<br/>
        <b>Сопутствующий:</b> {{ hosvisit.diagnosis_secondary }}
        <br/>
        <b>Находится на лечении в</b> ООО "Клиника профессора" в стационаре с {{ medhistory.openDate.split(' ')[0] }}<br/> 
        <b>Характеристика случая:</b> С учетом тяжести состояния и сопутствующей патологии разрешить назначение 5-ти и более ЛС, препаратов не входящих в стандарт лечения и в перечень ЖНВЛП:
        <text v-for="prescription, index in prescriptions">
            {{ prescription.name }} 
            {{ prescription.dosage }} 
            {{ prescription.method.toLowerCase() }} 
            {{ prescription.freq }} р.в.д. 
            №{{ prescription.duration }}
            {{ index < prescriptions.length-1 ? ', ' : '.'}}
        </text>
    </p>
    <br/>
    <p style="text-align:justify">
        Лечащий врач: {{ medhistory.doctor_full_name}}<br/>
    </p>
    <p style="text-align:justify">
        С историей болезни, диагнозом, тактикой ведения и лечения больного ознакомлены.<br/>
        При проверке первичной медицинской документации выявлено (дефекты, нарушения, ошибки): 
    </p>
    <table class="rc-table">
        <tr>
            <th colspan=4 align="center">
                <b>Оценка качества проводимой фармакотерапии (приказ МЗСР РФ от 22.10.2007 г. №494)</b>
            </th>
        </tr>
        <tr>
            <th width="52%">
                <b>Наименование показателей</b>
            </th>
            <th width="16%">
                <b>Да</b>
            </th>
            <th width="16%">
                <b>Нет</b>
            </th>
            <th width="16%">
                <b>Не в полной мере</b>
            </th>
        </tr>
        <tr>
            <td width="52%">
                Соответствие стандартам и протоколам лечения
            </td>
            <td width="16%">
            </td>
            <td width="16%">
            </td>
            <td width="16%">
            </td>
        </tr>
        <tr>
            <td width="52%">
                Рациональность выбора базового лекарственного средства (ЛС)
            </td>
            <td width="16%">
            </td>
            <td width="16%">
            </td>
            <td width="16%">
            </td>
        </tr>
        <tr>
            <td width="52%">
                Рациональность выбора комбинации ЛС
            </td>
            <td width="16%">
            </td>
            <td width="16%">
            </td>
            <td width="16%">
            </td>
        </tr>
        <tr>
            <td width="52%">
                Адекватность дозового режима
            </td>
            <td width="16%">
            </td>
            <td width="16%">
            </td>
            <td width="16%">
            </td>
        </tr>
        <tr>
            <td width="52%">
                Адекватность курсового режима
            </td>
            <td width="16%">
            </td>
            <td width="16%">
            </td>
            <td width="16%">
            </td>
        </tr>
        <tr>
            <td width="52%">
                Наличие нежелательных побочных реакций на препарат (НПР) 
            </td>
            <td width="16%">
            </td>
            <td width="16%">
            </td>
            <td width="16%">
            </td>
        </tr>
        <tr>
            <td width="52%">
                Оценка эффективности проводимой фармакотерапии лечащим врачом (клиническая эффективность, сроки проведения необходимого комплекса диагностических и лабораторных исследований)
            </td>
            <td width="16%">
            </td>
            <td width="16%">
            </td>
            <td width="16%">
            </td>
        </tr>
    </table>
    <p style="text-align:justify">
        Рекомендации по ведению больного 
    </p>
    <p style="text-align:justify">
        <b>Решение ЛКП:</b>
    </p>
    <p style="text-align:justify">
        С учетом тяжести состояния и сопутствующей патологии разрешить назначение 5-ти и более ЛС, препаратов не входящих в стандарт лечения и в перечень ЖНВЛП
    </p>

    <p style="text-align:left">
        <b>Главный врач:</b> Устинова А.И.<br/>
        <b>Д.м.н. Профессор:</b> Пальшин Г.А.<br/>
        <b>Заведующий:</b> Пальшин В.Г.<br/>
        <b>Врач анестезиолог-реаниматолог:</b> Захаров М.М.
    </p>
</div>
</template>
<script setup>
    import { formatDate } from '@/common/rcfx.js'
</script>
<script>
export default {
    props: ['patient'],
    computed: {
        patientAge() {
            var birthdate = new Date(this.patient.birthdate.split('.').reverse().join('-'))
            var today = new Date()
            return Math.floor((today-birthdate)/3600000/24/365)
        },
        medhistory() {
            return this.$store.state.app.medhistory
        },
        hosvisit() {
            return this.$store.state.app.hosvisit
        },
        preop() {
            return this.$store.state.app.preop
        },
        operation() {
            return this.$store.state.app.operation
        },
        diary() {
            return this.$store.state.app.diary
        },
        prescriptions() {
            return this.$store.state.app.prescriptions
        },
    }
}
</script>
<style scoped>
    .rc-table {
        border-collapse: collapse;
        border: solid 1px #333
    }
</style>
