<template>
  <div class="rcflex row" :class="{'rcflex-right mr-4' : isOwner==true, 'rcflex-left ml-4' : isOwner==false}">
  <VaCard class="rc-comment-card">
    <VaCardContent>
      <div class="rcflex row">
        <img 
            :alt="comment.user_full_name"
            :src="comment.user_photo ? comment.user_photo : '/img/defaultavatar.jpg'"
            class="top-avatar mr-2"
        />
        <div class="rc-comment-body" style="max-width:calc(100% - 55px)">
          <p class="mb-2 rc-text-bold">{{ comment.user_full_name }}</p>
          <p class="mb-3">Дата: {{ comment.date }}</p>
          <p class="mb-2">{{ comment.comment }}</p>
        </div>
    </div>
    </VaCardContent>
  </VaCard>
  </div>
</template>
<script>
export default {
    props: ['comment','isOwner'],
}
</script>