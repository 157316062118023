<template>
  <div class="rc-wrapper">
    <VaLayout 
    :top="{ fixed: true, order: 1 }"
    :left="{ order: 0 }"
  >
    <template #top>
      <VaNavbar class="py-2 rc-navbar" shadowed>
        <template #left>
          <VaButton preset="primary" :icon="showSidebar ? 'menu_open' : 'menu'" @click="toggleSidebar" />
        </template>
        <template #center>
          <VaNavbarItem class="font-bold text-lg">
            Реактив клиник
          </VaNavbarItem>
        </template>
        <template #right>
          <VaBadge
            v-show=false
            overlap
            text="5"
            :offset="12"
            placement="top-start"
          >
            <VaButton
              icon='notifications' 
              style="align-items: center;max-height: max-content;" 
              color="primary" 
              preset="secondary"
              @click="subscribe"
              />
          </VaBadge>
          <VaButton
              v-show=false
              icon='delete' 
              style="align-items: center;max-height: max-content;" 
              color="primary" 
              preset="secondary"
              @click=""
              />
          <VaMenu placement="bottom-end" v-if="loggedIn && userProfile">
            <template #anchor>
              <img 
                  :alt="userProfile.full_name"
                  :src="userProfile.photo ? userProfile.photo : '/img/defaultavatar.jpg'"
                  class="top-avatar"
                  @click="closeSidebar"
              />
            </template>
        
            <div class="mb-3 mt-3 va-text-right va-text-bold va-text-uppercase">
              {{userProfile.full_name}}
            </div>
            <VaMenuItem icon="calendar_month" v-if="iamDoctor|| iamAnesthesist">
              <router-link to="/myregs">Мое расписание</router-link>
            </VaMenuItem>
            <VaMenuItem icon="assignment"  v-if="iamDoctor">
              <router-link to="/templates">Мои шаблоны</router-link>
            </VaMenuItem>
            <VaMenuItem icon="person">
              <router-link to="/profile">Профиль</router-link>
            </VaMenuItem>
            <VaMenuItem icon="logout" @click="logOut()">
              Выйти
            </VaMenuItem>
          </VaMenu>
          <div v-else style="display:block;width:1px;height:54px;"/>
        </template>
      </VaNavbar>
    </template>

    <template #left>
      <VaSidebar v-model="showSidebar">
        <VaSidebarItem v-if="iamDoctor || iamAnesthesist">
          <router-link to="/myregs" @click="closeSidebar">
            <VaSidebarItemContent>
              <VaIcon name="calendar_month" />
              <VaSidebarItemTitle>
                Мое расписание
              </VaSidebarItemTitle>
            </VaSidebarItemContent>
          </router-link>
        </VaSidebarItem>
        <VaSidebarItem v-if="iamDoctor">
          <router-link to="/visits/manage" @click="closeSidebar">
            <VaSidebarItemContent>
              <VaIcon name="emergency" />
              <VaSidebarItemTitle>
                Амбулатория
              </VaSidebarItemTitle>
            </VaSidebarItemContent>
          </router-link>
        </VaSidebarItem>
        <VaSidebarItem v-if="iamDoctor || iamAnesthesist">
          <router-link to="/medhistory/manage" @click="closeSidebar">
            <VaSidebarItemContent>
              <VaIcon name="local_hospital" />
              <VaSidebarItemTitle>
                Стационар
              </VaSidebarItemTitle>
            </VaSidebarItemContent>
          </router-link>
        </VaSidebarItem>
        <VaSidebarItem v-if="iamDoctor || iamAnesthesist">
          <router-link to="/anexams/manage" @click="closeSidebar">
            <VaSidebarItemContent>
              <VaIcon name="ecg" />
              <VaSidebarItemTitle>
                Осмотры анестезиолога
              </VaSidebarItemTitle>
            </VaSidebarItemContent>
          </router-link>
        </VaSidebarItem>
        <VaSidebarItem>
          <router-link to="/patients" @click="closeSidebar">
            <VaSidebarItemContent>
              <VaIcon name="badge" />
              <VaSidebarItemTitle>
                Пациенты
              </VaSidebarItemTitle>
            </VaSidebarItemContent>
          </router-link>
        </VaSidebarItem>
        <VaSidebarItem v-if="iamNurse">
          <router-link to="/nursepost" @click="closeSidebar">
            <VaSidebarItemContent>
              <VaIcon name="clinical_notes" />
              <VaSidebarItemTitle>
                Пост медсестры
              </VaSidebarItemTitle>
            </VaSidebarItemContent>
          </router-link>
        </VaSidebarItem>

        <VaSidebarItem v-if="iamRegistrator">
          <router-link to="/regs" @click="closeSidebar">
            <VaSidebarItemContent>
              <VaIcon name="calendar_clock" />
              <VaSidebarItemTitle>
                Записи на прием
              </VaSidebarItemTitle>
            </VaSidebarItemContent>
          </router-link>
        </VaSidebarItem>
        <VaSidebarItem>
          <router-link to="/profile" @click="closeSidebar">
            <VaSidebarItemContent>
              <VaIcon name="person" />
              <VaSidebarItemTitle>
                Профиль
              </VaSidebarItemTitle>
            </VaSidebarItemContent>
          </router-link>
        </VaSidebarItem>
        <VaSidebarItem v-if="iamDoctor || iamAnesthesist">
          <router-link to="/staff" @click="closeSidebar">
            <VaSidebarItemContent>
              <VaIcon name="groups" />
              <VaSidebarItemTitle>
                Персонал
              </VaSidebarItemTitle>
            </VaSidebarItemContent>
          </router-link>
        </VaSidebarItem>
        <VaSidebarItem v-if="iamBoss">
          <router-link to="/reports" @click="closeSidebar">
            <VaSidebarItemContent>
              <VaIcon name="analytics" />
              <VaSidebarItemTitle>
                Отчеты
              </VaSidebarItemTitle>
            </VaSidebarItemContent>
          </router-link>
        </VaSidebarItem>
        <VaSidebarItem>
          <router-link to="/about" @click="closeSidebar">
            <VaSidebarItemContent>
              <VaIcon name="info" /> 
              <VaSidebarItemTitle>
                О приложении
              </VaSidebarItemTitle>
            </VaSidebarItemContent>
          </router-link>
        </VaSidebarItem>        
        <VaSidebarItem v-if="!loggedIn">
          <router-link to="/login" @click="closeSidebar">
            <VaSidebarItemContent>
              <VaIcon name="login" />
              <VaSidebarItemTitle>
                Войти
              </VaSidebarItemTitle>
            </VaSidebarItemContent>
          </router-link>
        </VaSidebarItem>
      </VaSidebar>
    </template>

    <template #content>
      <div @click="closeSidebar">
        <router-view/>
      </div>
    </template>
  </VaLayout> 
  <VaModal v-model="loggingOut" :hideDefaultActions=true overlayOpacity=1 no-dismiss><VaProgressCircle indeterminate style="margin:20px auto"/><span style="margin:0 auto;display:block;">Завершаем сеанс..</span></VaModal>
  <VaModal 
    v-model="deferredPrompt" 
    :hideDefaultActions=true 
    overlayOpacity=1 
    no-dismiss
    ref="installPrompt"
  >
    <VaCardTitle>Реактив Клиник</VaCardTitle>
    <VaCardContent>Установим приложение?</VaCardContent>
    <div class="rcflex row">
    <VaButton @click.before="$refs.installPrompt.hide()" @click="dismiss" preset="primary" color="secondary" class="center">Позже</VaButton>
    <VaButton icon="install_desktop" @click.before="$refs.installPrompt.hide()" @click="install" preset="primary" color="success" class="center" style="--va-background-color:#3D920930;background:transparent">Конечно</VaButton>
    </div>
  </VaModal>

</div>
</template>

<script setup>
import EventBus from "./common/EventBus";
import DeviceManager from './services/device.service';
import * as rcfx from "@/common/rcfx"
import axios from 'axios'
</script>
<script>
//import { useBreakpoint } from 'vuestic-ui/web-components';
import axiosInstance from "./services/api";
import { detectOS } from "./common/rcfx";
import Cookies from "js-cookie"
import { template } from "lodash";

export default {
  data() {
    return {
      currentTime: new Date(),
      EventMessage: '',
      showRefreshModal: false,
      // appVersion: null,
      updateVersion: null,
      refreshing: false,
      updateExists: false,
      registration: null,
      loggingOut:false,
      deferredPrompt: null,
      }
    },
  computed: {
    appVersion() {
      return this.$store.state.app.version
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    showSidebar() {
      return this.$store.state.app.sidebarStatus
    },  
    userProfile() {
      return this.$store.state.app.account.profile 
    },
    platform() {
      return detectOS()
    },
    iamBoss: function() {
        return this.userProfile ? this.userProfile.boss ? true : false : false
    },
    iamDoctor: function() {
        return this.userProfile && this.userProfile.iamDoctor
    },
    iamRegistrator: function() {
      return this.userProfile && this.userProfile.iamRegistrator
    },
    iamNurse: function() {
      return this.userProfile && this.userProfile.iamNurse
    },
    iamAnesthesist: function() {
      return this.userProfile && this.userProfile.iamAnesthesist
    },
  },
  watch: {
    $route(to,from) {
      console.log(from, to)
    },
    async currentTime(newValue, oldValue) {
      // var nv = Math.floor(newValue.getTime()/1000)
      // var ov = Math.floor(oldValue.getTime()/1000)
      // if ((nv - ov) < 1) console.log('tick: ' + oldValue + nv)
      //this.updateProfile()
      //
    },
    updateExists(value) {
      if (value===true) {
        this.confirmUpdate(this.updateVersion)
      }
    },
  },
  methods: {
    confirmUpdate(version) {
      this.$vaModal.confirm({
        // message: 'Отличные новости!\nВышла новая версия приложения ('+ version +'), обновить прямо сейчас?\n(приложение будет перезапущено)',
        message: 'Отличные новости!\nВышла новая версия приложения, обновить прямо сейчас?\n(приложение будет перезапущено)',
        // title: 'Системное обновление ('+ version +')',
        title: 'Системное обновление',
        okText: "Да, обновимся сейчас!",
        cancelText: "Позже",
      })
      .then((ok) => ok && this.refreshApp())
    },
    refreshApp() {
      this.updateExists = false
      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!this.registration || !this.registration.waiting) return
      // send message to SW to skip the waiting and activate the new SW
      this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
      if (this.refreshing) return
      // Here the actual reload of the page occurs
      window.location.reload()
      this.refreshing = true

    },
    sendMsg(message) {
      EventBus.dispatch('appmsg', message)
    },
    tick() {
      setInterval(() => {
        this.currentTime = new Date()
        //this.showNotification()
      }, 10000)
    },
    checkAuth() {
      axiosInstance.get('/token')
    },
    toggleSidebar() {
      console.log('toggle!')
      this.$store.state.app.sidebarStatus ? this.closeSidebar() : this.openSidebar()
    },
    openSidebar () {
      this.$store.dispatch('app/toggleSidebar',true)
    },
    closeSidebar () {
      this.$store.dispatch('app/toggleSidebar',false)
    },
    reLogin(){
      this.$store.dispatch('auth/expired');
      this.$store.dispatch('app/resetProfile')
      this.$router.push('/login');
    },
    async logOut() {
      if (this.loggedIn) {
        this.loggingOut = true
        if (this.$messaging && this.userProfile.pushservice == true) {

          this.unsubscribe(true, true)
          .then(() => {
            console.log('ready to logout')
            this.$store.dispatch('auth/logout');
            this.$store.dispatch('app/resetProfile')
            this.loggingOut = false
            this.$router.replace('/about');
          })
          .catch((error) => {
            console.log(error)
          })
        } else {
          console.log('No subscribe, ready to logout')
          this.$store.dispatch('auth/logout');
          this.$store.dispatch('app/resetProfile')
          this.loggingOut = false
          this.$router.replace('/about');
        }
      }
    },
    // Update the UI to indicate whether the API is supported.
    isSupported(kind) {
      console.log('supported', kind);
    },
    // Wrapper to support first and second origin trial
    // See https://web.dev/badging-api/ for details.
    setBadge(...args) {
      if (navigator.setAppBadge) {
        navigator.setAppBadge(...args);
      } else if (navigator.setExperimentalAppBadge) {
        navigator.setExperimentalAppBadge(...args);
      } else if (window.ExperimentalBadge) {
        window.ExperimentalBadge.set(...args);
      }
    },

    // Wrapper to support first and second origin trial
    // See https://web.dev/badging-api/ for details.
    clearBadge() {
      if (navigator.clearAppBadge) {
        navigator.clearAppBadge();
      } else if (navigator.clearExperimentalAppBadge) {
        navigator.clearExperimentalAppBadge();
      } else if (window.ExperimentalBadge) {
        window.ExperimentalBadge.clear();
      }
    },
    async letsSetBadge(value) {
      if (!(await this.getNotificationPermission())) {
        return alert('This demo requires the Notification permission to be granted.');  
      };
      const val = parseInt(value, 10);
      if (isNaN(val)) {
        this.setBadge();
        return;
      }
      this.setBadge(val);
    },
    async letsClearBadge() {
      if (!(await this.getNotificationPermission())) {
        return alert('This demo requires the Notification permission to be granted.');  
      };
      this.clearBadge();
    },
    updateAvailable(event) {
      this.registration = event.detail
      this.updateExists = true
    },
    resumed() {
      this.$vaToast.init({ title:'Системное уведомление', message: 'Приложение открыто', duration: 10000, color: '#9bd7e0', position: 'bottom-right' })
    },
    showNotification(payload) {
      Notification.requestPermission().then((result) => {
        if (result === "granted") {
          // Для движка Safari
          if (this.platform==="iOS" || this.platform==="Mac OS") {
            const notification = new Notification(payload.title, {body: payload.body})
          } 
          // Для движка Chrome
          else {
            navigator.serviceWorker.ready
            .then((registration) => {
              registration.showNotification(payload.title, {body: payload.body})
            })
          }
        }
      })
    },
    // Управление подписками
    // Подписка
    // Возвращает FCM токен или ошибку
    async subscribe(silent) {
        if (await Notification.requestPermission() === "granted") {
          let $this = this
          return new Promise(function(resolve, reject) {
            console.log('Начинаем процесс подписки')
            $this.$messaging.getToken({vapidKey:"BJQKibLqtkPH9BPPqG9L_pbJ7JXVG_ionq9KsVtbIKUdYvNzz26ZgjTZ6zT5Bl-qiMJUckEcl89IpmparIVZAiY"})
            .then(async (currentToken) => {
              if (currentToken) {
                if (await DeviceManager.addDevice(currentToken, $this.userProfile.id, $this.platform))
                 {
                  !silent && $this.$vaToast.init({ message: 'Push-увдомления включены', color: 'success', position: 'bottom-right' })
                  //console.log('client token', currentToken)
                  !silent && $this.showNotification({title: "Все отлично!", body:"Так будут выглядеть наши уведомления"})
                  $this.$store.dispatch('app/setPushServiceStatus', true)
                  resolve('Токен успешно получен и записан в базу Приложения')
                  }
                else {
                  resolve('Токен успешно получен но не записан в базу Приложения')
                }
              }
            })
            .catch((error) => {
              $this.$store.dispatch('app/setPushServiceStatus', false)
              // EventBus.dispatch("PushStatusReady",{result: "error"})
              $this.$vaToast.init({ message: 'Push-токен не получен от FCM',duration: 3000, color: 'secondary', position: 'bottom-right' })
              $this.$vaToast.init({ message: error, duration: 5000, color: 'secondary', position: 'bottom-right' })
              reject('Не удалось получить токен')
            })
          })
        }
    },
      unsubscribe(silent, logout=false, safemode=false) {
        let $this = this
        return new Promise(function(resolve, reject) {
          console.log('Начинаем процесс отписки')
          $this.$messaging.deleteToken({vapidKey:"BJQKibLqtkPH9BPPqG9L_pbJ7JXVG_ionq9KsVtbIKUdYvNzz26ZgjTZ6zT5Bl-qiMJUckEcl89IpmparIVZAiY"})
          .then(async (res)=>{
            console.log('Токен успешно удален из базы FCM:' + res)
            if (localStorage.getItem('pushTokenId') || localStorage.getItem('pushToken')) {
              !logout && $this.$store.dispatch('app/setPushServiceStatus', false)
              // console.log('Пробуем удалить токен из базы Приложения')
              DeviceManager.removeDevice(localStorage.getItem('pushTokenId'),localStorage.getItem('pushToken'))
              .then(() => {
                // console.log('Токен успешно удален из базы Приложения')
                // EventBus.dispatch("PushStatusReady",{result: "success"})
                safemode && $this.firebaseClean()
                !silent && $this.$vaToast.init({ message: 'Push-увдомления отключены', color: 'secondary', position: 'bottom-right' })
                console.log('FCM token deleted');
                $this.$store.dispatch('app/setPushServiceStatus', false)
                resolve('Токен успешно удален из FCM и базы Приложения')
              })
              .catch(() => {
                $this.$store.dispatch('app/setPushServiceStatus', false)
                console.log('Девайс менеджер ответил отказом')
                resolve('Токен успешно удален из FCM но при удалении из базы Приложения возникла ошибка')
              })
              }else{
                $this.$store.dispatch('app/setPushServiceStatus', false)
                resolve('FCM: удален, DeviceManager: устройство не найдено')
              }
          })
          .catch((error) => {
            $this.$vaToast.init({ message: 'Push-токен не найден в FCM',duration: 3000, color: 'secondary', position: 'bottom-right' })
            $this.$vaToast.init({ message: error, duration: 5000, color: 'secondary', position: 'bottom-right' })
            // $this.$store.dispatch('app/setPushServiceStatus', {result: "error"})
            // EventBus.dispatch("PushStatusReady","error")
            reject(error)
          })
        })
    },
    async cleanFirebaseDB() {
      var m = await indexedDB.deleteDatabase('firebase-messaging-database')
      m.onsuccess = function () { console.log("Deleted messaging database successfully") }
      m.onerror = function () { console.log("Couldn't messaging delete database") }
      m.onblocked = function () { console.log("Couldn't delete messaging database due to the operation being blocked") }
      var i = await indexedDB.deleteDatabase('firebase-installations-database')
      i.onsuccess = function () { console.log("Deleted installation database successfully") }
      i.onerror = function () { console.log("Couldn't delete installation database") }
      i.onblocked = function () { console.log("Couldn't delete installation database due to the operation being blocked") }
      if (m && i) EventBus.dispatch("cleanFirebaseDBfinished")
    },
    react(event) {
      if (event === "controllerchange") {
          console.log('reloading page')
          if (this.refreshing) return
          this.refreshing = true
          window.location.reload()
        }
      if (event === "visibilitychange") {
          if (document.visibilityState === "visible") {
            console.log("APP resumed");
          }else{
            console.log("APP goes at background");
          }
        }
    },
    async dismiss() {
      Cookies.set("add-to-home-screen", null, { expires: 1 });
      this.deferredPrompt = null;
    },
    async install() {
      this.deferredPrompt.prompt();
    },
    listenEsc(state) {
      if (state === true) {
        // document.addEventListener('keyup', this.escHandler);
      }
      if (state === false) {
        // document.removeEventListener('keyup', this.escHandler);
      }
    },
    escHandler(e) {
      if (e.code==="Escape") {this.$router.back()}
    },
    messageHandler(e) {
      if (e.data.code === "listenEsc") {
        console.log('Событие по эксейп: '+e.data.state)
          this.listenEsc(e.data.state)
      }
    }
  },
  created() {
    
    //ждем установку
    window.addEventListener("beforeinstallprompt", e => {
      e.preventDefault();
      // Stash the event so it can be triggered later.
      if (Cookies.get("add-to-home-screen") === undefined) {
        this.deferredPrompt = e;
      }
    });
    window.addEventListener("appinstalled", () => {
      this.deferredPrompt = null;
    });
    // localStorage.setItem('userprofile','{"pushservice":"true"}')
    // Ждем кастом-событие обновления от сервис-воркера
    document.addEventListener('swUpdated', this.updateAvailable, { once: true })
    //navigator.serviceWorker.addEventListener('controllerchange', this.react("controllerchange"))
    document.addEventListener("visibilitychange", this.react("visibilitychange"));
        // Prevent multiple refreshes
        // problem on Safari


      //   if ("serviceWorker" in navigator) {
      // navigator.serviceWorker.ready
      //     .then((registration) => {
      //       console.log('listening controllerchange event')
      //       registration.addEventListener('controllerchange', () => {
      //         console.log('reloading page')
      //         if (this.refreshing) return
      //         // Here the actual reload of the page occurs
      //         window.location.reload()
      //         this.refreshing = true
      //       })
      //     })
      //   }


    // navigator.serviceWorker.ready.then((r) => {
    //     r.addEventListener('controllerchange', () => {
    //     if (this.refreshing) return
    //     this.refreshing = true
    //     // Here the actual reload of the page occurs
    //     window.location.reload()
    //   })
    // })
  },
  mounted() {
    // var current = JSON.parse(localStorage.getItem("draft_test"))
    // console.log(current[0].id)
    // var foundId = current.findIndex(item=>typeof(item) === "object" && item.id === 88)
    // current[foundId].key = "some new key"
    // current.push({id:88,key:'some key', data:{a: 0, b: 2}})
    // console.log(foundId)
    // var current = [
    //   {id: 627, key: 'key627'},
    //   {id: 27, key: 'key27'},
    //   {id: 67, key: 'key67'},
    //   {id: 166, key: 'key166'},
    //   {id: 575, key: 'key575'},
    //   ]
    // localStorage.setItem("draft_test", JSON.stringify(current))
    // this.loggedIn && this.$router.push('/about')
    console.log(" APP VERSION: " + this.$store.state.app.version)
    
    // this.listenEsc(true)
    // События в компонентах
    const apptalk = new BroadcastChannel('inapp');
    apptalk.addEventListener('message', this.messageHandler)

    const channel = new BroadcastChannel('sw-messages');
    const authChannel = new BroadcastChannel('auth-messages');
    if (this.$messaging){
        this.$messaging.onMessage((e)=>{
        console.log('Push recieved: ' + JSON.stringify(e.notification))
        this.$vaToast.init({ title:e.notification.title, message: e.notification.body, duration: 7000, color: '#9bd7e0', position: 'bottom-right' })
        this.showNotification(e.notification)
      })
    }
    //console.log('Firebase cloud messaging object: ', this.$messaging)
    // Слушаем сообщения от Интерцептора через BroadcastChannel
    authChannel.addEventListener('message', e => {
      if (e.data.type === "LOGOUT") {
        this.logOut()
      } else
      if (e.data.type === "REFRESHTOKENBROKEN") {
        this.reLogin()
      }
    })
    // Слушаем сообщения от сервис-воркера через BroadcastChannel
    channel.addEventListener('message', e => {
      if (e.data.type === "FROMSERVICE") {
        this.showNotification(e.data.message)
      }else
      if (e.data.code === "STATUS") {
        // this.appVersion = e.data.version
      } else
      if (e.data.code === "UPDATED") {
        this.updateVersion = e.data.version
        // this.$vaToast.init({ title:"Системное уведомление", message: 'Обновление готово к установке. Версия: ' + e.data.version, duration: 2000, color: '#9bd7e0', position: 'bottom-right' })
        this.$vaToast.init({ title:"Системное уведомление", message: 'Обновление готово к установке.', duration: 2000, color: '#9bd7e0', position: 'bottom-right' })
      } else
      if (e.data.code === "UPDATEFOUND") {
        this.updateVersion = e.data.version
        // this.$vaToast.init({ title:"Системное уведомление", message: 'Скачивается обновление приложения('+ e.data.version +')..', duration: 2000, color: '#9bd7e0', position: 'bottom-right' })
        this.$vaToast.init({ title:"Системное уведомление", message: 'Скачивается обновление приложения..', duration: 2000, color: '#9bd7e0', position: 'bottom-right' })
      } else {
        //this.$vaToast.init({ title:"Системное уведомление. Код: "+e.data.code, message: e.data.message, duration: 4000, color: '#9bd7e0', position: 'bottom-right' })
      }
    });
    
    // Uptime таймер
    this.tick()
    // EventBus.on("logout", () => {
    //   this.logOut();
    // });
    EventBus.on("loginsuccess", () => {
      console.log('Login success event recived from auth module')
    });
    EventBus.on("cleanFirebaseDB", () => {
      this.cleanFirebaseDB()
    });
    EventBus.on("setPushServiceStatus", (message) => {
      if (this.$messaging && message.value===true && !message.safemode) {
        this.subscribe(message.silent)
        .then((result) => EventBus.dispatch("PushStatusReady",{result: "success", detail: result}))
        .catch((error) => EventBus.dispatch("PushStatusReady",{result: "error", detail: error}))
      }
      if (this.$messaging && message.value===false && !message.safemode) {
        this.unsubscribe(message.silent)
        .then((result) => EventBus.dispatch("PushStatusReady",{result: "success", detail: result}))
        .catch((error) => EventBus.dispatch("PushStatusReady",{result: "error", detail: error}))
      }
    });

    //Badge API Test
    // Check if the API is supported.
    if ('setExperimentalAppBadge' in navigator) {
      this.isSupported('v2')
    }

    // Check if the previous API surface is supported.
    if ('ExperimentalBadge' in window) {
      this.isSupported('v1');
    }

    // Check if the previous API surface is supported.
    if ('setAppBadge' in navigator) {
      this.isSupported('v3');
    }
  },
  beforeUnmount() {
    // this.cleanFirebaseDB()
    window.addRemoveListener("beforeinstallprompt", e)
    // this.listenEsc(false)
    // apptalk.removeEventListener('message', this.messageHandler)
    // navigator.serviceWorker.removeEventListener("controllerchange",react("controllerchange"))
    // document.removeEventListener("visibilitychange",react("visibilitychange"))
    document.removeEventListener("swUpdated", this.updateAvailable)
    EventBus.remove("logout")
    EventBus.remove("loginsuccess")
    EventBus.remove("setPushServiceStatus")
  }
}
</script>