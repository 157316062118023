<template>
<VaCard stripe stripe-color="success" v-if="!preopExists">
    <VaCardTitle>
      <VaButton
      icon="arrow_back_ios_new"
      color="primary"
      icon-color="#333"
      class="mr-3"
      preset="secondary"
      hover-behavior="opacity"
      :hover-opacity="0.4"
      @click="$router.back()"
    />
    Предоперационный эпикриз
    </VaCardTitle>
    
    <VaCardContent>
      <VaAlert
        color="primary"
        border="left"
        class="mb-6"
        >
        Этот эпикриз будет прикреплен к истории болезни №{{ form.medhistory }}
      </VaAlert>
      
        <VaForm v-show="preopReadyToEdit || (!preopId && anexamReady)" ref="formRef" class="items-baseline gap-6" style="display: flex; flex-direction: column; max-width:100%">
          <label class="va-input-label" style="color: var(--va-primary);">Пациент</label>
          <VaCollapse
            icon="person"
            v-model="toggleCard"
            class="min-w-96 mb-3 rc-text-m"
            :header="patient.full_name + (patient.birthdate ? ', '+patient.birthdate : '')"
          >
          <div class="va-table-responsive" v-if="patient.id">
            <PatientProfile :patient="patient" />
          </div>

          </VaCollapse>

          <VaInput class="mb-3"
            type="text"
            v-model="form.diagnosis"
            label="Диагноз основной"
            placeholder="Диагноз"
          />
          
          <VaInput
            class="mb-3"
            type="text"
            v-model="form.diagnosis_secondary"
            label="Диагноз сопутствующий"
            placeholder="Диагноз сопутствующий"
          />

          <VaTextarea
            class="mb-1"
            v-model="form.proof"
            label="Обоснование диагноза"
            placeholder=""
            autosize
            :minRows=3
            :max-rows=6
          />

          <VaTextarea
            class="mb-1"
            v-model="form.scope"
            label="Планируемый объем оперативного вмешательства"
            placeholder=""
            autosize
            :minRows=3
            :max-rows=6
          />

          <VaTextarea class="mb-3"
            v-model="form.anesthesia"
            label="Планируемая анестезия"
            autosize
            :minRows=1
            :max-rows=3
          />

          <VaSelect
            class="mb-1"
            v-model="form.vteo_risc"
            label="Риск ВТЭО"
            placeholder="Выбрать из списка"
            value-by="value"
            :options="[
              {
                text: 'Низкий',
                value: 0,
              },
              {
                text: 'Высокий',
                value: 1,
              },
            ]"
          />
                    
          <VaTextarea
            class="mb-1"
            v-model="form.possible_risc"
            label="Возможные осложнения"
            placeholder=""
            autosize
            :minRows=7
            :max-rows=100
          />

          <VaTextarea class="mb-1"
            v-model="form.status_alergo"
            label="Аллергические реакции"
            placeholder="Известные аллергические реакции. Заполняется со слов пациента"
            autosize
            :minRows=1
            :max-rows=3
          />
          <div class="rcflex mt-4">
          <VaButton
            preset="primary"
            color="secondary"
            icon="close"
            class="mr-2"
            @click="$router.back()"
          >
            Отмена
          </VaButton>
          <VaButton
            v-if="preopId" 
            style="max-width:230px"
            class="" 
            @click="patchPreop()"
            preset="primary"
            icon-right="system_update_alt"
          >
            Сохранить изменения
          </VaButton>
          <VaButton
            v-else
            style="max-width:200px"
            class="" 
            @click="addPreop()"
            preset="primary"
            icon-right="system_update_alt"
          >
            Добавить 
          </VaButton>
          </div>          
        </VaForm>



        </VaCardContent>
  </VaCard>

  <VaCard stripe stripe-color="danger" v-else>
    <VaCardTitle>Ошибка</VaCardTitle>
    <VaCardContent>
      <VaAlert
        icon="warning"
        color="warning"
        class="rcflex"
      >
      <template #title>
        <span class="rc-text-l mb-4 rc-text-bold">Предоперационный эпикриз уже создан, его можно посмотреть в истории болезни.</span>
      </template>
          Дата записи: {{ preop.preopDate }} <br/>
          Врач: {{ preop.doctor_full_name }}
      </VaAlert>
    </VaCardContent>
    <VaCardContent>
      <VaButton
            class="mr-3"
            icon="cancel"
            preset="primary"
            color="secondary"
            @click="$router.back()"
            >
            Назад
          </VaButton>
    </VaCardContent>
  </VaCard>

  <VaModal
    :allowBodyScroll=true
    v-model="addError"
    size="small"
    hideDefaultActions
    ref="errorModal"
  >
    
    <template #default>
      <h4 class="va-h4">
        <VaIcon size="2rem" color="danger" name="close"/>Ошибка!
      </h4>
      <p class="mb-4">
        Проверьте правильность заполнения всех полей
      </p>
    </template>
    <template #footer>
      <VaButton @click.before="$refs.errorModal.hide()" @click="addError = !addError" preset="primary"> Исправить </VaButton>
    </template>
  </VaModal>

  <VaModal
    :allowBodyScroll=true
    v-model="addSuccess"
    size="small"
    cancelText="Назад к списку"
    :beforeCancel="back"
    :mobile-fullscreen=false
    :hideDefaultActions=true
    ref="success"
  >
    
    <template #default>
      <h4 class="va-h4">
        Готово!
      </h4>
      <p class="mb-4">
      Запись успешно создана
      </p>
      <VaButton @click.before="$refs.success.hide()" @click="$router.back()">
          Ок
      </VaButton>
    </template>
  </VaModal>

</template>
<script>
import axiosInstance from "../services/api";
import { RouterLink } from 'vue-router'
import PatientProfile from '@/components/PatientProfile';

export default {
    components: { RouterLink, PatientProfile },
    props: ['preopId','patientId','medhistoryId','anexamId'],
    data() {
        return {
            preopReadyToEdit: false,
            patientId: this.$route.query.patientId,
            toggleCard: false,
            template: '',
            visit: {},
            form: {
              anesthesia: '',
              diagnosis: '',
              diagnosis_secondary: '',
              proof: '',
              scope: '',
              vteo_risc: '',
              possible_risc: '',
              status_alergo: '',
              user: this.$store.state.app.account.profile.id,
              patient: this.patientId,
              medhistory: this.medhistoryId,
            },
            patient: {},
            addSuccess: false,
            addError: false,
            patientLoading: true,
            scheduleLoading:false,
            scheduleUpdated:0,
            preopExists: false,
            preop: {},
            anexam: {},
            anexamReady: false
          };
    },
    watch: {
      addError(value) {
        apptalk.postMessage({code: 'listenEsc', state: !value })
      }
    },
    methods: {
      checkPreop() {
        axiosInstance
            .get('/preops/search?patient='+this.patientId+'&medhistory='+this.medhistoryId)
            .then(response => {
              this.preopExists = response.data.count > 0 ? true : false
              this.preop = response.data.results[0]
            })
            .catch(error => {
              console.log('Preop check: error ' + error);
            })
      },
      getPreop() {
        axiosInstance
            .get('/preops/'+this.preopId)
            .then(response => {
              this.form = response.data
              this.form.vteo_risc = Number(this.form.vteo_risc)
              this.getPatient(this.form.patient)
              this.preopReadyToEdit = true
            })
            .catch(error => {
              console.log('Preop get: error ' + error);
            })
      },
      addPreop() {
        axiosInstance
            .post(`preops/add`, this.form)
            .then(response => {
              this.$vaToast.init({ message: 'Предоперационный эпикриз сохранен', color: 'success', position: 'bottom-right'})
              this.$router.replace({name: 'editMedhistory', props: {medhistory: this.medhistoryId}})
        })
            .catch(error => {
              console.log('Preop add : error ' + error);
              this.addError = true
        })
      },
      patchPreop() {
        delete this.form.id
        delete this.form.doctor_full_name
        delete this.form.preopDate
        delete this.form.updated
        delete this.form.medhistory
        delete this.form.user
        delete this.form.patient
        axiosInstance
          .patch('preops/'+this.preopId, this.form)
          .then(response => {
            this.$vaToast.init({ message: 'Предоперационный эпикриз изменен', color: 'success', position: 'bottom-right'})
            this.$router.replace('/medhistory/' + response.data.medhistory)
      })
          .catch(error => {
            console.log('Preop add : error ' + error);
            this.addError = true
      })
      },
      getVisit() {
        console.log('Trying to get a Visit')
        axiosInstance
            .get('/visits/'+this.patientId)
            .then(response => {
              const message = 'Get ok' + JSON.stringify(response)
              console.log(message);
              this.visit = response.data.results[0]
              if (!this.preopId) {
                this.form.diagnosis = this.visit.diagnosis
                this.form.diagnosis_secondary = this.visit.diagnosis_secondary
                this.form.proof = this.visit.proof
                this.form.status_alergo = this.visit.status_alergo
              }
        })
            .catch(error => {
            console.log('Get visit Request Failed: ' + JSON.stringify(error))
        })
            .finally(() => {
              this.isLoading = false
        });
      },
      getPatient(id) {
        this.patientLoading = true
        console.log('id is ' + id)
        axiosInstance
            .get(`patients/`+id+`/`)
            .then(response => {
              this.patient = response.data;
        })
            .catch(error => {
            console.log('Axios Request Failed: ' + error);
            this.gotError = true;
        })
            .finally(() => {
              console.log('One patient: Request successfully completed')
              this.patientLoading = false
        });
      },
      getAnExam() {
        if (this.anexamId != "none") {
          axiosInstance
          .get(`/medhistory/anexam/`+this.anexamId)
          .then(response => {
            this.anexam = response.data;
            this.anexamReady = true
            this.form.scope = this.anexam.scope
            this.form.anesthesia = this.anexam.anesthesia_recom
            this.form.possible_risc = 'В осмотре анестезиолога указан риск анестезии: ' + this.anexam.anesthesia_risc + '\n'
          })
          .catch(error => {
            console.log('Get anexam error')
          })
        }else{
          this.form.anesthesia = "Местная анестезия"
          this.anexamReady = true
        }
      },
    },
    mounted() {
      const apptalk = new BroadcastChannel('inapp');
      if (this.preopId) {
        this.getPreop()
      }else{
        this.getAnExam()
        this.checkPreop()
        this.getPatient(this.patientId)
        this.getVisit(this.patientId)
      }

    },
}

</script>
<style>
</style>
  