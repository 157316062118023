<template>
<VaCard stripe stripe-color="success">
    <VaCardTitle>
    Мои шаблоны
    </VaCardTitle>
  
    <VaCardContent>

      <div class="rcflex row rcflex-btw mb-4">
          <VaSwitch
            v-model="displayShared"
            label="Показать все шаблоны"
            size="small"
            class="rcflex"
          />
          <VaButton icon="note_add" preset="primary" style="max-width:130px" @click="$router.push({name: 'newTemplate'})">
            Добавить
          </VaButton>
        </div>
          <VaCard 
            class="mb-3 mt-3"
            v-for="(template, index) in templates"
            :key="index"
          >
            <VaCardTitle class="rc-text-m rcflex rcflex-btw row">
              {{template.name}}
              <div v-if="template.owner == $store.state.app.account.profile.id" class="rcflex">
                <VaButton preset="primary" icon="edit" class="rcflex-right mr-2" :to="'templates/edit/'+template.id"/>
                <VaButton preset="primary" color="danger" icon="delete" class="rcflex-right" @click="confirmDelete(template)"/>
              </div>
              <div class="va-text-highlighted va-title" v-else>
              </div>
            </VaCardTitle>
            <VaCardContent>
              <div class="rcflex row rcflex-btw mb-3">
                <div class=" va-text-highlighted va-title rc-text-s">Автор: {{ template.owner == $store.state.app.account.profile.id ? "Вы" : template.owner_name }}</div><div class=" va-text-highlighted va-title rc-text-s">Доступ: {{ template.shared ? "Общий" : "Только для меня" }}</div>
              </div>
              <div class="mb-4 va-title rc-text-m" color="rgb(21, 78, 193)" style="color:rgb(21, 78, 193)">Для отделения: {{ template.type==0 ? "Амбулатория" : "Стационар"}}</div>
              <span class="mb-4"><p class="rc-text-bold rc-text-m mb-3">Локальный статус:</p><p class="rc-text-m mb-3">{{template.status_local}}</p></span>
              <span class="mb-4" v-if="template.type!=0"><p class="rc-text-bold rc-text-m mb-3">План ведения и лечения:</p>{{template.cure_plan}}</span>
              <span class="mb-4" v-else><p class="rc-text-bold rc-text-m mb-3">Рекомендации:</p>{{template.recom}}</span>
            </VaCardContent>
          </VaCard>

    </VaCardContent>
  </VaCard>

</template>
<script>
import axiosInstance from "../services/api";
import EventBus from "../common/EventBus";
import { RouterLink } from 'vue-router'

export default {
    components: { RouterLink },
    data() {
        return {
            displayShared: true,
            templates: [],
            tmpl:[],
          };
    },
    watch: {
      displayShared: function(newValue) {
        if (newValue == false) {
          this.templates = this.tmpl.filter((record) => record.owner == this.$store.state.app.account.profile.id)
        }else{
          this.templates = this.tmpl
        }
      }
    },
    methods: {
      getTemplates() {
        axiosInstance
            .get(`templates/`)
            .then(response => {
                this.templates = response.data.results
                this.tmpl = response.data.results
            })
            .catch(error => {
              console.log('Templates: error ' + error);
              this.templates = []
        })
            .finally(() => {
              console.log('Templates: Request completed')
        });
      },
      confirmDelete(tmpl) {
        // let cityId = this.templates.find(template => template.id === tmpl.id)
        //       console.log(cityId);

        this.$vaModal.confirm({
          size: "medium",
          message: 'Вы уверены что хотите удалить шаблон: ' + tmpl.name + '?',  
          okText: "Да, удалить",
          cancelText: "Нет, оставить",
          preset: "primary",
          allowBodyScroll: false,
          blur
        })
        .then((ok) => ok && this.deleteTemplate(tmpl.id))
      },
      deleteTemplate(tmplId) {
        axiosInstance
            .delete(`templates/` + tmplId)
            .then(response => {
                let index = this.templates.findIndex(el => el.id === tmplId);
                console.log('Удаляем вот его: ' + index);
                this.templates.splice(index,1)
                this.$vaToast.init({ message: 'Шаблон удален', color: 'info', position: 'bottom-right' })
            })
            .catch(error => {
              console.log('Templates add : error ' + error);
        })
            .finally(() => {
              console.log('Templates add: Request completed')
        });
        console.log('Deleting template with id: ' + tmplId)
      },
    },
    mounted() {
      this.getTemplates()
    },
}

</script>
<style>
</style>
  