<template>
    <div class="print">
        <p style="text-align:right;text-indent: 2em;">
        Приложение N 2 к приказу Министерства здравоохранения<br/>
        Российской Федерацииот 20 декабря 2012 г. N 1177н
        </p>
        <p style="text-align:center;font-weight:bold;">Информированное добровольное согласие на виды медицинских вмешательств, включенные в Перечень определенных видов медицинских вмешательств, на которые граждане дают информированное добровольное согласие при выборе врача и медицинской организации для получения первичной медико-санитарной помощи</p>
        <p style="text-align:justify;text-indent: 2em;">Я, {{ patient.full_name}}, {{ patient.birthdate }} г. рождения, {{ patient.sex == "m" ? 'зарегистрированный' : 'зарегистрированная' }} по адресу: {{ patient.address}},
            даю информированное добровольное согласие на виды медицинских вмешательств, включенные  в  Перечень  определенных  видов  медицинских  вмешательств, на которые  граждане  дают  информированное  добровольное  согласие при выборе врача  и  медицинской организации для получения первичной медико-санитарной помощи,  утвержденный  приказом  Министерства здравоохранения и социального развития  Российской Федерации от 23 апреля 2012 г. N 390н (зарегистрирован Министерством  юстиции Российской Федерации 5 мая 2012 г. N 24082) (далее - Перечень),  для  получения  первичной  медико-санитарной помощи / получения первичной  медико-санитарной помощи лицом, законным представителем которого я являюсь (ненужное зачеркнуть) в
        Общество с ограниченной ответственностью «Клиника Профессора»
        </p>
        <p style="text-align:center">
        ________________________________________________________________________________________________
        </p>
        <p style="text-align:center;text-indent: 2em;">
            (должность, Ф.И.О. медицинского работника)
        </p>
        <p style="text-align:justify;text-indent: 2em;">
        в доступной для меня форме мне разъяснены цели, методы оказания медицинской помощи, связанный с ними риск, возможные варианты медицинских вмешательств, их  последствия,  в  том  числе  вероятность  развития  осложнений, а также предполагаемые  результаты оказания медицинской помощи. Мне разъяснено, что я  имею  право  отказаться  от  одного  или  нескольких  видов  медицинских вмешательств,  включенных в Перечень, или потребовать его (их) прекращения,
        за  исключением  случаев,  предусмотренных  частью 9 статьи 20 Федерального закона  от 21 ноября 2011 г. N 323-ФЗ "Об основах охраны здоровья граждан в Российской  Федерации"  (Собрание законодательства  Российской  Федерации, 2011, N 48, ст. 6724; 2012, N 26, ст. 3442, 3446).
        Сведения  о  выбранных  мною  лицах, которым в соответствии с пунктом 5 части  5  статьи  19  Федерального закона от 21 ноября 2011 г. N 323-ФЗ "Об основах охраны здоровья граждан в Российской Федерации" может быть передана информация   о  состоянии  моего  здоровья  или  состоянии  лица,  законным представителем которого я являюсь (ненужное зачеркнуть)
        </p>

        <p>{{ patient.full_name}}, {{ patient.phone}} </p>
        <p>_____________________(подпись)</p>

        <p style="text-align:center;line-height:1rem">
        ________________________________________________________________________________________________
        </p>
        <p style="text-align:center;"> 
        <sup>(подпись)                  (Ф.И.О. медицинского работника)</sup>
        </p>

        <p style="text-align:left">Дата оформления: {{ date }} г.</p>



        <p>Паспорт: {{ passport}}</p>
        <p style="text-align:center;font-weight:bold;">
        Информирование о возможности получения медицинской помощи в рамках программ государственных гарантий бесплатного оказания гражданам медицинской помощи
        </p>
        <p style="text-align:justify;text-indent: 2em;">
            Я, {{ patient.full_name}},
        Информирован о возможности получения мною бесплатно медицинской помощи в рамках программ государственных гарантий бесплатного оказания гражданам медицинской помощи и территориальных программ государственных гарантий бесплатного оказания гражданам медицинской помощи (ОМС)
        </p>
        <p style="text-align:right">
        ______________  {{ patient.full_name}}
        </p>
        <p style="text-align:right">
            {{ date }} г.
        </p>
    </div>
</template>
<script>
export default {
    props: ['patient','date'],
    computed: {
        passport() {
            if (this.$isJSON(this.patient.passport)) {
                let p = JSON.parse(this.patient.passport)
                return 'серия ' + p.series + ' номер ' + p.number + ' выдан ' + p.issued + ' ' + p.date
            }else{
                return this.patient.passport.length>0 ? this.patient.passport : 'не указаны'
            }
      }
    }
}
</script>