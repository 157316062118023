<template>
    <div class="print">
        <p style="text-align:center;font-weight:bold;">
        Типовая форма информационного добровольного согласия на формирование листка нетрудоспособности в форме электронного документа и обработку персональный данных.
        </p>
        <p style="text-align:center;">
        Медицинская организация ООО «Клиника Профессора»
        </p>
        <p style="text-align:justify;">
        Я, {{ patient.full_name}}, {{ patient.birthdate }} г. рождения, {{ patient.sex == "m" ? 'зарегистрированный' : 'зарегистрированная' }} по адресу: {{ patient.address}},
        паспортные данные: {{ passport}}. 
        </p>
        <p style="text-align:justify;">
        В соответствии с требованием Федерального закона от 29 декабря 2006 г. № 255-ФЗ «Об обязательном социальном страховании на случай временной нетрудоспособности и в связи с материнством» и Федерального закона от 27 июля 2006 №152-ФЗ «О персональный данных» в целях осуществления обязательного страхования подтверждаю свое согласие на формирование листка нетрудоспособности в форме электронного документа, а также на обработку
        моих персональных данных, необходимых для оформления листка нетрудоспособности в форме электронного документа, в том числе СНИЛС, включая сбор, систематизацию, накопление, хранение, уточнение, обновление, изменение, извлечение, использование, передачу, распространение, предоставление, доступ, обезличивание, блокирование, удаление, уничтожение.
        </p>
        <p style="text-align:justify;">
        Медицинская организация вправе обрабатывать и передавать для обработки другим участникам информационного взаимодействия – страхователя, Фонду социального страхования Российской Федерации, учреждению медико-социальной экспертизы и другим медицинским организациям
        мои персональные данные, необходимые для оформления листка нетрудоспособности в форме электронного документа, в том числе СНИЛС, посредством внесения их в электронную базу данных с использованием машинных носителей информации, по каналам связи с соблюдением мер, обеспечивающих их защиту от несанкционированного доступа, без специального уведомления меня об этом.
        </p>
        <p style="text-align:justify;">
        Срок хранения персональных данных соответствует сроку хранения первичных медицинских документов (медицинской карты) и составляет двадцать пять лет.
        Настоящее согласие дано мной {{ date }} года и действует бессрочно.
        </p>
        <p style="text-align:justify;">
        Я оставляю за собой право отозвать свое согласие посредством составления соответствующего письменного документа, который может быть направлен мной в адрес медицинской организации по почте заказным письмом с уведомлением о вручении либо вручен лично под расписку представителю медицинской организации.
        В случае получения письменного заявления об отзыве настоящего согласия на обработку персональных данных медицинская организация обязана:<br/>
        а) прекратить их обработку;<br/>
        б) по истечении указанного выше срока хранения моих персональных данных (двадцать пять лет) уничтожить (стереть) все мои персональные данные из баз данных автоматизированной информационной системы медицинской организации, включая все копии на машинных носителях информации, без уведомления меня об этом.<br/>
        </p>
        <p style="text-align:right;">
        __________________________________ {{ patient.full_name}}</p>
        <p style="text-align:right;">
        {{ patient.phone}} 
        </p>
    </div>
</template>
<script>
export default {
    props: ['patient','date'],
    computed: {
        passport() {
            if (this.$isJSON(this.patient.passport)) {
                let p = JSON.parse(this.patient.passport)
                return 'серия ' + p.series + ' номер ' + p.number + ' выдан ' + p.issued + ' ' + p.date
            }else{
                return this.patient.passport.length>0 ? this.patient.passport : 'не указаны'
            }
      }
    }
}
</script>