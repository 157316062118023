<template>
  <VaButton v-if="$route.name!=='patient'" icon="edit" preset="primary" @click="$router.push({ path: '/patients/' + patient.id + '/edit'})">Изменить</VaButton>
  <table class="rc-table">
    <tbody>
          <tr>
            <th class=" ">Пол</th>
            <td class="">{{ patient.sex === "m" ? "Мужской" : patient.sex === "f" ? "Женский" : "не указан"}}</td>
          </tr>
          <tr>
            <th class=" ">Национальность</th>
            <td class="">{{ patient.nation ? patient.nation : "не указана" }}</td>
          </tr>
          <tr>
            <th class=" ">Образование</th>
            <td class="">{{ patient.education ? patient.education : "не указано"}}</td>
          </tr>
          <tr>
            <th class=" ">Дата рождения</th>
            <td class="">{{ patient.birthdate ? patient.birthdate : "не указана" }}</td>
          </tr>
          <tr>
            <th class=" ">Паспортные данные</th>
            <td class="">{{ passport ? passport : "не указаны" }}</td>
          </tr>
          <tr>
            <th class=" ">Семейное положение</th>
            <td class="">{{ patient.marriage ? patient.marriage : "не указано" }}</td>
          </tr>
          <tr>
            <th class=" ">Страховая организация</th>
            <td class="">{{ patient.polis_issuer ? patient.polis_issuer : "не указан" }}</td>
          </tr>
          <tr>
            <th class=" ">Полис ОМС</th>
            <td class="">{{ patient.polis ? patient.polis : "не указан" }}</td>
          </tr>
          <tr>
            <th class=" ">Адрес</th>
            <td class="">{{ patient.address ? patient.address : "не указан" }}</td>
          </tr>
          <tr>
            <th class=" ">Сельский житель</th>
            <td class="">{{ patient.villager ? "Да" : "Нет" }}</td>
          </tr>
          <tr>
            <th class=" ">Телефон</th>
            <td class="">{{ patient.phone ? patient.phone : "не указан" }}</td>
          </tr>
          <tr>
            <th class=" ">СНИЛС</th>
            <td class="">{{ patient.snils ? patient.snils : "не указан" }}</td>
          </tr>
          <tr>
            <th class=" ">Группа инвалидности</th>
            <td class="">{{ patient.disability }}</td>
          </tr>
          <tr>
            <th class=" ">Место работы</th>
            <td class="">{{ patient.works ? patient.works : "не указано" }}</td>
          </tr>
          <tr>
            <th class=" ">Рост, см</th>
            <td class="">{{ patient.height ? patient.height : "не указан" }}</td>
          </tr>
          <tr>
            <th class=" ">Вес, кг</th>
            <td class="">{{ patient.weight ? patient.weight : "не указан" }}</td>
          </tr>
          <tr>
            <th class=" ">Индекс массы тела</th>
            <td class=""><span v-if="patient.height && patient.weight">{{ bmi }} - {{ bmiString(bmi) }}</span><span v-else>Для расчета необходимо заполнить рост и вес пациента</span></td>
          </tr>
          <tr>
            <th class=" ">Рабочее АД</th>
            <td class="">{{ patient.artpressure ? patient.artpressure : "не указано" }}</td>
          </tr>
          <tr>
            <th class=" ">Пульс, уд. в мин.</th>
            <td class="">{{ patient.pulse ? patient.pulse : "не указан" }}</td>
          </tr>
          <tr>
            <th class=" ">Сатурация, %</th>
            <td class="">{{ patient.spo2 ? patient.spo2 : "не указана" }}</td>
          </tr>
          <tr>
            <th class=" ">Температура тела, °C</th>
            <td class="">{{ patient.temp ? patient.temp : "не указана" }}</td>
          </tr>
          <tr>
            <th class=" ">Группа крови</th>
            <td class="">{{ bloodTypes[patient.bloodtype] ? bloodTypes[patient.bloodtype] : "не указана" }}
            </td>
          </tr>
          <tr>
            <th class=" ">Резус фактор</th>
            <td class="">{{ rhfactor(patient.rhfactor) ? rhfactor(patient.rhfactor) : "не указан" }}</td>
          </tr>
          <tr>
            <th class=" ">Диагноз при поступлении</th>
            <td class="">{{ patient.diagnosis_first ? patient.diagnosis_first : "Нет" }}</td>
          </tr>
    </tbody>
  </table>
</template>
<script setup>
  import { bloodTypes, rhfactor } from "@/common/locale"
</script>
<script>
export default {
    props: ['patient'],
    computed: {
      bmi() {return this.patient.weight && this.patient.height && (this.patient.weight/Math.pow(this.patient.height/100, 2)).toFixed(2)},
      passport() {
        if (this.$isJSON(this.patient.passport)) {
          let p = JSON.parse(this.patient.passport)
          return 'серия ' + p.series + ' номер ' + p.number + ' выдан ' + p.issued + ' ' + p.date
        }else{
          return this.patient.passport.length>0 ? this.patient.passport : 'не указаны'
        }
      }
    },
    methods: {
      bmiString(bmi) {
        if (bmi<=16) {
          return 'Выраженный дефицит массы тела'
        }
        else if (bmi<=18.5) {
          return 'Недостаточная (дефицит) масса тела'
        }
        else if (bmi<=25) {
          return 'Норма'
        }
        else if (bmi<=30) {
          return 'Избыточная масса тела (предожирение)'
        }
        else if (bmi<=35) {
          return 'Ожирение первой степени'
        }
        else if (bmi<=40) {
          return 'Ожирение второй степени'
        }
        else if (bmi>40) {
          return 'Ожирение третьей степени (морбидное)'
        }
      },
    }
}
</script>