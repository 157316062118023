import { createRouter, createWebHistory } from 'vue-router'
import AboutView from '@/views/AboutView.vue'
import PatientsView from '@/views/PatientsView.vue'
import PatientView from '@/views/PatientView.vue'
import PatientFormView from '@/views/PatientFormView.vue'
import RegistrationsView from '@/views/RegistrationsView'
import RegistrationFormView from '@/views/RegistrationFormView'
import RegsDoctorsView from '@/views/RegsDoctorsView'
import VisitsFormView from '@/views/VisitsFormView'
import PreopFormView from '@/views/PreopFormView'
import TemplatesView from '@/views/TemplatesView'
import TemplatesFormView from '@/views/TemplatesFormView'
import ProfileView from '@/views/ProfileView'
import LoginView from '@/views/LoginView'
import LogoutView from '@/components/logout'
import MedhistoryView from '@/views/MedhistoryView'
import MedhistoryCreateView from '@/views/MedhistoryCreateView'
import MedhistoryManageView from '@/views/MedhistoryManageView'
import DiaryFormView from '@/views/DiaryFormView'
import OperationFormView from '@/views/OperationFormView'
import NursePostView from '@/views/NursePostView'
import StaffFormView from '@/views/StaffFormView'
import AccessNotice from '@/views/AccessNoticeView'
import ExpressFormView from '@/views/ExpressFormView'
import AnExamFormView from '@/views/AnExamFormView'
import DischargeEpicrisisFormView from '@/views/DischargeEpicrisisFormView'
import VisitsManageView from '@/views/VisitsManageView.vue'
import AnExamsManageView from '@/views/AnExamsManageView.vue'
import TitleFormView from '@/views/TitleFormView.vue'
import ReportsView from '@/views/ReportsView.vue'

const routes = [
  {
    path: '/',
    alias: '/about',
    name: 'about',
    component: AboutView
  },
  {
    path: '/reports',
    name: 'reports',
    component: ReportsView
  },
  {
    path: '/patients',
    name: 'patients',
    component: PatientsView
  },
  {
    path: '/express/add',
    name: 'expressAdd',
    props: true,
    component: ExpressFormView
  },
  {
    path: '/patients/add',
    name: 'patientAdd',
    props: true,
    component: PatientFormView
  },
  {
    path: '/patients/:patientId',
    name: 'patient',
    props: true,
    component: PatientView
  },
  {
    path: '/patients/:patientId/edit/',
    name: 'patientEdit',
    props: true,
    component: PatientFormView
  },
  {
    path: '/regs',
    name: 'registrations',
    component: RegistrationsView,
    props: true
  },
  {
    path: '/regs/add/:patientId',
    name: 'regAdd',
    props: true,
    component: RegistrationFormView
  },
  {
    path: '/visits/new/:patientId~:examType~:depType~:regId',
    name: 'visitNew',
    props: true,
    component: VisitsFormView
  },
  {
    path: '/visits/edit/:visitId',
    name: 'visitEdit',
    props: true,
    component: VisitsFormView
  },
  {
    path: '/myregs',
    name: 'myRegs',
    props: true,
    component: RegsDoctorsView
  },
  {
    path: '/login',
    name: 'login',
    props: true,
    component: LoginView
  },
  {
    path: '/logout',
    name: 'logout',
    props: true,
    component: LogoutView
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfileView
  },
  {
    path: '/templates',
    name: 'templates',
    component: TemplatesView
  },
  {
    path: '/templates/new',
    name: 'newTemplate',
    props: true,
    component: TemplatesFormView
  },
  {
    path: '/templates/edit/:templateId',
    name: 'editTemplate',
    props: true,
    component: TemplatesFormView
  },
  {
    path: '/preop/add/:patientId~:medhistoryId~:anexamId',
    name: 'preop',
    props: true,
    component: PreopFormView
  },
  {
    path: '/preop/edit/:preopId',
    name: 'preopEdit',
    props: true,
    component: PreopFormView
  },
  {
    path: '/medhistory/:medhistoryId',
    name: 'editMedhistory',
    props: true,
    component: MedhistoryView
  },
  {
    path: '/medhistory/manage',
    name: 'ManageMedhistory',
    props: true,
    component: MedhistoryManageView
  },
  {
    path: '/medhistory/add/:patientId',
    name: 'addMedhistory',
    props: true,
    component: MedhistoryCreateView
  },
  {
    path: '/medhistory/diary/add/:medhistoryId',
    name: 'addDiary',
    props: true,
    component: DiaryFormView
  },
  {
    path: '/medhistory/diary/edit/:recordId',
    name: 'editDiary',
    props: true,
    component: DiaryFormView
  },
  {
    path: '/operation/add/:patientId~:medhistoryId',
    name: 'addOperation',
    props: true,
    component: OperationFormView
  },
  {
    path: '/operation/edit/:operationId~:medhistoryId~:patientId',
    name: 'editOperation',
    props: true,
    component: OperationFormView
  },
  {
    path: '/anexam/new/:patientId~:medhistoryId~:visitId~:regId',
    name: 'addAnExam',
    props: true,
    component: AnExamFormView
  },
  {
    path: '/anexam/edit/:anexamId',
    name: 'editAnExam',
    props: true,
    component: AnExamFormView
  },
  {
    path: '/nursepost',
    name: 'nursepost',
    props: true,
    component: NursePostView
  },
  {
    path: '/staff',
    name: 'staff',
    props: true,
    component: StaffFormView
  },
  {
    path: '/discharge/add',
    name: 'addDischargeEpicrisis',
    props: true,
    component: DischargeEpicrisisFormView
  },
  {
    path: '/discharge/edit/:dischargeId',
    name: 'editDischargeEpicrisis',
    props: true,
    component: DischargeEpicrisisFormView
  },
  {
    path: '/visits/manage',
    name: 'VisitsManage',
    props: true,
    component: VisitsManageView
  },
  {
    path: '/anexams/manage',
    name: 'AnExamsManage',
    props: true,
    component: AnExamsManageView
  },
  {
    path: '/title/add',
    name: 'titleAdd',
    props: true,
    component: TitleFormView
  },
  {
    path: '/title/edit',
    name: 'titleEdit',
    props: true,
    component: TitleFormView
  },
  {
    path: '/denied/:code',
    name: 'denied',
    props: true,
    component: AccessNotice
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
